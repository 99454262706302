import React, { useState } from "react";
import PropTypes from "prop-types";
import { RichText } from "prismic-reactjs";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import {
  structuredTextPropTypes,
  imagePropTypes,
} from "../../utils/prop-types";
import TitleSubtitle from "../../components/TitleSubtitle";
import ThumbnailVideo from "../../components/ThumbnailVideo";
import PersonItem from "../../components/PersonItem";
import VideoModal from "../../components/VideoModal";
import ButtonBase from "@material-ui/core/ButtonBase";
import CustomLink from "../../components/CustomLink";

const EventDetailsVideo = ({ slice, classes }) => {
  const { primary, items } = slice;
  const [openModal, setOpenModal] = useState(false);

  const openVideoDialog = () => {
    setOpenModal(true);
  };

  const closeVideoDialog = () => {
    setOpenModal(false);
  };
  return (
    <div className={classes.eventDetailsVideo}>
      <Container maxWidth="lg">
        <TitleSubtitle
          title={RichText.asText(primary.videoTitle)}
          subtitle={RichText.asText(primary.videoSubtitle)}
          showClass
        />

        <Grid container direction="column" justify="center" alignItems="center">
          <div
            onClick={() => {
              openVideoDialog();
            }}
            className="thumbnailWrapper"
          >
            <ThumbnailVideo
              thumbnailVideoUrl={primary.thumbnail.url}
              thumbnailVideoAlt={primary.thumbnail.alt}
            />
            <ButtonBase
              className="playBtn"
              onClick={openVideoDialog}
            ></ButtonBase>
          </div>
          <VideoModal
            open={openModal}
            handleClose={closeVideoDialog}
            video={primary.vimeoOrYoutubeLink}
          />
        </Grid>
        <Grid container direction="column" justify="center" alignItems="center">
          <CustomLink link={primary.ctaButtonLink}>
            <Button variant="contained" color="primary">
              {RichText.asText(primary.ctaButtonText)}
            </Button>
          </CustomLink>
        </Grid>
        <div className="speakerTitleSubWrapper">
          <TitleSubtitle
            title={RichText.asText(primary.speakersTitle)}
            subtitle={RichText.asText(primary.speakersSubtitle)}
            showClass
          />
        </div>

        <Grid
          container
          spacing={4}
          justify="center"
          className={classes.speakerItem}
        >
          {items.map((item) => (
            <Grid item xs={12} sm={4}>
              <PersonItem
                bannerImageSrc={item.speakerImage.url}
                bannerImageAlt={item.speakerImage.alt}
                name={RichText.asText(item.speakerName)}
                title={RichText.asText(item.title)}
              />
            </Grid>
          ))}
          <Grid item xs={12}>
            <CustomLink link={primary.ctaButtonLink}>
              <Button
                variant="contained"
                color="primary"
                className="getTickerSpeaker"
              >
                {RichText.asText(primary.ctaButtonText)}
              </Button>
            </CustomLink>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
EventDetailsVideo.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({
      video_title: structuredTextPropTypes,
      video_subtitle: structuredTextPropTypes,
      thumbnail: imagePropTypes,
      cta_button_text: structuredTextPropTypes,
      speakers_title: structuredTextPropTypes,
      speakers_subtitle: structuredTextPropTypes,
    }).isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        speaker_image: imagePropTypes,
        name: PropTypes.string,
        title1: PropTypes.string,
      })
    ).isRequired,
  }).isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(style)(EventDetailsVideo);
