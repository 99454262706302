const styles = (theme) => ({
  workDetailsTestimonials: {
    backgroundSize: "cover",
    backgroundColor: "#000",
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    "& .background": {
      backgroundColor: "rgba(0, 0, 0, 0.3)",
      display: "flex",
      alignItems: "center",
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(10),
      [theme.breakpoints.down("sm")]: {
        paddingTop: theme.spacing(9),
        paddingBottom: theme.spacing(9),
      },
      [theme.breakpoints.down("xs")]: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
      },
    },
    "& .testimonialText": {
      fontStyle: "italic",
      color: "#ffffff",
      textAlign: "center",
      fontSize: 24,
      maxWidth: 969,
    },
    "& .author": {
      color: "#ffffff",
      textAlign: "center",
      lineHeight: 1.42,
      fontSize: 24,
      marginTop: theme.spacing(6.5),
      maxWidth: 969,
    },
  },
});
export default styles;
