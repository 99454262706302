const styles = (theme) => ({
  FeaturedWork: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(9),
      paddingBottom: theme.spacing(9),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
    '& .CategoryTagWrapper': {
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(10),
      [theme.breakpoints.down('sm')]: {
        paddingTop: theme.spacing(9),
        paddingBottom: theme.spacing(9),
      },
      [theme.breakpoints.down('xs')]: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
      },
    },
    '& .viewAll': {
      margin: '80px auto 0px',
      [theme.breakpoints.down('sm')]: {
        margin: '72px auto 0px',
      },
      [theme.breakpoints.down('xs')]: {
        margin: '64px auto 0px',
      },
    },
  },
})

export default styles
