import { createStyles, Theme } from "@material-ui/core";

// Theme-dependent styles
const styles = (theme: Theme) => createStyles({
  modal: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    color: "#4c4c78",
    fontFamily: "Raleway,Assistant",
    textAlign:'center',
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(3),
    },
  },
});

export default styles;
