const styles = (theme) => ({
  workDetailsGallery: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(9),
      paddingBottom: theme.spacing(9),
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
    "& .galleryContainerTitleSub":{
      height: 0,
      marginBottom: theme.spacing(10),
      [theme.breakpoints.down("sm")]: {
        marginBottom: theme.spacing(9),
      },
      [theme.breakpoints.down("sm")]: {
        marginBottom: theme.spacing(8),
      },
    },
    "& .galleryContainer": {
      "& img": {
        width: "100%",
        height: 300,
        objectFit: "cover",
        cursor: "pointer",
        boxShadow: "0 22px 57px 0 rgba(37, 44, 80, 0.5)",
        transition: 'transform .9s',
        "&:hover":{
          transform: 'scale(1.05)',
          '-ms-transform': 'scale(1.05)',
          '-webkit-transform': 'scale(1.05)',
        },
        [theme.breakpoints.down("xs")]: {
          height: "auto",
          objectFit: "unset",
        },
      },
    },
  },
});
export default styles;
