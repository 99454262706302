const styles = (theme) => ({
  background: {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    '& .MuiGrid-container': {
      height: '100%',
    },
  },
  backgroundOverlay: {
    backgroundColor: 'rgba(0,0,0,0.2)',
    height: 826,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(20, 2),
      height: '100%',
    },
  },
  preTitle: {
    padding: theme.spacing(0.5, 1),
    color: '#fff',
    zIndex: 99,
    textTransform: 'uppercase',
    marginBottom: theme.spacing(3.5),
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  heroTitle: {
    backgroundImage: `url('/eventDetailsBlueBg.svg')`,
    backgroundPosition: 'center',
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
    width: 'fit-content',
    padding: '33px 32px 26px 34px',
    color: '#fff',
    textAlign: 'center',
    textTransform: 'uppercase',
    marginRight: 250,
    marginBottom: '-10px',
    [theme.breakpoints.down('sm')]: {
      backgroundSize: 'cover',
      padding: theme.spacing(2),
      marginRight: 250,
    },
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
    },
  },
  heroSubTitle: {
    backgroundImage: `url('/eventDetailsGreenBg.svg')`,
    backgroundPosition: 'center',
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
    width: 'fit-content',
    padding: '20px 31px',
    color: '#fff',
    textAlign: 'center',
    marginLeft: 250,
    [theme.breakpoints.down('sm')]: {
      backgroundSize: 'cover',
      padding: theme.spacing(2),
      marginLeft: 0,
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
    },
  },
  heroButton: {
    display: 'flex',
    marginTop: theme.spacing(4),
    '& button': {
      margin: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      flexDirection: 'column;',
      alignItems: 'center',
    },
  },
  eventDaysTime: {
    backgroundColor: '#F4F5F6',
    backgroundImage: 'url("/patternSimpleText.svg")',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundBlendMode: 'color-burn',
    padding: theme.spacing(10, 4),
    fontFamily: 'Raleway,Assistant',
    fontSize: 42,
    fontWeight: 900,
    lineHeight: 1.09,
    textAlign: 'center',
    color: '#4c4c78',
    boxShadow: '0 30px 60px 0 rgba(73, 86, 149, 0.15)',
    margin: '0 80px',
    marginTop: '-105px',
    position: 'absolute',
    left: 0,
    right: 0,
    [theme.breakpoints.down('sm')]: {
      marginTop: '36px',
      padding: theme.spacing(9, 3),
      fontSize: 34,
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(8, 3),
      fontSize: 24,
    },
    '& .countType': {
      marginLeft: theme.spacing(1),
    },
    '& .countSlash': {
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
      },
    }
  },
})

export default styles