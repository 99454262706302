import React from "react";
import { array, shape } from "prop-types";
import { RichText, Date } from "prismic-reactjs";
import style from "./style";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { useAdditionalData } from "../../utils/additional-data-context";
import PersonItem from "../../components/PersonItem";
import TitleSubtitle from "../../components/TitleSubtitle";
import CategoryTag from "../../components/CategoryTag";
import CustomLink from "../../components/CustomLink";
const section = {
  maxWidth: "600px",
  margin: "4em auto",
  textAlign: "center",
};

const h2 = {
  color: "#8592e0",
};
const dateOptions = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
};
const AllBlogsInTopic = ({ slice, classes }) => {
  const { primary } = slice;
  const additionalData = useAdditionalData();
  return (
    <div className={classes.featuredBlogs}>
      <Container maxWidth="lg">
        <TitleSubtitle
          title={RichText.asText(primary.title)}
          subtitle={RichText.asText(primary.subtitle)}
          showClass={false}
        />

        <Grid
          container
          spacing={4}
          direction="row"
          justify="center"
          alignItems="center"
          className="CategoryTagWrapper"
        >
          <Grid item md={2} key="random">
            <CustomLink link={{ type: "learn" }}>
              <CategoryTag
                selected={true}
                CategoryTagTitle="View All"
                showIcon={false}
              />
            </CustomLink>
          </Grid>
          {additionalData.blog_topics.results.map((category, index) => {
            return (
              <Grid item md={2} key={index}>
                <CustomLink link={category}>
                  <CategoryTag
                    CategoryTagTitle={RichText.asText(
                      category.data.topic_name
                    )}
                    showIcon={false}
                  />
                </CustomLink>
              </Grid>
            );
          })}
        </Grid>
        <Grid
          container
          spacing={4}
          direction="row"
          justify="center"
          className="featureBlogsContainer"
        >
          {additionalData.blogs_for_category.map((blog, index) => {
            return (
              <Grid
                item
                xs={12}
                sm={6}
                md={index == 0 || index == 1 ? 6 : 4}
                key={index}
              >
                <PersonItem
                  link={blog}
                  showTag={
                    blog.data.blog_topics &&
                    blog.data.blog_topics.length > 0 &&
                    blog.data.blog_topics &&
                    blog.data.blog_topics.length > 0 &&
                    blog.data.blog_topics[0].blog_topic.data &&
                    blog.data.blog_topics[0].blog_topic.data.topic_name
                  }
                  tag={
                    blog.data.blog_topics &&
                    blog.data.blog_topics.length > 0 &&
                    blog.data.blog_topics[0].blog_topic.data &&
                    blog.data.blog_topics[0].blog_topic.data.topic_name ? (
                      <CustomLink link={blog.data.blog_topics[0].blog_topic}>
                        {RichText.asText(
                          blog.data.blog_topics[0].blog_topic.data.topic_name
                        )}
                      </CustomLink>
                    ) : null
                  }
                  bannerImageSrc={blog.data.featured_image.url}
                  bannerImageAlt={blog.data.featured_image.alt}
                  name={RichText.asText(blog.data.title)}
                  title={Date(blog.data.date).toLocaleDateString(
                   useRouter().locale,
                    dateOptions
                  )}
                />
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </div>
  );
};

AllBlogsInTopic.propTypes = {
  slice: shape({
    primary: shape({
      title: array.isRequired,
    }).isRequired,
  }).isRequired,
};

export default withStyles(style)(AllBlogsInTopic);
