const styles = (theme) => ({
  eventDetailsVideo: {
    clipPath: "polygon(0 5%, 100% 0, 100% 100%, 0% 100%)",
    position: "relative",
    backgroundColor: "#397DB7",
    backgroundImage: `url('/blueCirclePattern.png')`,
    backgroundPosition: "30% 151%",
    backgroundSize: "150% 70%",
    backgroundRepeat: "no-repeat",
    paddingTop: theme.spacing(20),
    paddingBottom: theme.spacing(10),
    
    [theme.breakpoints.down("sm")]: {
      clipPath: "polygon(0 4%, 100% 0, 100% 100%, 0% 100%)",
      paddingTop: theme.spacing(18),
      paddingBottom: theme.spacing(9),
    },
    [theme.breakpoints.down("xs")]: {
      clipPath: "polygon(0 3%, 100% 0, 100% 100%, 0% 100%)",
      paddingTop: theme.spacing(16),
      paddingBottom: theme.spacing(8),
    },
    "& .thumbnailWrapper": {
      position: "relative",
      cursor:'pointer',
      "& .playBtn": {
        height: 69,
        width: 105,
        backgroundImage: `url('/playBtnBg.png')`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        position: "absolute",
        borderRadius: 8,
        boxShadow: "0px 2px 6px 0px #1f1f1f",
        top: "50%",
        left: "50%",
        right: "50%",
        cursor: "pointer",
        transform: "translateY(-50%) translateX(-50%)",
        [theme.breakpoints.down("844")]: {
          height: 60,
          width: 90,
        },
        [theme.breakpoints.down("xs")]: {
          height: 50,
          width: 80,
          borderRadius: 8,
        },
      },
    },
    "&::after": {
      clipPath: "polygon(0 15%, 100% 0, 100% 95%, 0% 100%)",
      background:
        "linear-gradient(90deg, rgba(140,194,75,1) 70%, rgba(74,146,207,1) 30%)",
      content: '""',
      position: "absolute",
      left: 0,
      top: "-94.7%",
      right: 0,
      height: "100%",
      display: "block",
      zIndex: 9,
      [theme.breakpoints.down("sm")]: {
        top: "-95.7%",
        clipPath: "polygon(0% 0%, 100% 10%, 100% 96%, 0% 100%);",
      },
      [theme.breakpoints.down("xs")]: {
        top: "-96.7%",
        clipPath: "polygon(0% 0%, 100% 10%, 100% 97%, 0% 100%)",
      },
    },

    "& .speakerTitleSubWrapper": {
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(10),
      [theme.breakpoints.down("sm")]: {
        paddingTop: theme.spacing(9),
        paddingBottom: theme.spacing(9),
      },
      [theme.breakpoints.down("xs")]: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
      },
    },
    "& .getTickerSpeaker": {
      margin: "64px auto 0px",
      display: "block",
      [theme.breakpoints.down("sm")]: {
        margin: "56px auto 0px",
      },
      [theme.breakpoints.down("xs")]: {
        margin: "48px auto 0px",
      },
    },
  },
});
export default styles;
