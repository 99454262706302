import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { RichText } from "prismic-reactjs";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import style from "./style";

const BlogPostRichText = ({ classes, slice }) => {
  const { primary } = slice;
  return (
    <Container maxWidth="lg" className={classes.blogPostRichText}>
      <Grid container justify="center">
        <Grid item xs={12} sm={10} md={8}>
          {RichText.render(primary.richText)}
        </Grid>
      </Grid>
    </Container>
  );
};

BlogPostRichText.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({
    }).isRequired,
    items: PropTypes.array.isRequired,
  }).isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(style)(BlogPostRichText);
