import React, { useState } from "react";
import PropTypes from "prop-types";
import { RichText } from "prismic-reactjs";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import {
  structuredTextPropTypes,
  imagePropTypes,
} from "../../utils/prop-types";
import TitleSubtitle from "../../components/TitleSubtitle";
import Lightbox from "lightbox-react";
import Image from "next/image";

const WorkDetailsGallery = ({ slice, classes }) => {
  const { primary, items } = slice;
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Container className={classes.workDetailsGallery}>
      {primary.sectionTitle && primary.sectionSubtitle && (
        <div className="galleryContainerTitleSub">
          <TitleSubtitle
            title={RichText.asText(primary.sectionTitle)}
            subtitle={RichText.asText(primary.sectionSubtitle)}
          />
        </div>
      )}
      <Grid container spacing={4} className="galleryContainer">
        {items.map((item, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            onClick={() => {
              setIsOpen(true);
              setPhotoIndex(index);
            }}
          >
            <img src={item.image.url} alt={item.image.alt} />
          </Grid>
        ))}
      </Grid>
      {isOpen && (
        <Lightbox
          mainSrc={items[photoIndex].image.url}
          nextSrc={items[(photoIndex + 1) % items.length].image.url}
          prevSrc={
            items[(photoIndex + items.length - 1) % items.length].image.url
          }
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + items.length - 1) % items.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % items.length)
          }
        />
      )}
    </Container>
  );
};

WorkDetailsGallery.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({
      sectionTitle: structuredTextPropTypes,
      sectionSubtitle: structuredTextPropTypes,
    }).isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        photo: imagePropTypes,
      })
    ).isRequired,
  }).isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(style)(WorkDetailsGallery);
