import * as venmo from 'braintree-web/venmo'
import { BraintreeBranch } from '../../../models/braintree/DonationModels'
import IPaymentFormInfo from '../../../models/braintree/IPaymentFormInfo'
import Logger from '../../error-logging-service'
import { VenmoResult } from './bt-venmo-models'

const VenmoService = {

    branchSupportedByVenmo: (branch: BraintreeBranch) : boolean=> {
        return branch === BraintreeBranch.US
    },
    payWithVenmo: async (authorizationToken: string, paymentInfo: IPaymentFormInfo): Promise<VenmoResult> => {

        try {

            let venmoInstance = await venmo.create({
                authorization: authorizationToken,
                paymentMethodUsage: paymentInfo.isRecurring ? 'multi_use' : 'single_use',
                allowDesktop: true,
                allowNewBrowserTab: false
            })

            const venmoResult = await venmoInstance.tokenize()
            Logger.logTrace("venmo tokenization result", {venmoResult})
            return { success: true, nonce: venmoResult.nonce, username: venmoResult.details.username }
        }
        catch (venmoError) {
            if (venmoError.code === "VENMO_DESKTOP_CANCELED"){
                return { success: true, error: venmoError }
            }
            Logger.logError(venmoError)
            return { success: false, error: venmoError }
        }
    }
}

export default VenmoService