import React, { useState } from "react";
import PropTypes from "prop-types";
import { RichText, Date } from "prismic-reactjs";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import style from "./style";
import { structuredTextPropTypes } from "../../utils/prop-types";
import PersonItem from "../../components/PersonItem";
import TitleSubtitle from "../../components/TitleSubtitle";
import { useAdditionalData } from "../../utils/additional-data-context";
import CustomLink from "../../components/CustomLink";
import VideoModal from "../../components/VideoModal";
import ThumbnailVideo from "../../components/ThumbnailVideo";
import ButtonBase from "@material-ui/core/ButtonBase";
import { Typography } from "@material-ui/core";

const dateOptions = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
};

const EventVideos = ({ slice, classes }) => {
  const { primary, items } = slice;
  const [openModal, setOpenModal] = useState(false);
  const [videoUrl, setVideoUrl] = useState(false);

  const openVideoDialog = () => {
    setOpenModal(true);
  };

  const closeVideoDialog = () => {
    setOpenModal(false);
  };
  return (
    <div className={classes.eventVideos}>
      <Container maxWidth="lg">
        <TitleSubtitle title={RichText.asText(primary.title)} />
        <Grid
          container
          spacing={4}
          direction="row"
          justify="center"
          className="featureBlogsContainer"
        >
          {items.map((item) => {
            return (
              <Grid
                items
                xs={12}
                sm={6}
                md={6}
                onClick={() => {
                  setVideoUrl(item.vimeoLink);
                  openVideoDialog();
                }}
              >
                <div className="thumbnailWrapper">
                  <ThumbnailVideo
                    thumbnailVideoUrl={item.thumbnail.url}
                    thumbnailVideoAlt={item.thumbnail.alt}
                  />
                  <ButtonBase
                    className="playBtn"
                    onClick={openVideoDialog}
                  ></ButtonBase>
                  <div className="titleDescWrapper">
                    <Typography variant="h4" className="title">
                      {RichText.asText(item.title)}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      className="shortDescription"
                    >
                      {RichText.asText(item.shortDescription)}
                    </Typography>
                  </div>
                </div>
              </Grid>
            );
          })}
        </Grid>
        <VideoModal
          open={openModal}
          handleClose={closeVideoDialog}
          video={videoUrl}
        />
      </Container>
    </div>
  );
};

EventVideos.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({
      title: structuredTextPropTypes,
    }).isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        blogPost: PropTypes.objectOf(PropTypes.any),
      })
    ).isRequired,
  }).isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(style)(EventVideos);
