const styles = (theme) => ({
  customShare: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: theme.direction === "ltr" ? 'row' : 'row-reverse',
  },
  socialButton: {
    color: "#9ad455 !important",
    margin: "0px 4px",
    outline: "none",
    cursor: "pointer",
    borderRadius: 4,
    backgroundColor: "#fff !important",
    padding: "4px 4px 0px !important",
    "&:hover": {
      color: "#9ad455 !important",
    },
  },
  shareButton: {
    width: 40,
    height: 40,
    padding: 10,
    boxShadow: " 0 8px 18px 0 rgba(121, 125, 176, 0.28)",
    backgroundColor: "#9ad455",
    borderRadius: 4,
    cursor: 'pointer',
  },
});

export default styles;
