
import { BraintreeBranch, BraintreeEnvironment, CurrencyCode, LocalPaymentProviders, PaymentErrorType, PaymentMethod, RecurringPaymentBasket, SinglePaymentBasket } from '../../../models/braintree/DonationModels'
import regionHelpers from '../../regionHelpers';
import Logger from '../../error-logging-service'
import IPaymentFormInfo, { IPaymentUIEvents } from '../../../models/braintree/IPaymentFormInfo'
import analyticsService from '../../analytics-service'
import errorLoggingService from '../../error-logging-service'
import getConfig from 'next/config'
import SecurityService from '../../security-service';
import BraintreeClientTokenResult from '../../../models/braintree/BraintreeClientTokenResult';
import CreditCardService from '../credit-card/bt-credit-card-service';
import VenmoService from '../venmo/bt-venmo-service';
import GooglePayService from '../google-pay/bt-google-pay-service';
import PaypalService from '../paypal/bt-paypal-service';
import ThreeDSecureService, { ThreeDSecureCreateResult, ThreeDSecureInstance } from '../bt-three-d-secure-service';
import BraintreeConfig from './braintree-config';
import ApplePayService from '../apple-pay/bt-apple-pay-service';
import { PaypalInitResult } from '../paypal/bt-paypal-models';
import { CreditCardInitResult } from '../credit-card/bt-credit-card-models';
import LocalPayService from '../local-pay/bt-local-payment-service';
import React from 'react';
import { PaymentProviderHTMLElements, PaymentProviderHTMLElement, MakePaymentGenericFunction } from './bt-models-shared';
import { ILocalPaymentLogInsertResult, ITransactionResult } from '../../transaction-logging-models';
import { Button } from '@material-ui/core';
import { config } from 'process';
const reCAPTCHA_site_key = getConfig().publicRuntimeConfig.recaptchaSiteKey
const data_token_key: string = "data-token"
const transaction_log_id_key: string = "transaction-log-id"
declare global {
    interface Window {
        paypal: any;
        $: any
        grecaptcha: any;
        google: any;
        ApplePaySession?: any;
    }
}

///This class handles interaction with the braintree api from the front end
/// it calls the backend to make the payment then handles returning success or error messages to the component
/// it calls out to various payment-type sepcific services for credit card, paypal etc
const braintreeClientService = {

    getVerificationUrl: (paymentInfo: IPaymentFormInfo, uiEvents: IPaymentUIEvents) : string => {
        
        const config = getConfig().publicRuntimeConfig;

        const branch = regionHelpers.findBranchFromCountryCode(paymentInfo.address.country.code)

        Logger.logTrace("branch",{branch})

        if (!branch || !BraintreeConfig.validateBranch(branch)) {
            errorLoggingService.logTrace("invalid branch", {branch: branch})
            uiEvents.onError(PaymentErrorType.Server, null)
            return null;
        }

        const merchantId =  config.braintreeClientTokens[branch].merchantId;
        const domain = config.braintreeEnvironment === BraintreeEnvironment.PRODUCTION ? "www" : "sandbox";

        return `https://${domain}.braintreegateway.com/merchants/${merchantId}/verified`

    },
    // when we finish filling in the address popup, and click proceed having chosen "credit card"
    // we need to intialise the braintree credit card form
    // this involves registering our form with braintree "hosted fields" which will automaticaly validate our credit card input
    // and on successful submission provide us with a secure encrypted token representing the user's card details
    // we can submit to our server without storing the credit card details or ever transferring them over the network
    initPaymentForm: async (paymentForm: HTMLElement, getPaymentInfo: () => IPaymentFormInfo,
        uiEvents: IPaymentUIEvents, paymentProviderButtons: PaymentProviderHTMLElements): Promise<any> => {

        try {

            var paymentInfo = getPaymentInfo()
            uiEvents.showLoading()

            Logger.logTrace("payment info",{paymentInfo})

            const branch = regionHelpers.findBranchFromCountryCode(paymentInfo.address.country.code)

            Logger.logTrace("branch",{branch})

            if (!branch || !BraintreeConfig.validateBranch(branch)) {
                errorLoggingService.logTrace("invalid branch", {branch: branch})
                uiEvents.onError(PaymentErrorType.Server, null)
                return;
            }

            const clientTokenResult = await getClientToken(branch, paymentInfo.currencyCode)

            Logger.logTrace("clientTokenResult",{clientTokenResult})

            if (!clientTokenResult.success || !clientTokenResult.token) {
                errorLoggingService.logError(clientTokenResult.error, { id: "init_payment_form", paymentInfo, branch })
                uiEvents.onError(PaymentErrorType.Server, clientTokenResult.error)
                return;
            }

            const transactionLogResult = await logTransactionStart(paymentInfo,branch)

            if (!transactionLogResult.success || !transactionLogResult.id){
                Logger.logTrace("Transaction log start error")
                uiEvents.onError(PaymentErrorType.Server, null)
                return;
            }

            const transactionLogId = transactionLogResult.id.toString()

            paymentForm.setAttribute(transaction_log_id_key,transactionLogId)

            setupPaymentProviderButtons(paymentProviderButtons, branch, 
                clientTokenResult.token,paymentInfo,transactionLogId);

            Logger.logTrace("Setup payment provider buttons")
            //initialise paypal, credit card form and threeDS security in parallel to save time rendering the form
            const promiseResults = await Promise.all([
                PaypalService.init(transactionLogId,clientTokenResult.token, paymentInfo, uiEvents, makePaymentGeneric, branch),
                CreditCardService.create(clientTokenResult.token, paymentInfo, uiEvents),
                ThreeDSecureService.create(clientTokenResult.token)
            ])

            const paypalResult : PaypalInitResult = promiseResults[0]
            const creditCardInitResult : CreditCardInitResult = promiseResults[1]
            const tdsCreateResult : ThreeDSecureCreateResult = promiseResults[2]

            if (!paypalResult.success) {
                // hide paypal button, inform withlove
                Logger.logTrace("Error initialising paypal",{paypalResult})
                return
            }else{
                Logger.logTrace("setting paypal button to be visible",{button: paymentProviderButtons.paypalButton})
                paymentProviderButtons.paypalButton?.setAttribute("style","display: flex !important");
            }
            if (!tdsCreateResult.success) {
                Logger.logTrace("error creating tds",{tdsCreateResult})
                uiEvents.onError(PaymentErrorType.ThreeDS, "error_creating_3ds")
                return
            }
            if (!creditCardInitResult.success) {
                Logger.logTrace("credit card init failure",{creditCardInitResult})
                uiEvents.onError(PaymentErrorType.CreditCard, "error_creating_credit_card")
                return
            }

            const onSubmitMakeCreditCardPayment = async (event) => {

                try {
                    event.preventDefault();
                    await tokenizeCardAndMakePayment(
                        transactionLogResult.id.toString(),
                        creditCardInitResult,
                        branch, 
                        getPaymentInfo, 
                        uiEvents, 
                        paypalResult, 
                        tdsCreateResult.tds, 
                        clientTokenResult.token)
                }
                catch (error) {
                    uiEvents.onError(PaymentErrorType.CreditCard, error)
                }
                finally {
                    uiEvents.hideLoading()
                }
            };``

            paymentForm.addEventListener('submit', onSubmitMakeCreditCardPayment, false)

        }
        catch (error) {
            Logger.logError(error, { page: "donations", action: "payment-error" });
            uiEvents.onError(PaymentErrorType.Server,null)
        }
        finally {
            uiEvents.hideLoading()
            Logger.logTrace("Payment init form complete")
        }
    },
    payWithLocalPaymentProvider: async (paymentProvider: LocalPaymentProviders, button: HTMLElement, 
        paymentInfo: IPaymentFormInfo, uiEvents: IPaymentUIEvents, locale: string,redirectButtonText: string)=>{

        try{
            uiEvents.showLoading()

            const branch = regionHelpers.findBranchFromCountryCode(paymentInfo.address.country.code)

            if (!branch) {
                Logger.logMessage(`Could not get branch when making a ${paymentProvider}  payment`)
                uiEvents.onError(LocalPayService.getErrorType(paymentProvider), { name: `${paymentProvider}_error`, message: "could not get branch" })
                return;
            }

            const token = button?.getAttribute(data_token_key)

            if (!token) {
                Logger.logMessage(`Could not get auth token when making a ${paymentProvider}  payment`)
                uiEvents.onError(LocalPayService.getErrorType(paymentProvider), null)
                return;
            }

            const transactionLogId = button?.getAttribute(transaction_log_id_key)

            if (!transactionLogId) {
                Logger.logMessage(`Could not get transactionLogId when making a ${paymentProvider}  payment`)
                uiEvents.onError(LocalPayService.getErrorType(paymentProvider), null)
                return;
            }

            const paymentResult = await LocalPayService.createPayment(transactionLogId, paymentProvider,token,branch,paymentInfo,locale,redirectButtonText)

            if (paymentResult.success){
                if (paymentResult.nonce){
                    await makePaymentGeneric(transactionLogId, PaymentMethod.LOCAL,branch,paymentResult.nonce,paymentInfo,uiEvents,null,token)
                } 
                if (paymentResult.suspectedDropOff){
                    uiEvents.handleSuspectedDropOff()
                }
            }else{
                uiEvents.onError(LocalPayService.getErrorType(paymentProvider),paymentResult.error)
            }
          
        }
        catch(error){
            uiEvents.onError(LocalPayService.getErrorType(paymentProvider),error)
        }
        finally{
            uiEvents.hideLoading()
        }
    },
    payWithVenmo: async (venmoButtonRef: HTMLElement, paymentInfo: IPaymentFormInfo, uiEvents: IPaymentUIEvents) => {

        try {

            uiEvents.showLoading()

            const branch = regionHelpers.findBranchFromCountryCode(paymentInfo.address.country.code)

            if (!branch) {
                Logger.logMessage("Could not get branch when making a venmo payment")
                uiEvents.onError(PaymentErrorType.Venmo, { name: "venmo_error", message: "could not get branch" })
                return;
            }

            const token = venmoButtonRef?.getAttribute(data_token_key)

            if (!token) {
                Logger.logMessage("Could not get auth token when making an venmo payment")
                uiEvents.onError(PaymentErrorType.Venmo, null)
                return;
            }

            const transactionLogId = venmoButtonRef?.getAttribute(transaction_log_id_key)

            if (!transactionLogId) {
                Logger.logMessage(`Could not get transactionLogId when making a venmo payment`)
                uiEvents.onError(PaymentErrorType.Venmo, null)
                return;
            }

            const nonceResult = await VenmoService.payWithVenmo(token, paymentInfo)

            if (nonceResult.nonce) {
                await makePaymentGeneric(transactionLogId,PaymentMethod.VENMO, branch, nonceResult.nonce, paymentInfo, uiEvents, null, token)
            } else {
                if (!nonceResult.success) {
                    uiEvents.onError(PaymentErrorType.Venmo, nonceResult.error)
                } else {
                    Logger.logTrace("venmo cancelled")
                }

            }
        }
        catch (error) {

            uiEvents.onError(PaymentErrorType.Venmo, error)
        }
        finally {
            uiEvents.hideLoading()
        }

    },
    payWithGoogle: async (googleButtonRef: HTMLElement, paymentInfo: IPaymentFormInfo, uiEvents: IPaymentUIEvents) => {

        try {

            const branch = regionHelpers.findBranchFromCountryCode(paymentInfo.address.country.code)

            if (!branch) {
                Logger.logMessage("Could not get branch when making a google payment")
                return;
            }

            const clientTokenResult = await getClientToken(branch, paymentInfo.currencyCode)

            if (!clientTokenResult.success || !clientTokenResult.token) {
                errorLoggingService.logError(clientTokenResult.error, { id: "init_payment_form", paymentInfo, branch })
                uiEvents.onError(PaymentErrorType.Server, clientTokenResult.error)
                return;
            }

            const transactionLogId = googleButtonRef?.getAttribute(transaction_log_id_key)

            if (!transactionLogId) {
                Logger.logMessage(`Could not get transactionLogId when making a google payment`)
                uiEvents.onError(PaymentErrorType.Google, null)
                return;
            }

            const nonceResult = await GooglePayService.makePayment(clientTokenResult.token, paymentInfo)

            if (nonceResult.nonce) {
                await makePaymentGeneric(transactionLogId, PaymentMethod.GOOGLE, branch, nonceResult.nonce, paymentInfo, uiEvents, null, clientTokenResult.token)
            } else {
                uiEvents.onError(PaymentErrorType.Google, { name: "google_error", message: "no nonce" })
            }

        }
        catch (error) {
            Logger.logError(error)
            uiEvents.onError(PaymentErrorType.Google, error)
        }
        finally {
            uiEvents.hideLoading()
        }
    },
    payWithApple: async (applePayButton: HTMLElement, paymentInfo: IPaymentFormInfo, uiEvents: IPaymentUIEvents, companyNameLabel: string) => {

        try {

            uiEvents.showLoading()

            const branch = regionHelpers.findBranchFromCountryCode(paymentInfo.address.country.code)

            if (!branch) {
                Logger.logMessage("Could not get branch when making an apple payment")
                uiEvents.onError(PaymentErrorType.ApplePay, null)
                return;
            }

            const authToken = applePayButton?.getAttribute(data_token_key)

            if (!authToken) {
                Logger.logMessage("Could not get auth token when making an apple payment")
                uiEvents.onError(PaymentErrorType.ApplePay, null)
                return;
            }

            const transactionLogId = applePayButton?.getAttribute(transaction_log_id_key)

            if (!transactionLogId) {
                Logger.logMessage(`Could not get transactionLogId when making an apple payment`)
                uiEvents.onError(PaymentErrorType.Google, null)
                return;
            }

            const applePayResult = await ApplePayService.makePayment(transactionLogId, branch, authToken, paymentInfo, uiEvents, makePaymentGeneric,companyNameLabel)
            if (!applePayResult.success) {
                Logger.logMessage("failed apple pay attempt", { result: applePayResult })
                uiEvents.onError(PaymentErrorType.ApplePay, null)
            }
        }
        catch (error) {
            Logger.logError(error)
            uiEvents.onError(PaymentErrorType.ApplePay, error)
        }
        finally {
            uiEvents.hideLoading()
        }
    },

}

const setupPaymentProviderButtons = (paymentProviderButtons: PaymentProviderHTMLElements, 
    branch: BraintreeBranch, authToken: string, paymentInfo: IPaymentFormInfo, transactionLogId: string) => {

    const showElement = (elementHolder: PaymentProviderHTMLElement) => {
       
        elementHolder.setShow(true)
        elementHolder.button.setAttribute(data_token_key, authToken)
        elementHolder.button.setAttribute(transaction_log_id_key,transactionLogId)
    }

    const hideElement = (elementHolder: PaymentProviderHTMLElement) => {
        elementHolder.setShow(false)
        elementHolder.button.setAttribute(data_token_key, "")
    }

    if (paymentProviderButtons.applePay?.button) {
        if (ApplePayService.deviceSupportsApplePay()) {
            Logger.logTrace("This device supports version 3 of Apple Pay.")
            showElement(paymentProviderButtons.applePay)
        } else {
            Logger.logTrace("device does not support apple pay, hiding apple pay button", paymentProviderButtons.applePay.button)
            hideElement(paymentProviderButtons.applePay)
        }
    }

    if (paymentProviderButtons.venmo?.button) {
        if (VenmoService.branchSupportedByVenmo(branch)) {
            showElement(paymentProviderButtons.venmo)
        } else {
            hideElement(paymentProviderButtons.venmo)
        }
    }

    if (paymentProviderButtons.klarna?.button){
        if (LocalPayService.supportsLocalPaymentMethod(LocalPaymentProviders.Klarna,paymentInfo)){
            showElement(paymentProviderButtons.klarna)
        }else{
            hideElement(paymentProviderButtons.klarna)
        }
    }
    if (paymentProviderButtons.ideal?.button){
        if (LocalPayService.supportsLocalPaymentMethod(LocalPaymentProviders.iDeal,paymentInfo)){
            showElement(paymentProviderButtons.ideal)
        }else{
            hideElement(paymentProviderButtons.ideal)
        }
    }
}

export default braintreeClientService


//PRIVATE METHODS
const logTransactionStart = async(paymentDetails: IPaymentFormInfo, branch: BraintreeBranch) : Promise<ILocalPaymentLogInsertResult> => {
   
       try {
           const url = `/api/donations/checkout/${branch}/log-transaction-start`;

           const response = await fetch(url, {
               method: "POST", body: JSON.stringify({ paymentDetails }),
               headers: { 'Content-Type': 'application/json', },
           })
       
           if (response.ok){
               const paymentResult = await response.json() as ILocalPaymentLogInsertResult
       
               return paymentResult
           }
       
           return {success:false, id:null}
       }
       catch (error){
           Logger.logTrace("local payment error",{error})
           Logger.logError(error)
           return {success:false, id:null}
       }
       finally{

       }

}



//todo - move this to the credit card service
const tokenizeCardAndMakePayment = async (transactionLogId: string, creditCardInitResult: CreditCardInitResult,
    branch: BraintreeBranch, getFieldValues: () => IPaymentFormInfo,
    uiEvents: IPaymentUIEvents, 
    paypalInitResult: PaypalInitResult,
    tds: ThreeDSecureInstance, 
    clientAuthToken: string) => {

    try {

        if (paypalInitResult?.paypalButtonActions) {
            Logger.logTrace("disabling paypal buttons")
            paypalInitResult.paypalButtonActions.disable()
        }

        const paymentInfo = getFieldValues()

        const tokenizationPayload = await CreditCardService.tokenizeCard(creditCardInitResult.hostedFieldsInstance, paymentInfo)

        if (!tokenizationPayload) {
            uiEvents.onError(PaymentErrorType.CreditCard, { message: "tokenization_error" })
            return;
        }

        uiEvents.onSubmit()

        // so called as it will be regenerated in make payment generated
        const interimBasketForInfoOnly = await getBasket(uiEvents, paymentInfo,
            PaymentMethod.CREDIT_CARD, tokenizationPayload.nonce, null)

        if (!interimBasketForInfoOnly) {
            uiEvents.onError(PaymentErrorType.CreditCard, { message: "no_basket_error" })
            return;
        }

        const tdsVerifyCardResult = await ThreeDSecureService
            .verifyCard(tds, interimBasketForInfoOnly.totalAmount, tokenizationPayload, paymentInfo, branch)

        if (!tdsVerifyCardResult.success) {
            uiEvents.onError(PaymentErrorType.ThreeDS, tdsVerifyCardResult.error)
            uiEvents.hideLoading()
            return;
        }

        Logger.logTrace("Successful 3ds validation")

        interimBasketForInfoOnly.braintree_payment_nonce = tdsVerifyCardResult.verifyCardResult.nonce
        await makePaymentGeneric(transactionLogId, PaymentMethod.CREDIT_CARD, branch, interimBasketForInfoOnly.braintree_payment_nonce,
            paymentInfo, uiEvents, paypalInitResult, clientAuthToken);
    }
    catch (error) {
        Logger.logError(error, { type: "tokenize_card_and_make_payment" })
        uiEvents.onError(PaymentErrorType.CreditCard, error)
        uiEvents.hideLoading()
    }
    finally {
        Logger.logTrace("finished tokenize card and make payment")
        if (paypalInitResult?.paypalButtonActions) {
            paypalInitResult.paypalButtonActions.enable()
        }
    }
}

const getBasket = async (uiEvents: IPaymentUIEvents,
    paymentInfo: IPaymentFormInfo, paymentMethod: PaymentMethod, nonce: string, deviceData: string) => {

    const recaptchaToken = await SecurityService.getRecaptchaToken("submit")

    if (!recaptchaToken) {
        uiEvents.onError(PaymentErrorType.Recaptcha, { errorCodes: ['Do you have an adblocker or a password manager enabled? These can intefere with our anti-fraud detection. Please disable them and try again.'] })
        return
    }

    const basket = paymentInfo.isRecurring
        ? new RecurringPaymentBasket(nonce, paymentMethod, paymentInfo, recaptchaToken, deviceData)
        : new SinglePaymentBasket(nonce, paymentMethod, paymentInfo, recaptchaToken, deviceData);

    return basket;
}

///Once we have the braintree payment nonce, we can hit the same backend method to process the transaction for any payment type
// regardless whether it is paypal, credit card, apple pay or other
export const makePaymentGeneric : MakePaymentGenericFunction = async (transactionLogId: string, 
    paymentMethod: PaymentMethod, branch: BraintreeBranch,
    nonce: string, paymentInfo: IPaymentFormInfo, uiEvents: IPaymentUIEvents,
    paypalInitResult: PaypalInitResult, clientAuthToken: string): Promise<void> => {

    try {
        uiEvents.showLoading()

        const deviceDataResult = await BraintreeConfig.getDeviceData(clientAuthToken, paymentMethod === PaymentMethod.PAYPAL)

        if (!deviceDataResult.success) {

            errorLoggingService.logError(deviceDataResult.error, { id: "init_payment_form", type: "device_data" })

            //todo - unify error type with payment type somehow
            uiEvents.onError(PaymentErrorType.CreditCard, deviceDataResult.error)
            uiEvents.hideLoading()
            return;
        }

        const basket = await getBasket(uiEvents, paymentInfo, paymentMethod, nonce, deviceDataResult.deviceData);

        if (!basket) {
            return;
        }

        const transactionType = paymentInfo.isRecurring ? 'recurring' : 'single';
        const url = `/api/donations/checkout/${transactionType}-transaction/${branch}`;

        const response = await fetch(url, {
            method: "POST", body: JSON.stringify({
                transactionLogId,
                paymentDetails: basket,
                twoLetterLanguageCode: paymentInfo.twoLetterLanguageCode
            }),
            headers: { 'Content-Type': 'application/json', },
        })

        const paymentResult = await response.json()

        if (response.ok) {

            Logger.logMessage(`We made a ${paymentMethod} ${transactionType} payment! Firing onSuccess`, paymentResult);

            if (paypalInitResult?.paypalCheckoutInstance) {
                await paypalInitResult.paypalCheckoutInstance.teardown()
            }

            const transactionId = paymentInfo.isRecurring ? paymentResult.subscriptionId : paymentResult.transactionId

            paymentInfo.isRecurring ?
                analyticsService.logRecurringDonation(basket as RecurringPaymentBasket, transactionId)
                : analyticsService.logSingleDonation(basket as SinglePaymentBasket, transactionId)

            paymentInfo.onSuccess(paymentInfo.isRecurring ? paymentResult.subscriptionId : paymentResult.transactionId);
        } else {

            if (paymentResult.recaptchaError) {
                uiEvents.onError(PaymentErrorType.Recaptcha, paymentResult)
            } else if (paymentMethod === PaymentMethod.PAYPAL) {
                uiEvents.onError(PaymentErrorType.Paypal, paymentResult)
            } else {
                uiEvents.onError(PaymentErrorType.CreditCard, paymentResult)
            }

        }
        if (paypalInitResult?.paypalButtonActions) {
            paypalInitResult.paypalButtonActions.enable()
        }
    }
    catch (error) {
        Logger.logError(error, { page: "donations", action: "error-making-payment", paymentMethod });
        uiEvents.onError(PaymentErrorType.Server, error)
    }
    finally {
        uiEvents.hideLoading()
    }
}

export const getTransactionDetails = async (transactionLogId: string) :Promise<ITransactionResult> => {

    const response = await fetch(`/api/donations/get-transaction?tlid=${transactionLogId}`, {
        method: "GET",
        headers: { 'Content-Type': 'application/json', },
    });

    if (!response.ok){
        return {success: false, transactionLogId}
    }

    const result = await response.json()     

    console.log("get transaction details result",result)

    return result?.result as ITransactionResult
}

export const getClientToken = async (branch: BraintreeBranch, currency: CurrencyCode): Promise<BraintreeClientTokenResult> => {

    try{
        const tokenResponse = await fetch(`/api/donations/token/${branch}/${currency}`, {
            method: "GET",
            headers: { 'Content-Type': 'application/json', },
        });

        if (tokenResponse.ok) {
    
            const tokenObj = await tokenResponse.json()     
            return {
                success: true,
                token: tokenObj.token
            }
        } else {
            const error = new Error(`${tokenResponse.status}`)
            Logger.logError(error, { branch, currency })
            return { success: false, error }
        }
    }
    catch (error){
        Logger.logError(error, { branch, currency })
        return { success: false, error }
    }
    finally{
Logger.logTrace("Finally in get client token")
    }

}
