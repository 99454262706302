const styles = (theme) => ({
  eventVideos: {
    position: "relative",
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(9),
      marginBottom: theme.spacing(9),
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8),
    },
    "& .thumbnailWrapper": {
      position: "relative",
      cursor: "pointer",
      "& .playBtn": {
        height: 69,
        width: 105,
        backgroundImage: `url('/playBtnBg.png')`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        position: "absolute",
        borderRadius: 8,
        boxShadow: "0px 2px 6px 0px #1f1f1f",
        top: "50%",
        left: "50%",
        right: "50%",
        cursor: "pointer",
        transform: "translateY(-70%) translateX(-50%)",
        [theme.breakpoints.down("844")]: {
          height: 60,
          width: 90,
        },
        [theme.breakpoints.down("xs")]: {
          height: 50,
          width: 80,
          borderRadius: 8,
        },
      },
      "& .titleDescWrapper": {
        marginTop: theme.spacing(-4),
        paddingLeft: theme.spacing(4),
        "& .title": {
          color: "#4c4c78",
          fontWeight: 700,
          marginBottom: 16,
        },
        "& .shortDescription": {
          color: "#4c4c78",
          fontWeight: 500,
          lineHeight: 1.44,
        },
      },
    },
  },
});
export default styles;
