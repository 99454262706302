import AddressForm from '../AddressForm';
import analyticsService from '../../../services/analytics-service';
import IPaymentFormInfo from '../../../models/braintree/IPaymentFormInfo';
import React, { useState } from 'react';

import SimpleDialog from '../../../components/Modal';
import style from './style';
import Typography from '@material-ui/core/Typography';
import { Address } from '../../../models/braintree/CountryConfig';

import { Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { withStyles } from '@material-ui/core';
import { RichText } from 'prismic-reactjs';
import { useLayout } from '../../../utils/layout-context';
import { ExtraImpact } from '../ExtraImpact';
import Logger from '../../../services/error-logging-service';
import {
  BraintreeBranch,
  PaymentErrorType,
} from '../../../models/braintree/DonationModels';
import regionHelpers from '../../../services/regionHelpers';

interface IAddressPopupProps {
  classes: any;
  openDonationPopup: boolean;
  closeAllPopups: () => void;
  getPaymentInfo: () => IPaymentFormInfo;
  openPayNowPopup: () => void;
  address: Address;
  handleAddressChange: (e: any) => void;
  setCheckbox: (id: string, e: any) => void;
  mappedCheckboxes: (checkbox: string) => boolean;
}

const AddressPopup = ({
  classes,
  openDonationPopup,
  closeAllPopups,
  openPayNowPopup,
  address,
  handleAddressChange,
  setCheckbox,
  mappedCheckboxes,
  getPaymentInfo,
}: IAddressPopupProps) => {

  const layout = useLayout().layout;

  const [
    addressValidationError,
    setAddressValidationError,
  ] = useState<string>();

  // todo - translatable messages. Hardcoded to English for now so we can get it out for testing.
  // todo - place error next to relevant field

  const validateAddress = (): boolean => {
    if (isBlank(address.country?.code)) {
      Logger.logMessage('country code missing', { address });
      setAddressValidationError('Country code missing');
      return false;
    }

    if (isBlank(address.dialingCode)) {
      Logger.logMessage('dialing code missing', { address });
      setAddressValidationError('Dialing code missing');
      return false;
    }

    if (isBlank(address.email)) {
      Logger.logMessage('email missing', { address });
      setAddressValidationError('Email missing');
      return false;
    }

    if (address.country.code === 'US') {
      if (isBlank(address.state)) {
        Logger.logMessage('state missing for US transaction', { address });
        setAddressValidationError('State missing');
        return false;
      }
    }

    return true;
  };

  function isBlank(str) {
    return !str || /^\s*$/.test(str);
  }

  const isCountryCurrencyMatch = () =>
    address.country
      ? getPaymentInfo().currencyCode ===
        regionHelpers.getCountryFromCode(address.country.code).vfiCurrencyCode
      : // If no country is selected it's a match
        true;

  return (
    <SimpleDialog
      onRendered={() => {
        analyticsService.logInitiateCheckout(getPaymentInfo());
      }}
      open={openDonationPopup}
      handleClose={closeAllPopups}
    >
      <div className={classes.modal}>
        <Typography variant="h5" className={classes.modalTitle}>
          {RichText.asText(layout.personal_details_popup_title)}{' '}
        </Typography>
        {!isCountryCurrencyMatch() && (
          <Alert severity="warning">
            {layout.country_currency_mismatch_warning}
          </Alert>
        )}
        <div className={classes.modalSectionTitle}>
          {RichText.asText(layout.personal_details_popup_title)}
        </div>
        <form
          onSubmit={(e) => {
            setAddressValidationError('');
            e.preventDefault();

            if (!validateAddress()) {
              Logger.logMessage('could not validate address', { address });
              return;
            }

            openPayNowPopup();
          }}
        >
          <AddressForm
            address={address}
            handleAddressChange={handleAddressChange}
          />

          <ExtraImpact
            classes={classes}
            address={address}
            mappedCheckboxes={mappedCheckboxes}
            setCheckbox={setCheckbox}
          />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            className={classes.proceedBtn}
          >
            {RichText.asText(layout.proceed_button_text)}
          </Button>
          {addressValidationError && (
            <Alert severity="error">
              {addressValidationError}
            </Alert>
          )}
        </form>
        <div className={classes.tnc}>
          {/* TODO - once we go live and old vfi is switched off, rename privacy_text1 to something better */}
          {RichText.render(layout.privacy_text1)}
        </div>
        <div className={classes.recaptcha}>
          <p>
            This site is protected by reCAPTCHA.
            <br />
            The Google{' '}
            <a href="https://policies.google.com/privacy">Privacy Policy</a> and
            <a href="https://policies.google.com/terms">
              {' '}
              Terms of Service
            </a>{' '}
            apply.
          </p>
        </div>
      </div>
    </SimpleDialog>
  );
};

export default withStyles(style)(AddressPopup);
