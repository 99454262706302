const styles = (theme) => ({
  contactBranchMainWrapper: {
    backgroundColor: '#fff',
    backgroundImage: '-webkit-linear-gradient(-84deg, #fff 18%, #f4f5f6 18%)',
  },
  contactOurBranch: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(9),
      paddingBottom: theme.spacing(9),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
  },
  branchWrapper: {
    paddingBottom: theme.spacing(10),
    zIndex: 9,
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(9),
    },
    [theme.breakpoints.down('xs')]: {
      paddingBottom: theme.spacing(8),
    },
    '& .branchCard': {
      height: '100%',
      borderRadius: 6,
      boxShadow: '0 23px 42px 0 rgba(106, 118, 154, 0.15)',
      backgroundColor: '#ffffff',
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down('sm')]: {
        height: 'auto',
      },
      '& .bannerImg': {
        height: 350,
        width: '100%',
        objectFit:'cover',
      },
      '& .branchCardData': {
        padding: theme.spacing(3.5, 3.5, 1),
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(2, 2, 0.5),
        },
        '& .country': {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginBottom: theme.spacing(4),
          [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(3),
          },
          [theme.breakpoints.down('xs')]: {
            marginBottom: theme.spacing(2),
          },
          '& img': {
            height: 33,
          },
          '& .countryName': {
            color: '#4c4c78',
            fontWeight: 700,
            marginLeft: theme.spacing(2),
          },
        },
        '& .infoTitle': {
          color: '#4c4c78',
          margin: theme.spacing(0, 0),
        },
        '& .infoData': {
          color: '#4c4c78',
          fontWeight: 500,
          margin: theme.spacing(0),
          fontFamily: 'Raleway,Assistant,sans-serif',
          "& a": {
            color: '#4c4c78',
            borderBottom: '1.5px solid #4c4c78',
            textDecoration: 'none',
          }
        },
      },
    },
  },
})
export default styles