const styles = (theme) => ({
  blogPostTestimonials:{
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
  },
  workDetailsTestimonials: {
    backgroundColor: '#ecf1f2',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius:16,
    padding: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4),
      borderRadius:12,
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(3),
      borderRadius:8,
    },
    // '& .overlay': {
    //   height: '100%',
    //   width: '100%',
    //   display: 'flex',
    //   alignItems: 'center',
    //   flexDirection: 'column',
    //   justifyContent: 'center',
    //   backgroundColor: 'rgba(0,0,0,0.5)',
    //   paddingTop: theme.spacing(10),
    //   paddingBottom: theme.spacing(10),
    //   [theme.breakpoints.down('sm')]: {
    //     paddingTop: theme.spacing(9),
    //     paddingBottom: theme.spacing(9),
    //   },
    //   [theme.breakpoints.down('xs')]: {
    //     paddingTop: theme.spacing(8),
    //     paddingBottom: theme.spacing(8),
    //   },
    // },
    '& .testimonialText': {
      fontStyle: 'italic',
      color: '#4c4c78',
      textAlign: 'center',
      lineHeight: 1.75,
    },
    '& .author': {
      color: '#4c4c78',
      textAlign: 'center',
      lineHeight: 1.42,
      marginTop: theme.spacing(5),
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(4),
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(3),
      }
    },
  },
})
export default styles