import { CurrencyCode } from "./DonationModels"

export default class CountryConfig {
    code: string
    label: string
    dialingCode: string
    suggested?: boolean
    vfiCurrencyCode: CurrencyCode
    nativeCurrencyCode: string
  }

export interface RegionConfig {
  code: string
  name: string
}

export class Address  {
  dialingCodeCountryConfig?: CountryConfig
  
  public constructor(init?:Partial<Address>) {
    Object.assign(this, init);
  }

  firstName: string
  lastName: string
  address1: string
  address2: string
  city:string
  email: string
  dialingCode: string
  phoneNumberLocal: string
  state: string
  zip: string
  country: CountryConfig
  signUpForEmail: boolean
  
  static getFullPhoneNumber(address: Address) :string {
      if (address.country?.dialingCode && address.phoneNumberLocal){
        return "+" + address.country.dialingCode + address.phoneNumberLocal
      }
        return ""
  }
}