import { createStyles, Theme } from "@material-ui/core"
import { secondary } from "../../../theme"

// Theme-dependent styles
const styles = (theme: Theme) => createStyles({
  codeWrapper: {
    display: 'flex',
    "& span": {
      marginRight: 4,
    }
  },
  addressformGrid: {
    flexDirection: theme.direction === "ltr" ? "row" : "row-reverse",
    '& input': {
      textAlign: theme.direction === "ltr" ? "left" : "right",
    }
  },
  donateMailList: {
    flexDirection: theme.direction === "ltr" ? "row" : "row-reverse",
    marginRight: theme.direction === "ltr" ? "0px" : '',
    marginLeft: theme.direction === "ltr" ? '' : '0px',
    width: '100%',
    alignItems: 'flex-start',
    '& .MuiCheckbox-root': {
      marginTop: '-6px',
    }
  },
  autocompletePaper: {
    '& .MuiAutocomplete-option': {
      direction: theme.direction === "ltr" ? "ltr" : "rtl",
    }
  },
});

export default styles;
