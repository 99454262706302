const styles = (theme) => ({
  whoWeAre: {
    backgroundColor: '#8bc249',
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(9),
      paddingBottom: theme.spacing(9),
      backgroundPosition: '100%',
      backgroundSize: 'cover',
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
      backgroundPosition: '100%',
      backgroundSize: 'cover',
    },
    '& .whoWeAreContainer': {
      marginTop: theme.spacing(10),
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(9),
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(8),
      },
      
    },
    '& .testimonialCardGrid':{
      textAlign:"center",
      cursor:"pointer",
      transition: 'transform .5s',
      "&:hover": {
        transform: 'scale(1.1)',
      },
    },
    '& .icon': {
      width: 80,
      height: 80,
    },
    '& .itemTitle': {
      color: '#fff',
      textAlign: 'center',
      margin: theme.spacing(2, 0),
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(1, 0),
      },
    },
    '& .anchorLinkText': {
      color: '#fff',
      textAlign: 'center',
      textDecoration: 'underline',
      cursor:"pointer"
    },
  },
})

export default styles