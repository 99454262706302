import React from "react";
import PropTypes from "prop-types";
import { RichText } from "prismic-reactjs";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import style from "./style";
import { structuredTextPropTypes } from "../../utils/prop-types";
import TitleSubtitle from "../../components/TitleSubtitle";
import { useAdditionalData } from "../../utils/additional-data-context";
import Image from "next/image";

const Endorsements = ({ slice, classes }) => {
  const { primary } = slice;
  const additionalData = useAdditionalData();
  return (
    <div className={classes.endorsements}>
      <Container maxWidth="lg">
        <Grid
          container
          spacing={4}
          direction="row"
          justify="center"
          alignItems="stretch"
        >
          {additionalData.endorsements.results.map((endorsement, i) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              container
              direction="row"
              justify="center"
              className={classes.testimonialCardGrid}
            >
              <div className="flexEnd">
                <div className={classes.testimonialCard}>
                  <Typography variant="subtitle2" className="testimonial">
                    {RichText.asText(endorsement.data.endorsement)}
                  </Typography>
                  <Typography variant="subtitle1" className="name">
                    {RichText.asText(endorsement.data.endorser_name)}
                  </Typography>

                  <Typography variant="subtitle2" className="testimonialTitle">
                    {RichText.asText(endorsement.data.endorser_title)}
                  </Typography>
                </div>
                <img
                  src={endorsement.data.endorser_profile_image.url}
                  alt="profile"
                  className={classes.testimonialImg}
                />
              </div>
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
};

Endorsements.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({
      title: structuredTextPropTypes,
      subtitle: structuredTextPropTypes,
      cta_button_text: structuredTextPropTypes,
    }).isRequired,
    items: PropTypes.array.isRequired,
  }).isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(style)(Endorsements);
