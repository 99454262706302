import { createStyles, Theme } from "@material-ui/core";
import { secondary } from "../../../theme";

// Theme-dependent styles
const styles = (theme: Theme) => createStyles({
  errorMessage: {
    color: 'red'
  },
  modal: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    color: "#4c4c78",
    fontFamily: "Raleway",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(3),
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2),
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: '#8bc249',
    },
    "& fieldset": {
      border: '2px solid #f3f3f3',
      "&:hover": {
        border: '2px solid #8bc249',
      }
    },
    '& .MuiInputBase-root': {
      backgroundColor: '#f3f3f3',
    },
  },
  modalTitle: {
    textTransform: "uppercase",
    fontWeight: 900,
    textAlign: "left",
    marginBottom: theme.spacing(2),
  },
  modalSectionTitle: {
    fontSize: 16,
    fontWeight: 700,
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(2.5),
    textAlign: theme.direction === "ltr" ? "left" : "right",
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(1.5),
      marginTop: theme.spacing(1.5),
    },
  },
  proceedBtn: {
    maxWidth: 350,
    margin: '0 auto',
    display: 'block',
    marginTop: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(3),
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(2),
    },
  },
  tnc: {
    "& p": {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: 1.71,
      textAlign: "center",
      marginTop: theme.spacing(2),
      "& span": {
        textDecoration: "none",
      },
      "& a": {
        color: "#4c4c78",
        textDecoration: "underline",
        textDecorationThickness: '1.5px',
      }
    },
  },
  extraImpactWrapper: {
    "& .MuiFormControlLabel-root": {
      alignItems: 'flex-start',
      '& .MuiCheckbox-root':{
        marginTop:'-6px',
      }
    }
  }
});

export default styles;
