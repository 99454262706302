import { Container, Grid, Typography, Button } from "@material-ui/core"
import React from "react"
import CustomLink from "../../../components/CustomLink"
import { useLayout } from "../../../utils/layout-context";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import style from './style'
import clsx from "clsx";

const ThankYouForDonating = ({ classes, transactionId }: { classes: any, transactionId: string }) => {

  const layout = useLayout().layout

  return <>
    <Container maxWidth="lg">
      <Grid container alignItems="center" justify="center" className={classes.thankYouForDonating}>
        <Grid item xs={12} sm={10} md={8} >
          <Typography variant="h5">{layout.thank_you_title}</Typography>
          <Typography className={classes.desc}>{layout.thank_you_description}</Typography>
          <div className={classes.thankYouTransID}>
            <Typography variant="h6">{layout.thank_you_transaction_id && layout.thank_you_transaction_id.replace('{{transaction_id}}', transactionId)}</Typography>
            <Typography className={clsx(classes.desc, classes.grayBgBox)}>{layout.thank_you_feedback_text}</Typography>
          </div>
          <CustomLink key={'a'} classes={classes} link={layout.survey_link} className={clsx(classes.surveyLinkTxt, 'adam')}>
            <Button variant="contained" color="primary" fullWidth >{layout.survey_link_text}</Button>
          </CustomLink>
          <CustomLink key={''} link={layout.continue_link}  className={classes.homepageLinkTxt}>
            <>{layout.homepage_link_text}</>
          </CustomLink>
        </Grid>
      </Grid>
    </Container>
  </>
}

ThankYouForDonating.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(style)(ThankYouForDonating);