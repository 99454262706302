const styles = (theme) => ({
  eventDetailsAbout: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
    backgroundColor: "#fafafd",
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(9),
      marginBottom: theme.spacing(9),
      padding: theme.spacing(3),
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8),
      padding: theme.spacing(2),
    },
    "& .title,h1,h2,h3,h4,h5,h6": {
      color: "#4c4c78",
      lineHeight: 1.75,
    },
    "& .subtitle,ul,p,a": {
      color: "#4c4c78",
      lineHeight: 1.89,
      fontWeight: 700,
      margin: theme.spacing(1, 0, 5),
    },
    "& ul": {
      paddingLeft: 16,
      fontSize: 16,
      [theme.breakpoints.down("sm")]: {
        fontSize: 15,
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: 14,
      },
    },
    "& .subtitleUnderline": {
      textDecoration: "underline",
    },
    "& .getTickets": {
      marginTop: theme.spacing(2.5),
    },
  },
});

export default styles;
