import React from "react";
import PropTypes from "prop-types";
// import { RichText } from 'prismic-reactjs'
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import Countdown, { zeroPad } from "react-countdown-now";
// import { structuredTextPropTypes, imagePropTypes } from '../../utils/prop-types'

const EventDetailsHero = ({ classes }) => {
  const divStyle = {};
  const Completionist = () => <div className={classes.completion}></div>;

  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      return (
        <>
          {zeroPad(days)}
          <span className="countType">days</span>
          <span className="countSlash">/</span>
          {zeroPad(hours)}
          <span className="countType">hours</span>
          <span className="countSlash">/</span>
          {zeroPad(minutes)}
          <span className="countType">minutes</span>
          <span className="countSlash">/</span>
          {zeroPad(seconds)}
          <span className="countType">seconds</span>
        </>
      );
    }
  };
  return (
    <div className={classes.background}>
      <div className={classes.backgroundOverlay}>
        <Grid container direction="column" justify="center" alignItems="center">
          <Typography variant="h1" className={classes.preTitle}>
            SUCCOT CELEBRATION 2020
          </Typography>

          <Typography variant="h1" className={classes.heroTitle}>
            Jerusalem, Israel
          </Typography>

          <Typography variant="h1" className={classes.heroSubTitle}>
            September 18-19, 2020
          </Typography>

          <div className={classes.heroButton}>
            <Button variant="contained" color="primary">
              GET TICKETS
            </Button>
          </div>
        </Grid>
        <div className={classes.eventDaysTime}>
          {/* <Countdown
              zeroPadTime={2}
              date={primary.event_start_date_and_time}
              renderer={renderer}
            /> */}
        </div>
      </div>
    </div>
  );
};



EventDetailsHero.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(style)(EventDetailsHero);
