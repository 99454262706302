const styles = (theme) => ({
  blogPostRichText: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    color: '#4c4c78',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    '& p':{
      margin:0,
      fontSize:20,
      fontWeight:500,
      lineHeight: '30px',
      letterSpacing: 0.4,
      wordSpacing: 4,
      [theme.breakpoints.down('sm')]: {
        fontSize:19,
        lineHeight: '28px'
      },
      [theme.breakpoints.down('xs')]: {
        fontSize:18,
        lineHeight: '26px',
      },
    },
    '& li':{
      fontSize:20,
      fontWeight:500,
      lineHeight: '30px',
      letterSpacing: 0.4,
      wordSpacing: 4,
      [theme.breakpoints.down('sm')]: {
        fontSize:19,
        lineHeight: '28px'
      },
      [theme.breakpoints.down('xs')]: {
        fontSize:18,
        lineHeight: '26px',
      },
    },
    '& .title': {
      lineHeight: 1.92,
      margin: theme.spacing(0),
    },
    '&  .boldText': {
      lineHeight: 2.13,
      margin: theme.spacing(7.5, 0, 2),
      fontWeight: 700,
    },
    '& .para': {
      lineHeight: 2.13,
      fontWeight: 500,
    },
  },
})

export default styles