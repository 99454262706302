import { createStyles, Theme } from "@material-ui/core";
import { secondary } from "../../../theme";

// Theme-dependent styles
const styles = (theme: Theme) => createStyles({
  thankYouForDonating: {
    color: '#4c4c78',
    textAlign: 'center',
    "& .MuiGrid-item": {
      padding: theme.spacing(5),
      borderRadius: 6,
      boxShadow: '0 7px 28px 0 rgba(184, 196, 214, 0.5)',
      backgroundColor: '#fff',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(4),
      },
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(3),
      }
    }
  },
  thankYouTransID: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    maxWidth: 475,
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
  desc: {
    maxWidth: 475,
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(1.5),
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(1),
    },
  },
  surveyLinkTxt: {
    maxWidth: 350,
    width: '100%',
  },
  homepageLinkTxt: {
    marginTop: theme.spacing(3),
    fontWeight: 700,
    color: '#4c4c78',
    textDecoration: 'underline',
    fontSize: 14,
    cursor: 'pointer',
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(2),
      fontSize: 13,
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(1),
      fontSize: 12,
    },
  },
  grayBgBox: {
    padding: theme.spacing(4),
    borderRadius: 6,
    backgroundColor: '#f3f3f3',
    fontWeight: 700,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(3),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  }
});

export default styles;
