import React from "react";
import PropTypes from "prop-types";
import { RichText } from "prismic-reactjs";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import style from "./style";
import { structuredTextPropTypes } from "../../utils/prop-types";
import PersonItem from "../../components/PersonItem";
import TitleSubtitle from "../../components/TitleSubtitle";
import { useLayout } from "../../utils/layout-context";
import { Element } from "react-scroll";
import CustomLink from "../../components/CustomLink";
const FeaturedMembers = ({ slice, classes }) => {
  const { primary, items } = slice;
  const { layout } = useLayout();
  return (
    <Container maxWidth="lg" className={classes.featuredMember}>
      <Element
        name={primary.anchorId}
        className="element"
      ></Element>
      <TitleSubtitle
        title={RichText.asText(primary.title)}
        subtitle={RichText.asText(primary.subtitle)}
      />
      <Grid
        container
        spacing={4}
        className="boardMemberItem"
        direction="row"
        justify="center"
        alignItems="center"
      >
        {items &&
          items.map((item) => {
            return (
              layout &&
              item &&
              item.person &&
              item.person.data &&
              item.person.data.person_type && (
                <Grid item xs={12} sm={6} md={4}>
                  <PersonItem
                    bannerImageSrc={item.person.data.portrait_image.url}
                    bannerImageAlt=""
                    name={RichText.asText(item.person.data.name)}
                    title={RichText.asText(item.person.data.title)}
                    btnLabel={RichText.asText(layout.full_bio_button_text)}
                    showTag={false}
                    showBtn
                    link={item.person}
                  />
                </Grid>
              )
            );
          })}
      </Grid>
      <Grid container direction="row" justify="center" alignItems="center">
        <CustomLink link={primary.ctaButtonLink}>
          <Button variant="contained" color="primary">
            {RichText.asText(primary.ctaButtonText)}
          </Button>
        </CustomLink>
      </Grid>
    </Container>
  );
};

FeaturedMembers.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({
      title: structuredTextPropTypes,
      subtitle: structuredTextPropTypes,
      cta_button_text: structuredTextPropTypes,
    }).isRequired,
    items: PropTypes.array.isRequired,
  }).isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(style)(FeaturedMembers);
