import ButtonGroup from "@material-ui/core/ButtonGroup"
import MenuItem from "@material-ui/core/MenuItem"
import React from "react"
import style from "./style"
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography"
import { Button, Grid, Select } from "@material-ui/core"
import { RichText } from "prismic-reactjs"
import { useLayout } from "../../../utils/layout-context"
import { useVFIStoreState, useVFIStoreActions } from '../../../store/vfi-easy-peasy-store'
import { withStyles } from "@material-ui/core"
import { allCurrencies } from '../../../services/currency-service'
import { CurrencyCode } from "../../../models/braintree/DonationModels";
import clsx from "clsx";

interface IAddADonationProps {
    classes: any
    addToBasket: () => void
    setAmount: any
    amount: string | number
    amountInput: React.MutableRefObject<any>
    selectedProjectIndex: number
    setSelectedProjectIndex: any
    availableProjects: { id: string, name: string }[]
    editOrSaveButtonText: string
}

export const AddADonation = ({ classes, addToBasket, setAmount, amount, amountInput, selectedProjectIndex, setSelectedProjectIndex, availableProjects, editOrSaveButtonText }: IAddADonationProps) => {

    const layout = useLayout().layout
    const state = useVFIStoreState(state => state);
    const actions = useVFIStoreActions(actions => actions)

    return (
        <div className={classes.donateLeft}>
            <Typography variant="h6" className={classes.givePageDonationCardTitle}>
                {RichText.asText(layout.give_page_donation_card_title)}
            </Typography>
            <div className={classes.divider} />
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    addToBasket();
                }}
            >
                <div className={classes.selectAmountText}>
                    {RichText.asText(layout.select_amount_text)}
                </div>
                <ButtonGroup color="primary" className={classes.amtGroup}>
                    {layout.amounts.map((amountConfig, i) => (
                        <Button
                            style={{ backgroundColor: amountConfig.amount == amount && "#4a92cf" }}
                            onClick={() => setAmount(amountConfig.amount)}
                            key={i}
                        >
                            {state.currency.symbol} {amountConfig.amount}
                        </Button>
                    ))}
                </ButtonGroup>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={4} md={3}>
                        <Select
                            displayEmpty
                            id="currency"
                            variant="outlined"
                            required
                            fullWidth
                            margin="dense"
                            value={state.currency.code}
                            onChange={(e) => {
                                actions.setCurrency(e.target.value as CurrencyCode)
                            }}
                            className={classes.custInput}
                        >
                            {allCurrencies.map((currency, i) => {
                                return <MenuItem className={classes.selectMenuItem} key={i} value={currency.code}>{currency.code} {currency.symbol}</MenuItem>
                            })}
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={8} md={9}>
                        <TextField
                            placeholder={RichText.asText(layout.other_amount_placeholder)}
                            fullWidth
                            required
                            inputRef={amountInput}
                            type="number"
                            variant="outlined"
                            margin="dense"
                            value={amount ? Number(amount) : ''}
                            onChange={(e) => {
                                setAmount(+e.target.value || null);
                            }}
                            className={clsx(classes.custInput, classes.amountInput)}
                        />
                    </Grid>
                </Grid>
                <div className={classes.dedicateDonationPlaceholder}>
                    {RichText.asText(layout.dedicate_donation_placeholder)}
                </div>
                <Select
                    displayEmpty
                    id="dedicateTo"
                    variant="outlined"
                    fullWidth
                    required
                    margin="dense"
                    value={selectedProjectIndex}
                    onChange={(e) => {
                        setSelectedProjectIndex(+e.target.value);
                    }}
                    className={classes.custInput}
                >
                    {/* <MenuItem disabled={true} value={-1} key={-1}><em>{layout.donation_dedication_option_empty_message}</em></MenuItem> */}
                    {availableProjects.map((project, i) => {
                        return <MenuItem className={classes.selectMenuItem} key={i} value={i}>{project.name}</MenuItem>
                    })}
                </Select> 
                <Button
                    disabled={selectedProjectIndex < 0 || !amount || amount <= 0}
                    variant="contained"
                    color="primary"
                    fullWidth
                    className={classes.editOrSaveButtonText}
                    type="submit"
                >{editOrSaveButtonText}</Button>

            </form>
        </div>
    )


}

export default withStyles(style)(AddADonation)


