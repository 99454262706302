import { createStyles, Theme } from "@material-ui/core";
import { secondary } from "../../../theme";

// Theme-dependent styles
const styles = (theme: Theme) => createStyles({
  payNowPopupWrapper: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    color: "#4c4c78",
    fontFamily: "Raleway,Assistant",
    position: 'relative',
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(3),
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2),
    },
  },
  modalTitle: {
    textTransform: "uppercase",
    fontWeight: 900,
    textAlign: "center",
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(3),
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(2),
    },
  },
  generalError: {
    color: 'red',
    fontWeight: 500,
    fontSize: 16,
    marginLeft: 0,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      marginLeft: 0,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
      marginLeft: 0,
    },
  },
  fieldError: {
    color: 'red',
    marginTop: 2,
    position: 'absolute',
    fontWeight: 500,
    fontSize: 12,
    marginLeft: 16,
    [theme.breakpoints.down("sm")]: {
      fontSize: 11.5,
      marginLeft: 12,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 11,
      marginLeft: 8,
    },
  },
  paypalErrors:{
    color: 'red',
    fontWeight: 500,
    fontSize: 16,
    marginTop: 16,
    [theme.breakpoints.down("sm")]: {
      fontSize: 15,
      marginTop: 12,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
      marginTop: 8,
    },
  },
  cardSize: {
    fontSize: 40,
  },
  totalAmt: {
    fontFamily: "Raleway,Assistant",
    fontSize: 16,
    fontWeight: 700,
    color: "#4c4c78",
    textAlign: "center",
    margin: theme.spacing(1, 0, 0),
  },
  braintreeEntryField: {
    fontFamily: "Raleway,Assistant",
    paddingLeft: 16,
    paddingRight: 16,
    borderRadius: 6,
    backgroundColor: "#ecf1f2",
    height: 48,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 12,
      paddingRight: 12,
      height: 40,
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 8,
      paddingRight: 8,
      height: 32,
    },
    "& svg": {
      position: 'absolute',
      right: 8,
      [theme.breakpoints.down("sm")]: {
        height: 32,
      },
      [theme.breakpoints.down("sm")]: {
        height: 24
      }
    }
  },
  proceedBtn: {
    maxWidth: 350,
    margin: '0 auto',
    display: 'block',
  },
  orWrapper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textAlign: 'center',
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5),
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  paypalButton: {
    maxWidth: 350,
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 6,
    width: '100%',
    height: 48,
    background: '#0070ba',
  },
  buttonProgress: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.2)',
  },
  paynowGrid:{
    flexDirection: theme.direction === "ltr" ? "row" : "row-reverse",
    '& input': {
      textAlign: theme.direction === "ltr" ? "left" : "right",
    }
  },
  applePayButton:{
    backgroundColor:'#000',
    color:'#fff',
    maxWidth: 350,
    height: 48,
    borderRadius: 6,
    margin: '0 auto',
    alignItems: 'center',
    fontWeight:600,
    textTransform:'unset',
    width:'100%',
    marginTop:16,
    marginBottom:16,
    '& svg':{
      fontSize:48,
      marginLeft:4,
    },
    '&:hover':{
      backgroundColor:'#000',
    }
  },
  showPaymentButton: {
    display:'flex !important'
  },
  hidePaymentButton: {
    display:'none !important'
  },
  venmoButton:{
    maxWidth: 350,
    margin: '16px auto',
    alignItems: 'center',
    justifyContent:'center',
    borderRadius: 24,
    width: '100%',
    height: 48,
    background: '#008CFF',
    '&:hover':{
      background: '#008CFF',
    },
    '& img':{
      display:'block',
      margin: '0px auto',
      height: 16,
    }
  },
  klarnaPayButton:{
    color: 'white',
    maxWidth: 350,
    margin: '16px auto',
    alignItems: 'center',
    justifyContent:'center',
    borderRadius: 24,
    width: '100%',
    height: 48,
    background: '#008CFF',
    '&:hover':{
      background: '#008CFF',
    },
    '& img':{
      display:'block',
      margin: '0px auto',
      height: 16,
    }
  },
  iDealPayButton:{
    color: 'white',
    maxWidth: 350,
    margin: '16px auto',
    alignItems: 'center',
    justifyContent:'center',
    borderRadius: 24,
    width: '100%',
    height: 48,
    background: '#008CFF',
    '&:hover':{
      background: '#008CFF',
    },
    '& img':{
      display:'block',
      margin: '0px auto',
      height: 16,
    }
  }
});

export default styles;
