import { createStyles, Theme } from "@material-ui/core"

// Theme-dependent styles
const styles = (theme: Theme) => createStyles({
  donateRight: {
    padding: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(4),
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2),
    }
  },
  proceedButtonText: {
    maxWidth: 350,
    marginTop: theme.spacing(4),
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(3),
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(2),
    },
  },
  checkboxWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    "& .custCheckBox": {
      width: 'fit-content',
      marginRight: 0,
    },
    "& .custCheckBoxLbl": {
      "& span": {
        display: "block",
        fontWeight: " 700 !important",
        color: '#4c4c78',
        fontSize: 12,
        [theme.breakpoints.down("sm")]: {
          fontSize: 11.5,
        },
        [theme.breakpoints.down("xs")]: {
          fontSize: 11,
        },
      },
    },
  },
  donationBasketTitle: {
    color: "#4c4c78",
    textTransform: 'uppercase',
    fontWeight: 900,
    textAlign: "center",
    lineHeight: "1.5",
  },
  divider: {
    height: 1,
    backgroundColor: "#ecf1f2",
    width: "100%",
    margin: theme.spacing(2.5, 0),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(2, 0),
    },
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(1.5, 0),
    }
  },
  donateData: {
    flex: 1,
    width: '104%',
    maxHeight: 200,
    overflowY: 'scroll',
    overflowX: 'hidden',
    paddingRight: 20,
    marginRight: '-20px',
    [theme.breakpoints.down("sm")]: {
      maxHeight: 'unset',
      flex: 'unset',
    },
    [theme.breakpoints.down("xs")]: {
      width: '107%',
    }
  },
  totalAmtTxt: {
    fontWeight: 900,
    textAlign: "center",
    color: "#4c4c78",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1.5),
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
    }
  },
  monthlyOptionDisclaimer: {
    fontSize: 12,
    lineHeight: 2,
    color: '#4c4c78',
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      fontSize: 11.5,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 11,
    }
  }
});

export default styles;
