import React from "react";
import PropTypes from "prop-types";
import { RichText } from "prismic-reactjs";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import CustomLink from "../../components/CustomLink";
import TitleSubtitle from "../../components/TitleSubtitle";
import Image from "next/image";

const DonateOtherModes = ({ classes, slice }) => {
  const { primary, items } = slice;
  return (
    <Container maxWidth="lg" className={classes.donateOtherModes}>
      <Grid container>
        <Grid item xs={12}>
          <TitleSubtitle title={RichText.asText(primary.title)} />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={4}
        justify="center"
        alignItems="center"
        className="methodGridWrapper"
      >
        {items.map((item, i) => (
          <Grid item key={i} className="methodGrid">
            <CustomLink link={item.link}>
              <img src={item.icon.url} alt="" />
              <Typography variant="subtitle1" className="pModeTitle">
                {RichText.asText(item.linkText)}
              </Typography>
            </CustomLink>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

DonateOtherModes.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(style)(DonateOtherModes);
