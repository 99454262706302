const styles = (theme) => ({
  intro: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(9),
      marginBottom: theme.spacing(9),
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8),
    },
    "& .label": {
      background:
        "linear-gradient( to left, #2f8dc0 50%, #4a92cf 100% ) left bottom no-repeat",
      backgroundSize: "100% 3px",
      paddingBottom: "2px",
    },
    "&  .description": {
      lineHeight: 2.13,
      color: "#4c4c78",
      textAlign: "center",
      maxWidth: 1023,
      margin: theme.spacing(4, 0),
      fontFamily: "Raleway,Assistant",
      fontWeight: 700,
      [theme.breakpoints.down("sm")]: {
        margin: theme.spacing(3, 0),
      },
      [theme.breakpoints.down("xs")]: {
        margin: theme.spacing(2, 0),
      },
      "& p": {
        lineHeight: 2.13,
        color: "#4c4c78",
        textAlign: "center",
        maxWidth: 1023,
        fontFamily: "Raleway,Assistant",
        fontWeight: 700,
      },
    },
    "& .boldText": {
      color: "#4c4c78",
      lineHeight: 1.75,
      maxWidth: 1023,
      marginBottom: theme.spacing(4),
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        marginBottom: theme.spacing(3),
      },
      [theme.breakpoints.down("xs")]: {
        marginBottom: theme.spacing(2),
      },
    },
  },
  highlightedText: {
    backgroundColor: "#f4f6f8",
    padding: theme.spacing(2.5, 0, 2.5, 0),
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2, 0, 2, 0),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1, 0, 1, 0),
    },
    "& p": {
      padding: theme.spacing(0, 2, 0, 2),
      fontSize: 28,
      fontWeight: 900,
      color: "#4c4c78",
      fontFamily: "Raleway,Assistant",
      textAlign: "center",
      lineHeight: 1.75,
      margin: theme.spacing(0),
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(0, 1, 0, 1),
        fontSize: 24,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 20,
      },
    },
  },
  aboutButton: {
    display: "flex",
    "& .cta": {
      margin: theme.spacing(2),
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      flexDirection: "column;",
      alignItems: "center",
    },
  },
});
export default styles;
