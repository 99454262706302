import { RichText } from "prismic-reactjs"
import React from "react"
import { BraintreeBranch } from "../../../models/braintree/DonationModels"
import regionHelpers from "../../../services/regionHelpers"
import { Checkbox, FormControlLabel } from "@material-ui/core"
import { useLayout } from "../../../utils/layout-context"
import { Address } from "../../../models/braintree/CountryConfig"

interface IExtraImpactProps {
    classes: any
    address: Address
    setCheckbox: (id: string, e: any) => void
    mappedCheckboxes: (checkbox: string) => boolean
}

export const ExtraImpact = ({ classes, address, mappedCheckboxes, setCheckbox }: IExtraImpactProps) => {

    const layout = useLayout().layout

    const branch = regionHelpers.findBranchFromCountryCode(address?.country?.code)

    if (branch !== BraintreeBranch.UK) {
        return <div></div>
    }

    const giftAidCheckbox = layout.extra_impact_checkboxes.find(x => x.extra_impact_id === "gift-aid")

    if (!giftAidCheckbox) {
        return <></>
    }

    return <>
        <div className={classes.modalSectionTitle}>
            {RichText.asText(giftAidCheckbox.checkbox_title)}
        </div>

        <div className={classes.extraImpactWrapper}>
            <FormControlLabel
                value="end"
                checked={mappedCheckboxes(giftAidCheckbox.extra_impact_id)}
                control={
                    <Checkbox
                        color="primary"
                        onChange={(e) => {
                            setCheckbox(giftAidCheckbox.extra_impact_id, e);
                        }}
                    />
                }
                label={
                    <>
                        {RichText.asText(giftAidCheckbox.checkbox_text)}
                    </>
                }
                labelPlacement="end"
            ></FormControlLabel>
        </div>
    </>
}



export default ExtraImpact