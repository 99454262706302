import React from "react";
import style from "./style.js";
import { withStyles, Button, Paper } from "@material-ui/core";
import { RichText } from "prismic-reactjs";
import Link from "next/link";
import clsx from "clsx";

let CustomShareButton = (props) => {
  const { classes } = props;
  return (
    <div
      className={classes.shareIcon}
      style={{
        mask: `url(/share-alt-solid.svg) no-repeat center`,
        WebkitMask: `url(/share-alt-solid.svg) no-repeat center`,
        height: 20,
      }}
    />
  );
};

export default withStyles(style)(CustomShareButton);
