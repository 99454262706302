import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { RichText } from "prismic-reactjs";
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import { structuredTextPropTypes } from "../../utils/prop-types";
import EventsItem from "../../components/EventsItem";
import { useAdditionalData } from "../../utils/additional-data-context";
import TitleSubtitle from "../../components/TitleSubtitle";
import { useLayout } from "../../utils/layout-context";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

const EventList = ({ slice }) => {
  const { primary, items, add } = slice;
  const { layout } = useLayout();
  const additionalData = useAdditionalData();
  const [nextPage, setNextPage] = useState(additionalData.events.next_page);
  const [results, setResults] = useState(additionalData.events.results);
  useEffect(() => {
    fetch(`${nextPage}`)
      .then((response) => response.json())
      .then((data) => {
        setResults(results.concat(data.results));
        setNextPage(data.next_page);
      });
  }, [nextPage]);
  const events =
    results &&
    results
      .filter((event) => event.data.upcoming_or_past == false)
      .map((item) => {
        return (
          item &&
          layout && (
            <EventsItem
              pastOrFuture={item.data.upcoming_or_past}
              item={item}
              moreDetailsLink={item.data.register_button_link}
              imgSrc={item.data.featured_image.url}
              imgAlt={item.data.featured_image.alt}
              eventName={RichText.asText(item.data.title)}
              eventPlace={RichText.asText(item.data.subtitle1)}
              eventDate={RichText.asText(item.data.subtitle2)}
              moreDetails={RichText.asText(
                layout.event_more_details_button_text
              )}
              eventPrice={RichText.asText(item.data.price_text)}
              btnLbl={RichText.asText(item.data.register_button_text)}
            />
          )
        );
      });

  return <Container>{events && <Grid container>{events}</Grid>}</Container>;
};

EventList.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({
      title: structuredTextPropTypes,
      subtitle: structuredTextPropTypes,
    }).isRequired,
    items: PropTypes.array.isRequired,
  }).isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles()(EventList);
