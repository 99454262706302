import { BraintreeBranch } from "../models/braintree/DonationModels"
import getConfig from 'next/config'

const emails = getConfig().publicRuntimeConfig.emails

const countryEmailConfig = [
    {
        code: "US",
        includedCountryCodes: [ "US"], 
        contactEmail: emails.US
     },
     {
        code: "UK",
        includedCountryCodes: [ "GB","GI","JE","NO","ZA" ], 
        contactEmail: emails.UK
     },
     {
        code: "NL",
        includedCountryCodes: [ "NL","BE" ], 
        contactEmail: emails.NL
     },
     {
        code: "DE",
        includedCountryCodes: [  "DE","AD","AT","CY","EE","FI","FR","GR","IE","IT","LV","LT","LU",
        "MT","MC","ME","PL","PT","BL","PM","SM","SK","SI","ES","CH"], 
        contactEmail: emails.DE
     },
     {
        code: "IL",
        includedCountryCodes: [ "IL"], 
        contactEmail: emails.IL
     },
]

const getCountryEmailConfig = (twoLetterIsoCountryCode: string)=> {

    const country = countryEmailConfig.find((countryConfig) => {
       
          if (twoLetterIsoCountryCode === countryConfig.code || countryConfig.includedCountryCodes.includes(twoLetterIsoCountryCode)) {
            return true;
          }
        
      });

      return country ?? countryEmailConfig.find(config => config.code === "US")

}

export default getCountryEmailConfig