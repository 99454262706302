import React from "react";
import PropTypes from "prop-types";
import { RichText } from "prismic-reactjs";
import Container from "@material-ui/core/Container";
import { withStyles } from "@material-ui/core/styles";
import { structuredTextPropTypes } from "../../utils/prop-types";
import style from "./style";
import TitleSubtitle from "../../components/TitleSubtitle";
import { Grid, Typography } from "@material-ui/core";

const ShortHero = ({ slice, classes }) => {
  const { primary } = slice;
  return (
    <Container maxWidth="md" className={classes.shortHero}>
      <Grid
        container
        spacing={2}
        justify="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={11} md={10}>
          <Typography variant="h1">
            {RichText.asText(primary.title)}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={11} md={10}>
          {RichText.render(primary.subtitle)}
        </Grid>
      </Grid>
      {/* <TitleSubtitle
        title={RichText.asText(primary.title)}
        subtitle={RichText.render(primary.subtitle)}
      /> */}
    </Container>
  );
};

ShortHero.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({
      title: structuredTextPropTypes,
      subtitle: structuredTextPropTypes,
    }).isRequired,
    items: PropTypes.array.isRequired,
  }).isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(style)(ShortHero);
