import React, { useState } from "react";
import PropTypes from "prop-types";
import { RichText } from "prismic-reactjs";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CustomLink from "../../components/CustomLink";
import VideoModal from "../../components/VideoModal";
import Image from "next/image";
import style from "./style";
import {
  structuredTextPropTypes,
  imagePropTypes,
} from "../../utils/prop-types";

const AboutHero = ({ slice, classes }) => {
  const { primary } = slice;
  const [openModal, setOpenModal] = useState(false);

  const styles = {};

  const openVideoDialog = () => {
    setOpenModal(true);
  };

  const closeVideoDialog = () => {
    setOpenModal(false);
  };

  return (
    <div className={classes.outer}>
      <Container maxWidth="lg" className={classes.aboutHero}>
        <Typography variant="h1" className={classes.heroTitle}>
          {RichText.asText(primary.title)}
        </Typography>
        <Typography variant="subtitle1" className={classes.heroSubTitle}>
          {RichText.asText(primary.subtitle)}
        </Typography>
        <div className={classes.heroButton}>
          <CustomLink link={primary.primaryCtaButtonLink}>
            <Button variant="contained" color="primary">
              {RichText.asText(primary.primaryCtaButtonText)}
            </Button>
          </CustomLink>
          {!primary.secondaryButtonType ? (
            <CustomLink link={primary.secondaryCtaButtonLink}>
              <Button variant="contained" color="secondary">
                {RichText.asText(primary.secondaryCtaButtonText)}
              </Button>
            </CustomLink>
          ) : (
            <Button
              variant="contained"
              color="secondary"
              onClick={openVideoDialog}
            >
              {RichText.asText(primary.secondaryCtaButtonText)}
            </Button>
          )}
        </div>
      </Container>
      <img src="./aboutUsHero/homehero1.svg" className="homehero1" />
      <img src="./aboutUsHero/homehero2.svg" className="homehero2" />
      <img src={primary.pic1.url} className="homehero3" />
      <img src={primary.pic2.url} className="homehero4" />
      <img src="./aboutUsHero/homehero5.svg" className="homehero5" />
      <img src={primary.pic3.url} className="homehero6" />
      <img src={primary.pic4.url} className="homehero7" />
      <img src="./aboutUsHero/homehero8.svg" className="homehero8" />
      <img src={primary.pic5.url} className="homehero9" />
      <img src={primary.pic6.url} className="homehero10" />
      <div className={classes.aboutHeroOverlay}></div>
      <VideoModal
        open={openModal}
        handleClose={closeVideoDialog}
        video={primary.videoUrl}
      />
    </div>
  );
};

AboutHero.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({
      backgroundImage: imagePropTypes,
      title: structuredTextPropTypes,
      subtitle: structuredTextPropTypes,
      primaryCtaButtonText: structuredTextPropTypes,
      primaryCtaButtonLink: structuredTextPropTypes,
      secondaryCtaButtonText: structuredTextPropTypes,
      secondaryCtaButtonLink: structuredTextPropTypes,
    }).isRequired,
    items: PropTypes.array.isRequired,
  }).isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(style)(AboutHero);
