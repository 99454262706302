import React, { useState } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import {
  structuredTextPropTypes,
  imagePropTypes,
} from "../../utils/prop-types";
import ThumbnailVideo from "../../components/ThumbnailVideo";
import VideoModal from "../../components/VideoModal";
import ButtonBase from "@material-ui/core/ButtonBase";

const BlogPostVideo = ({ classes, slice }) => {
  const { primary } = slice;
  const [openModal, setOpenModal] = useState(false);

  const openVideoDialog = () => {
    setOpenModal(true);
  };

  const closeVideoDialog = () => {
    setOpenModal(false);
  };

  return (
    <div className={classes.blogPostVideo}>
      <Container>
        <Grid container direction="column" justify="center" alignItems="center">
          <div onClick={() => openVideoDialog()} className="thumbnailWrapper">
            <ThumbnailVideo
              thumbnailVideoUrl={primary.thumbnailVideoUrl.url}
              thumbnailVideoAlt={primary.thumbnailVideoUrl.alt}
            />
            <ButtonBase
              className="playBtn"
              onClick={openVideoDialog}
            ></ButtonBase>
          </div>
          <VideoModal
            open={openModal}
            handleClose={closeVideoDialog}
            video={primary.videoId}
          />
        </Grid>
      </Container>
    </div>
  );
};

BlogPostVideo.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({
      section_title: structuredTextPropTypes,
      section_subtitle: structuredTextPropTypes,
      thumbnail: imagePropTypes,
      cta_button_text: structuredTextPropTypes,
    }).isRequired,
    items: PropTypes.array.isRequired,
  }).isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(style)(BlogPostVideo);
