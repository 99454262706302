const styles = (theme) => ({
  description: {
    color: '#ffffff',
    textAlign: 'center',
    lineHeight: 1.42,
    marginBottom: theme.spacing(5),
    maxWidth: 599,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
})
export default styles