import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { RichText } from "prismic-reactjs";
import { Grid, Snackbar } from "@material-ui/core";
import { Checkbox, FormControlLabel } from "@material-ui/core"
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import MuiAlert from "@material-ui/lab/Alert";
import CheckRounded from "@material-ui/icons/CheckRounded"
import WarningOutlined from "@material-ui/icons/WarningOutlined"
import ErrorRounded from "@material-ui/icons/ErrorRounded"
import { structuredTextPropTypes } from "../../utils/prop-types";
import ContactOurBranches from "../../components/ContactOurBranches";
import getCountryEmailConfig from "../../utils/country-email-mapper";
import style from "./style";
import Autocomplete from "@material-ui/lab/Autocomplete";
import allCountries from "../../utils/countries";
import regionHelpers from "../../services/regionHelpers";
import { useLayout } from "../../utils/layout-context";
import filters from '../../utils/autocomplete-filters'
import { useRouter } from 'next/router'
import appConstants from '../../utils/constants/vfi-constants'
import {
  useVFIStoreState,
  useVFIStoreActions,
} from "../../store/vfi-easy-peasy-store";
import CountryConfig from "../../models/braintree/CountryConfig";

const ContactForm = ({ classes, slice }) => {
  const state = useVFIStoreState((state) => state);
  const { primary, items } = slice;



  const locale = useRouter().locale
  const { layout } = useLayout();
  const [ourBranchesItems, setOurBranchesItems] = useState([]);
  const [localBranchesItem, setLocalBranchesItem] = useState<any>();
  const [signUpForEmail, setSignUpForEmail] = useState(layout.contact_auto_select_mailing_list_signup)
  const [message, setMessage] = useState("");

  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryTwoLetterIsoCode, setCountryTwoLetterIsoCode] = useState(
    state?.country?.code
  );
  const [countryPhoneDialingCode, setCountryPhoneDialingCode] = useState(
    state?.country?.dialingCode
  );
  const [dialingCodeCountry, setDialingCodeCountry] = useState(
    state?.country?.code
  );
  const [countryName, setCountryName] = useState(state?.country?.label);

  const [email, setEmail] = useState("");

  const [showSignupFailMessage, setShowSignupFailMessage] = useState<boolean>(false)
  const [emailValid, setEmailValid] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [verifying, setVerifying] = useState(false);

  const setBranches = () => {

    if (state?.country?.code && items) {
      const localBranch =
        items.find((branch) => branch.countryCode === state.country.code) ||
        items[0]; // instead of items[0] should we be calculating the correct branch as we do for donations???
      const otherBranches = items.filter(
        (branch) => {
          if (localBranch.countryCode !== "US") {
            return branch.countryCode !== state.country.code
          } else {
            return branch.countryCode !== state.country.code && branch.countryCode !== "US"
          }
        }
      );

      setLocalBranchesItem(localBranch);
      setOurBranchesItems(otherBranches);
      setCountryTwoLetterIsoCode(state.country.code);
      setCountryPhoneDialingCode(state.country.dialingCode);
      setCountryName(state.country.label);

    } else {
      console.log("could not set branches");
    }
  };

  useEffect(setBranches, [state.country, items]);

  const submitForm = async (e) => {

    setShowSignupFailMessage(false)

    e.preventDefault();

    const country = getCountryEmailConfig(countryTwoLetterIsoCode)

    setVerifying(true);

    if (emailValid) {
      fetch("/api/contact/submit-contact-form", {
        method: "POST",
        body: JSON.stringify({
          sendEmail: country?.contactEmail ?? "info@visionforisrael.com",
          country: countryName,
          f_name: fullName,
          phone_number: "+" + countryPhoneDialingCode + phoneNumber,
          email,
          message,
          signUpForEmail,
          twoLetterLanguageCode: locale
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(async (response) => {

          if (response.ok) {
            setVerifying(false);
            setSuccess(true);
            setError(false);
            setMessage("");
            setCountryPhoneDialingCode("");
            setFullName("");
            setPhoneNumber("");
            setEmail("");
            if (signUpForEmail) {
              var result = await response.json()
              if (!result.signupSuccess) {
                setShowSignupFailMessage(true)
              } else {
                setShowSignupFailMessage(false)
              }
            } else {
              setShowSignupFailMessage(false)
            }
          } else {
            setError(true);
            setSuccess(false)
            setSnackbarOpen(true);
            setShowSignupFailMessage(false)
          }

        })
        .catch((err) => {
          setShowSignupFailMessage(false)
          setVerifying(false);
          setSuccess(false);
          setError(true);
        });
    } else {
      setSnackbarOpen(true);
      setSuccess(false);
      setError(false);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccess(false);
    setSnackbarOpen(false);
    setError(false);
  };
  const emailValidation = (e) => {
    setEmailValid(appConstants.emailRegex.test(e.target.value))
  };
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  return (
    <>
      <Container className={classes.contactForm}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={6}>
            <div className="FormCard">
              <Typography variant="h6" className="formTitle">
                {RichText.asText(layout.contact_form_title)}
              </Typography>
              <form onSubmit={(e) => submitForm(e)}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {countryTwoLetterIsoCode && (
                      <Autocomplete
                        id="country-select-demo"
                        style={{ width: "100%" }}
                        options={allCountries}
                        classes={{
                          option: classes.option,
                          paper: classes.autocompletePaper
                        }}
                        defaultValue={allCountries.find(
                          (ccountry) => ccountry.code == countryTwoLetterIsoCode
                        )}
                        value={allCountries.find(
                          (ccountry) => ccountry.code == countryTwoLetterIsoCode
                        )}
                        autoHighlight
                        getOptionLabel={(option) => option.label}
                        onChange={(e, v: CountryConfig) => {

                          setCountryTwoLetterIsoCode(
                            v != null ? v.code : state.country.code
                          );
                          setDialingCodeCountry(
                            v != null ? v.code : state.country.code
                          );
                          setCountryPhoneDialingCode(
                            v != null ? v.dialingCode : state.country.dialingCode
                          )
                          setCountryName(
                            v != null ? v.label : state.country.label
                          );
                        }}
                        renderOption={(option) => (
                          <div className={classes.codeWrapper}>
                            <span>{regionHelpers.countryToFlag(option.code)} </span>
                            {option.label} ({option.code}) +{option.dialingCode}
                          </div>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder={RichText.asText(
                              layout.your_country_placeholder_contact
                            )}
                            variant="outlined"
                            fullWidth
                            margin="dense"
                            className={classes.custInput}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password", // disable autocomplete and autofill
                            }}
                          />
                        )}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      placeholder={RichText.asText(
                        layout.first_name_placeholder_contact
                      )}
                      variant="outlined"
                      fullWidth
                      required
                      margin="dense"
                      value={fullName}
                      autoComplete="name"
                      className={classes.custInput}
                      onChange={(e) => setFullName(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      autoComplete="email"
                      placeholder={RichText.asText(layout.email_placeholder_contact)}
                      variant="outlined"
                      fullWidth
                      required
                      margin="dense"
                      className={classes.custInput}
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        emailValidation(e);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={5}>
                    {countryTwoLetterIsoCode && (
                      <Autocomplete
                        id="country-select-demo"
                        style={{ width: "100%" }}
                        options={allCountries}
                        classes={{
                          option: classes.option,
                          paper: classes.autocompletePaper
                        }}
                        defaultValue={allCountries.find(
                          (country) => country.code == dialingCodeCountry
                        )}
                        value={allCountries.find(
                          (country) => country.code == dialingCodeCountry
                        )}
                        autoHighlight
                        getOptionLabel={(option) => `+` + option.dialingCode}
                        filterOptions={filters.dialingCodes}
                        onChange={(e, chosenCode: CountryConfig) => {
                          setDialingCodeCountry(
                            chosenCode != null ? chosenCode.code : null
                          );
                          setCountryPhoneDialingCode(
                            chosenCode != null ? chosenCode.dialingCode : null
                          );
                        }}
                        renderOption={(option) => (
                          <div className={classes.codeWrapper}>
                            <span>
                              {regionHelpers.countryToFlag(option.code)}
                            </span>
                            {/* {option.label}  */}
                            ({option.code}) +{option.dialingCode}
                          </div>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            className={classes.custInput}
                            placeholder={RichText.asText(
                              layout.code_placeholder_contact
                            )}
                            variant="outlined"
                            margin="dense"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password", // disable autocomplete and autofill
                            }}
                          />
                        )}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={7}>
                    <TextField
                      autoComplete="tel-local"
                      placeholder={RichText.asText(
                        layout.phone_number_placeholder_contact
                      )}

                      variant="outlined"
                      fullWidth
                      margin="dense"
                      value={phoneNumber}
                      className={classes.custInput}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      placeholder={RichText.asText(layout.message_placeholder)}
                      variant="outlined"
                      className={classes.custTxtArea}
                      fullWidth
                      multiline
                      margin="dense"
                      value={message}
                      rows={12}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      checked={signUpForEmail}
                      value="start"
                      className={classes.donateMailList}
                      control={<Checkbox
                        color="primary"
                        onChange={(e) => {
                          setSignUpForEmail(e.target.checked);
                        }} />}
                      label={
                        <div>{layout.contact_mailing_list_signup_text}</div>
                      }
                      labelPlacement="start"
                    />
                  </Grid>
                  {showSignupFailMessage && <Grid item xs={12}>
                    <div className="mailingListSignupFailure">
                      {layout.contact_mailing_list_signup_failure}
                    </div>

                  </Grid>}
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      fullWidth
                      className="sendBtn"
                    >
                      {RichText.asText(layout.send_button_text)}
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="formtnc">
                      {RichText.render(layout.privacy_text_contact)}
                    </div>
                  </Grid>
                </Grid>
              </form>
            </div>
          </Grid>
          {localBranchesItem && (
            <Grid item xs={12} sm={12} md={6}>
              <div className="branchCard">
                <img
                  src={localBranchesItem.bannerImage.url}
                  alt=""
                  className="bannerImg"
                />
                <div className="branchCardData">
                  <div className="country">
                    <img src={localBranchesItem.flagImage.url} alt="" />
                    <Typography variant="h5" className="countryName">
                      {RichText.asText(localBranchesItem.locationName).toUpperCase()}
                    </Typography>
                  </div>
                  <Typography variant="h6" className="infoTitle">
                    {primary.contactTitle}
                  </Typography>
                  <div className="infoData">
                    {RichText.render(localBranchesItem.contactDetails)}
                  </div>
                  <Typography variant="h6" className="infoTitle">
                    {primary.addressTitle}
                  </Typography>
                  <div className="infoData">
                    {RichText.render(localBranchesItem.addressDetails)}
                  </div>
                </div>
              </div>
            </Grid>
          )}
          <Snackbar open={success} autoHideDuration={6000} onClose={handleClose}>
            <Alert
              icon={<CheckRounded fontSize="inherit" />}
              onClose={handleClose}
              severity="success"
            >
              {RichText.asText(layout.contact_success_message)}
            </Alert>
          </Snackbar>
          <Snackbar
            open={snackbarOpen && !success && !error}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert
              icon={<WarningOutlined fontSize="inherit" />}
              onClose={handleClose}
              severity="warning"
            >
              {RichText.asText(layout.contact_email_error_message)}
            </Alert>
          </Snackbar>
          <Snackbar
            open={error && snackbarOpen && !success}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert
              icon={<ErrorRounded fontSize="inherit" />}
              onClose={handleClose}
              severity="error"
            >
              {RichText.asText(layout.contact_error_message)}
            </Alert>
          </Snackbar>
        </Grid>
      </Container>
      <ContactOurBranches
        items={ourBranchesItems}
        primary={primary}
      ></ContactOurBranches>
    </>
  );
};

ContactForm.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({
      title: structuredTextPropTypes,
      placeholderName: PropTypes.string,
    }).isRequired,
  }).isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(style)(ContactForm);
