import React, { useState } from "react";
import PropTypes from "prop-types";
import { RichText } from "prismic-reactjs";
import { withStyles } from "@material-ui/core/styles";
import LeftTextImageRight from "../../components/LeftTextImageRight";
import style from "./style";
import {
  structuredTextPropTypes,
  imagePropTypes,
} from "../../utils/prop-types";
import VideoModal from "../../components/VideoModal";
const TextWithVideo = ({ slice, classes }) => {
  const { primary } = slice;
  const [openModal, setOpenModal] = useState(false);
  const [videoUrl, setVideoUrl] = useState(false);
  const openVideoDialog = () => {
    setOpenModal(true);
  };

  const closeVideoDialog = () => {
    setOpenModal(false);
  };
  return (
    <div className={classes.textWithVideoBg}>
      <LeftTextImageRight
        preTitle={RichText.asText(primary.preTitle)}
        title={RichText.asText(primary.title)}
        description={RichText.asText(primary.description)}
        btnLabel={RichText.asText(primary.ctaButtonText)}
        btnUrl={primary.ctaButtonLink}
        rightImageSrc={primary.videoThumbnail.url}
        rightbannerImageAlt={primary.videoThumbnail.alt}
        videoLink={primary.vimeoLink}
        openVideoDialog={openVideoDialog}
        rightBgImageVisibility={true}
      />
      {primary.vimeoLink && (
        <VideoModal
          open={openModal}
          handleClose={closeVideoDialog}
          video={primary.vimeoLink}
        />
      )}
    </div>
  );
};

TextWithVideo.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({
      preTitle: structuredTextPropTypes,
      title: structuredTextPropTypes,
      description: structuredTextPropTypes,
      videoThumbnail: imagePropTypes,
    }).isRequired,
    items: PropTypes.array.isRequired,
  }).isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(style)(TextWithVideo);
