const styles = (theme) => ({
  textWithImageBg: {
    backgroundColor: '#f4f5f6',
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(9),
      paddingBottom: theme.spacing(9),
      backgroundPosition: '100%',
      backgroundSize: 'cover',
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
      backgroundPosition: '100%',
      backgroundSize: 'cover',
    },
    '& .element': {
      fontWeight: 900,
      position: 'absolute',
      top: 80,
    },
    '& .preTitle, .title, .subtitle, .description': {
      color: '#4c4c78 !important',
    },
  },
})


export default styles