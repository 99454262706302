import React from "react";
import { RichText } from "prismic-reactjs"
import { DesignationItem } from "../../../models/braintree/DonationModels"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import style from "./style"
import Typography from "@material-ui/core/Typography"
import { useLayout } from "../../../utils/layout-context"
import { useVFIStoreState } from "../../../store/vfi-easy-peasy-store"

export interface BasketItemsProps {
    classes: any
    basketItemsArray: DesignationItem[]
    updateItem: (i: number, item: DesignationItem, amount: number) => void
    deleteItem: (i: number) => void
    amountInput: React.MutableRefObject<any>
    netAmount: number
}

const BasketItems = ({ classes, basketItemsArray, updateItem, deleteItem, amountInput, netAmount }: BasketItemsProps) => {

    const layout = useLayout().layout
    const state = useVFIStoreState(state => state);

    // NO BASKET ITEMS
    if (!basketItemsArray || basketItemsArray.length === 0) {
        return <div className={classes.greyBg}>

            <Typography
                onClick={() => {
                    amountInput.current.focus()
                }}
                variant="h5"
                className={classes.noBasketItemsMessage}
            >
                + {layout.no_basket_items_message}
            </Typography></div>
    }

    // BASKET ITEMS 
    const items = basketItemsArray.map((item, i) => {

        return (<div className={classes.greyBg} key={i}>
            <div onClick={() => deleteItem(i)} className={classes.closeItem}>x</div>
            <div className={classes.yourBasketTitle}>
                <span className={classes.adam}>
                    {netAmount && layout.donation_card_text1 && layout.donation_card_text1.replace(
                        "{{currencySymbol}}", state.currency.symbol)
                        .replace("{{currency}}", state.currency.code)
                        .replace("{{amount}}", item.amount.toFixed(2))
                    }
                </span><br />
                <span>
                    {layout.donation_card_text2 && layout.donation_card_text2
                        .replace("{{dedicateTo}}", item.name)}
                </span>
            </div>
            <a
                onClick={() => {
                    updateItem(i, item, item.amount);
                }}
                className={classes.editAmt}
            >
                {RichText.asText(layout.edit_amount_text)}
            </a>
        </div>
        );
    })

    return <>{items}</>
}

BasketItems.propTypes = {
    classes: PropTypes.objectOf(PropTypes.any).isRequired,
    basketItemsArray: PropTypes.arrayOf(PropTypes.any).isRequired
};

//export default BasketItems

export default withStyles(style)(BasketItems)

