import * as googlePayment from 'braintree-web/google-payment'
import getConfig from 'next/config'
import { Basket } from '../../../models/braintree/DonationModels';
import IPaymentFormInfo from '../../../models/braintree/IPaymentFormInfo';
import Logger from '../../error-logging-service';
import BraintreeConfig from '../shared/braintree-config';
import { GooglePayResult } from './bt-google-pay-models';

declare global { interface Window { google: any } }

const googlePlayEnv = getConfig().publicRuntimeConfig.googlePayEnvironment

if (!googlePlayEnv) {
    Logger.logError({ name: "google_pay_setup_error", message: "no google play env set" })
}

const GooglePayService = {

    makePayment: async (authorizationToken: string, paymentInfo: IPaymentFormInfo): Promise<GooglePayResult> => {

        try {

            const paymentsClient = new window.google.payments.api.PaymentsClient({ environment: googlePlayEnv });

            if (!paymentsClient) {
                Logger.logMessage("Could not get google payments client")
                return {success:false};
            }

            const googlePaymentInstance = await googlePayment.create({
                authorization: authorizationToken,
                googlePayVersion: 2,
                // Optional in sandbox; if set in sandbox, this value must be a valid production Google Merchant ID
                //googleMerchantId: 'merchant-id-from-google' 
            })

            const allowedPaymentMethods = googlePaymentInstance.createPaymentDataRequest().allowedPaymentMethods

            if (!allowedPaymentMethods || allowedPaymentMethods.length === 0) {
                return { success: false, error: { message: "no payment methods added", name: "no_payment_methods" } }
            }
            const paymentsClientResponse = await paymentsClient.isReadyToPay({
                // see https://developers.google.com/pay/api/web/reference/object#IsReadyToPayRequest
                apiVersion: 2,
                apiVersionMinor: 0,
                allowedPaymentMethods: allowedPaymentMethods,
                existingPaymentMethodRequired: true  // Optional
            })

            if (!paymentsClientResponse?.result) {
                return { success: false, error: { name: "google_pay_error", message: "Is ready to pay returned an error" } }
            }

            const paymentDataRequest = googlePaymentInstance.createPaymentDataRequest({
                transactionInfo: {
                    currencyCode: paymentInfo.currencyCode,
                    totalPriceStatus: 'FINAL',
                    totalPrice: BraintreeConfig.getTotalAmountString(paymentInfo.netAmount, paymentInfo.coverTransactionCosts)
                }
            });

            // See all available options at https://developers.google.com/pay/api/web/reference/object
            const cardPaymentMethod = paymentDataRequest.allowedPaymentMethods[0];
            cardPaymentMethod.parameters.billingAddressRequired = true;
            cardPaymentMethod.parameters.billingAddressParameters = {
                format: 'FULL',
                phoneNumberRequired: true
            };

            const paymentData = await paymentsClient.loadPaymentData(paymentDataRequest)

            const finalAuthResult = await googlePaymentInstance.parseResponse(paymentData)

            if (!finalAuthResult?.nonce) {
                return { success: false, error: { name: "google_pay_error", message: "could_not_get_nonce" } }
            }

            return { success: true, nonce: finalAuthResult.nonce }
        }
        catch (error) {
            Logger.logError(error, { doingWhat: "google_payment", paymentInfo })
            return { success: false, error: error }
        }


    }

}


export default GooglePayService