const styles = (theme) => ({
  background: {
    backgroundPosition: "center",
    backgroundSize: "cover",
    position: 'relative',
    "& .container ": {
      height: "100%",
      position: "relative",
    },
  },
  backgroundOverlay: {
    backgroundColor: "rgba(0,0,0,0.2)",
    height: 560,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(20, 2),
      height: "100%",
    },
    "& .blogPostTagWrapper": {
      display: "flex",
      [theme.breakpoints.down("xs")]: {
        display: "block",
      },
    },
    "& .blogPostTag,.blogPostTitle, .boldPostDate": {
      color: "#fff",
      textTransform: "uppercase",
      padding: theme.spacing(1.5, 0),
    },
    "& .boldPostDate": {
      fontWeight: 900,
    },
    "& .blogPostTag": {
      borderRadius: 6,
      backgroundColor: "#4a92cf",
      width: "fit-content",
      padding: theme.spacing(0.5, 1),
      marginRight: theme.spacing(1),
      cursor: "pointer",
      fontWeight: 900,
      [theme.breakpoints.down("xs")]: {
        marginRight: theme.spacing(0),
        marginTop: theme.spacing(1),
      },
    },
    "& .shareButton": {
      position: "relative",
      bottom: "-32px",
    },
  },
  heroButton: {
    display: "flex",
    marginTop: theme.spacing(4),
    "& button": {
      margin: theme.spacing(2),
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      flexDirection: "column;",
      alignItems: "center",
    },
  },
});

export default styles;
