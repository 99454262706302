import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import style from "./style";
import { useLayout } from "../../utils/layout-context";
import CustomLink from "../CustomLink";
import { RichText } from "prismic-reactjs";
import Image from "next/image";

const EventsItem = ({
  classes,
  imgSrc,
  imgAlt,
  eventName,
  eventPlace,
  eventDate,
  eventPrice,
  btnLbl,
  item,
  moreDetailsLink,
  pastOrFuture,
}) => {
  const { layout } = useLayout();

  const buttonText = !pastOrFuture
    ? btnLbl
    : RichText.asText(layout.past_event_watch_button_text)



  return (
    <Grid items xs={12}>
      {item && layout && (
        <CustomLink link={item}>
          <div className={classes.eventsItem}>
            <img src={imgSrc} alt={imgAlt} />

            <div item className="eventInfo">
              <Typography variant="h5" className="eventName">
                {eventName}
              </Typography>
              <Typography variant="h6" className="eventPlace">
                {eventPlace}
              </Typography>
              <Typography variant="h6" className="eventDate">
                {eventDate}
              </Typography>
              <Typography variant="h6" className="moreDetails">
                {RichText.asText(layout.event_more_details_button_text)}
              </Typography>
            </div>
            <div item className="eventRegister">
              {!pastOrFuture && (
                <Typography variant="h3" className="eventPrice">
                  {eventPrice}
                </Typography>
              )}
              {moreDetailsLink && buttonText && (
                <CustomLink link={!pastOrFuture ? moreDetailsLink : item}>
                  <Button variant="contained" color="primary">
                    {buttonText}
                  </Button>
                </CustomLink>
              )}
            </div>
          </div>
        </CustomLink>
      )}
    </Grid>
  );
};

EventsItem.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  imgSrc: PropTypes.objectOf(PropTypes.string).isRequired,
  imgAlt: PropTypes.objectOf(PropTypes.string).isRequired,
  eventName: PropTypes.objectOf(PropTypes.string).isRequired,
  eventPlace: PropTypes.objectOf(PropTypes.string).isRequired,
  eventDate: PropTypes.objectOf(PropTypes.string).isRequired,
  moreDetails: PropTypes.objectOf(PropTypes.string).isRequired,
  eventPrice: PropTypes.objectOf(PropTypes.string).isRequired,
  btnLbl: PropTypes.objectOf(PropTypes.string).isRequired,
};
export default withStyles(style)(EventsItem);