import React from "react";
import PropTypes from "prop-types";
import { RichText, Date } from "prismic-reactjs";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { useAdditionalData } from "../../utils/additional-data-context";
import style from "./style";
import CustomShare from "../../components/share-component";
import CustomLink from "../../components/CustomLink";
import { useRouter } from 'next/router'

const dateOptions = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
};
const BlogPostHero = ({ classes, slice }) => {
  const { primary } = slice;

  const additionalData = useAdditionalData();

  const styles = {};
  if (additionalData.blog_post.data.featured_image) {
    styles.backgroundImage = `url(${additionalData.blog_post.data.featured_image.url})`;
  }

  return (
    <div className={classes.background} style={styles}>
      <div className={classes.backgroundOverlay}>
        <Container maxWidth="lg" className="container">
          <Grid
            container
            direction="column"
            justify="center"
            className="container"
          >
            <div className="blogPostTagWrapper">
              {additionalData.blog_post.data &&
                additionalData.blog_post.data.blog_topics &&
                additionalData.blog_post.data.blog_topics.length > 0 &&
                additionalData.blog_post.data.blog_topics.map((category) => (
                  <CustomLink link={category.blog_topic}>
                    {category.blog_topic.data &&
                      category.blog_topic.data.topic_name && (
                        <Typography variant="subtitle1" className="blogPostTag">
                          {RichText.asText(category.blog_topic.data.topic_name)}
                        </Typography>
                      )}
                  </CustomLink>
                ))}
              {additionalData.blog_post.data &&
                additionalData.blog_post.data.project_categories &&
                additionalData.blog_post.data.project_categories.length > 0 &&
                additionalData.blog_post.data.project_categories.map(
                  (category) => (
                    <CustomLink link={category.project_category}>
                      {category.project_category.data && (
                        <Typography variant="subtitle1" className="blogPostTag">
                          {RichText.asText(
                            category.project_category.data.title
                          )}
                        </Typography>
                      )}
                    </CustomLink>
                  )
                )}
            </div>

            <Typography variant="h3" className="blogPostTitle">
              {RichText.asText(additionalData.blog_post.data.title)}
            </Typography>
            <Typography variant="subtitle2" className="boldPostDate">
              {Date(additionalData.blog_post.data.date).toLocaleDateString(
                useRouter().locale,
                dateOptions
              )}
            </Typography>
          </Grid>
        </Container>
        <Container maxWidth="lg" className="container" className="shareButton">
          <div>
            <CustomShare></CustomShare>
          </div>
        </Container>
      </div>
    </div>
  );
};

BlogPostHero.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({
    }).isRequired,
    items: PropTypes.array.isRequired,
  }).isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(style)(BlogPostHero);
