import React from "react";
import PropTypes from "prop-types";
import { RichText } from "prismic-reactjs";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import {
  structuredTextPropTypes,
  imagePropTypes,
} from "../../utils/prop-types";
import TitleSubtitle from "../../components/TitleSubtitle";

const ContactOurBranch = ({ slice, classes }) => {
  const { primary, items } = slice;


  return (
    <Container className={classes.contactOurBranch}>
      <TitleSubtitle
        title={RichText.asText(primary.title)}
        subtitle={RichText.asText(primary.subTitle)}
      />
      <Grid container spacing={4} className="branchWrapper">
        {items.map((item, index) => (
          <Grid key={index} item xs={12} sm={12} md={6}>
            <div className="branchCard">
              <img
                src={item.bannerImage.url}
                alt=""
                className="bannerImg"
              />
              <div className="branchCardData">
                <div className="country">
                  <img src={item.flagImage.url} alt="" />
                  <Typography variant="h4" className="countryName">
                    {RichText.asText(item.locationName)}
                  </Typography>
                </div>
                <Typography variant="subtitle1" className="infoTitle">
                  {RichText.asText(primary.contactTitle)}
                </Typography>
                <Typography variant="subtitle3" className="infoData">
                  {RichText.asText(item.contactDetails)}
                </Typography>
                <Typography variant="subtitle1" className="infoTitle">
                  {RichText.asText(primary.addressTitle)}
                </Typography>
                <Typography variant="subtitle3" className="infoData">
                  {RichText.asText(item.addressDetails)}
                </Typography>
              </div>
            </div>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

ContactOurBranch.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({
      title: structuredTextPropTypes,
      subtitle: structuredTextPropTypes,
    }).isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        flag: imagePropTypes,
        location_name: PropTypes.string,
        contact_info: PropTypes.string,
      })
    ).isRequired,
  }).isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(style)(ContactOurBranch);
