import React from 'react'
import PropTypes from 'prop-types'
import { RichText } from 'prismic-reactjs'
import { withStyles } from '@material-ui/core/styles'
import style from './style'
import { structuredTextPropTypes } from '../../utils/prop-types'
import TextCta from '../../components/TextCta'
import CustomLink from '../../components/CustomLink'
const EventDetailsCta = ({ slice, classes }) => {
  const { primary } = slice
  return (
    <div className={classes.eventDetailsCta}>
      <TextCta
        description={RichText.render(primary.ctaText)}
        ctaButtonText={RichText.asText(primary.ctaButtonText)}
        ctaButtonLink={primary.ctaButtonLink}
      />
    </div>
  )
}

EventDetailsCta.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({
      cta_text: structuredTextPropTypes,
      cta_button_text: structuredTextPropTypes,
    }).isRequired,
    items: PropTypes.array.isRequired,
  }).isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default withStyles(style)(EventDetailsCta)