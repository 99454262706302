const styles = (theme) => ({
  donateOtherModes: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(9),
      paddingBottom: theme.spacing(9),
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
    "& .subtitle": {
      display: 'none',
    },
    "& .methodGridWrapper": {
      display: "flex",
      paddingTop: theme.spacing(10),
      [theme.breakpoints.down("sm")]: {
        paddingTop: theme.spacing(9),
      },
      [theme.breakpoints.down("xs")]: {
        paddingTop: theme.spacing(8),
      },
    },
    "& .methodGrid": {
      textAlign: "center",
      flex: 1,
      transition: 'transform .5s',
      "&:hover": {
        transform: 'scale(1.1)',
      },
      [theme.breakpoints.down("768")]: {
        flex: "unset",
      },
    },
    "& .pModeHeadtitle": {
      color: "#4c4c78",
      textAlign: "center",

      marginBottom: theme.spacing(13),
      [theme.breakpoints.down("xs")]: {
        marginBottom: theme.spacing(7.5),
      },
    },
    "& .pModeTitle": {
      color: "#4c4c78",
      textAlign: "center",
      fontWeight: 700,
      marginTop: theme.spacing(2.5),
      [theme.breakpoints.down("sm")]: {
        marginTop: theme.spacing(2),
      },
      [theme.breakpoints.down("xs")]: {
        marginTop: theme.spacing(1),
      },
    },
  },
});
export default styles;
