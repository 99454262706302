const styles = (theme) => ({
  featuredMember: {
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.down("sm")]: {
      paddingBottom: theme.spacing(9),
    },
    [theme.breakpoints.down("xs")]: {
      paddingBottom: theme.spacing(8),
    },
    "& .viewAll": {
      margin: "0 auto",
    },
  },
});

export default styles;
