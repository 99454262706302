import { createStyles, Theme } from "@material-ui/core";
import { secondary } from "../../../theme";

// Theme-dependent styles
const styles = (theme: Theme) => createStyles({
  donateLeft: {
    padding: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(4),
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2),
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: '#8bc249',
    },
    "& fieldset": {
      border: '2px solid #f3f3f3',
      "&:hover": {
        border: '2px solid #8bc249',
      }
    },
  },
  givePageDonationCardTitle: {
    fontWeight: 900,
    letterSpacing: 1.24,
    textAlign: 'center',
    color: '#4c4c78',
  },
  divider: {
    height: 1,
    backgroundColor: "#ecf1f2",
    width: "100%",
    margin: theme.spacing(2.5, 0),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(2, 0),
    },
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(1.5, 0),
    }
  },
  editOrSaveButtonText: {
    maxWidth: 350,
    marginTop: theme.spacing(4),
    margin: "0 auto",
    display: 'block',
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(3),
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(2),
    },
  },
  selectAmountText: {
    fontSize: 16,
    fontWeight: 700,
    color: "#4c4c78",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      fontSize: 15,
      marginBottom: theme.spacing(1.5),
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
      marginBottom: theme.spacing(1),
    },
  },
  amtGroup: {
    marginBottom: theme.spacing(5),
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(4),
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(3),
    },
    "& button:first-child": {
      borderTopRightRadius: theme.direction === "ltr" ? 0 : 6,
      borderBottomRightRadius: theme.direction === "ltr" ? 0 : 6,
      borderTopLeftRadius: theme.direction === "ltr" ? 6 : 0,
      borderBottomLeftRadius: theme.direction === "ltr" ? 6 : 0,
    },
    "& button:last-child": {
      borderTopLeftRadius: theme.direction === "ltr" ? 0 : 6,
      borderBottomLeftRadius: theme.direction === "ltr" ? 0 : 6,
      borderTopRightRadius: theme.direction === "ltr" ? 6 : 0,
      borderBottomRightRadius: theme.direction === "ltr" ? 6 : 0,
    },
    "& .MuiButton-outlinedPrimary": {
      color: "#4c4c78",
      border: "solid 1px #4c4c78",
      flex: 1,
      fontSize: 18,
      fontWeight: 700,
      height: "fit-content",
      padding: theme.spacing(1.3, 0),
      "&:hover": {
        backgroundColor: secondary[500],
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 17,
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: 16,
      },
    },
  },
  dedicateDonationPlaceholder: {
    fontSize: 16,
    fontWeight: 700,
    color: "#4c4c78",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      fontSize: 15,
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(1.5),
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1),
    },
  },
  selectMenuItem:{
    direction:theme.direction === "ltr" ? "ltr" : "rtl",
  }
});

export default styles;
