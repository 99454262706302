import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import style from "./style";
import CustomLink from "../CustomLink";
import ButtonBase from "@material-ui/core/ButtonBase";
import clsx from "clsx";

const LeftTextImageRight = ({
  classes,
  preTitle,
  title,
  description,
  btnLabel,
  rightImageSrc,
  rightbannerImageAlt,
  btnUrl,
  videoLink,
  openVideoDialog,
  rightBgImageVisibility
}) => {

  return (
    <Container maxWidth="lg" className={classes.leftTextImageRight}>
      <Grid
        container
        spacing={3}
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid item xs={12} md={6}>
          <Typography variant="subtitle2" className="preTitle">
            {preTitle}
          </Typography>
          <Typography variant="h1" component="h2" className="title">
            {title}
          </Typography>
          <Typography variant="subtitle1" className="description">
            {description}
          </Typography>
          {btnUrl && btnLabel && (
            <CustomLink className={""} link={btnUrl} key={btnLabel}>
              <Button variant="contained" color="primary">
                {btnLabel}
              </Button>
            </CustomLink>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          className="rightImageWrapper"
          container
          direction="row"
          justify="center"
        >
          {rightBgImageVisibility &&
            <div className="rightImageOverlay" />
          }
          <img
            src={rightImageSrc}
            className={clsx("rightImage", {
              [classes.rightBgImageVisibility]: !rightBgImageVisibility 
            })}
            alt={rightbannerImageAlt}
          />
          {videoLink && (
            <ButtonBase
              onClick={() => openVideoDialog()}
              className="playBtn"
            ></ButtonBase>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

LeftTextImageRight.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  preTitle: PropTypes.objectOf(PropTypes.string).isRequired,
  title: PropTypes.objectOf(PropTypes.string).isRequired,
  description: PropTypes.objectOf(PropTypes.string).isRequired,
  btnLabel: PropTypes.objectOf(PropTypes.string).isRequired,
  rightImageSrc: PropTypes.objectOf(PropTypes.string).isRequired,
  rightbannerImageAlt: PropTypes.objectOf(PropTypes.string).isRequired,
  rightBgImageVisibility: PropTypes.objectOf(PropTypes.bool).isRequired,
};

export default withStyles(style)(LeftTextImageRight);
