import { IRecaptchaResponse } from "../models/IRecaptchaResponse";
import getConfig from 'next/config'
import Logger from "./error-logging-service";
const reCAPTCHA_site_key = getConfig().publicRuntimeConfig.recaptchaSiteKey
// rather than having to go through the whole project
const SecurityService = {

    // returns a recaptcha token
    // the token should be verified on our server using validateRecaptchaToken
    getRecaptchaToken: async (action: string) => {

        if (!reCAPTCHA_site_key){
            Logger.logError({message: "missing recaptcha token",name:"missing_recaptcha_token"},null)
            return ""
        }

        if (!window.grecaptcha){
            Logger.logError({message: "missing recaptcha object on the window",name:"missing_recaptcha_object"},null)
            return ""
        }

        return new Promise<string>((res, rej) => {
            window.grecaptcha.ready(function () {

                window.grecaptcha.execute(reCAPTCHA_site_key, { action: action }).then((token) => {
                    return res(token);
                })
            })

        }).catch(err => {
            Logger.logError(err, { recaptchaAction: action })
            return "";
        })

    },
    validateRecaptchaTokenWithGoogle: async (token: string): Promise<{ status: number, recaptchaResponse: IRecaptchaResponse }> => {

        const secretKey = getConfig().serverRuntimeConfig.recaptchaSecretKey

        if (!secretKey) {
            Logger.logMessage("tcould not find secret recaptcha key when validating on the server", null)
            return {
                status: 400, recaptchaResponse: {
                    success: false,
                    score: 0,
                    message: "key not provided",
                    action: "submit"
                } as IRecaptchaResponse
            }
        }

        if (!token) {
            Logger.logMessage("Could not find recaptcha token when validating on the server", null)
            return { status: 400, recaptchaResponse: { success: false, score: 0, message: "token not provided", action: "submit" } as IRecaptchaResponse }
        }

        const verificationResponse = await fetch(`https://www.google.com/recaptcha/api/siteverify?secret=${secretKey}&response=${token}`, { method: "POST" })
        const json = await verificationResponse.json() as IRecaptchaResponse
        return { status: verificationResponse.status, recaptchaResponse: json }

    }

}

export default SecurityService