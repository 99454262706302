import IPaymentFormInfo, { IPaymentUIEvents } from "../../../models/braintree/IPaymentFormInfo"
import { ApplePayResult } from "./bt-apple-pay-models"
import * as applePay from 'braintree-web/apple-pay'
import BraintreeConfig from "../shared/braintree-config"
import Logger from "../../error-logging-service"
import { PaymentMethod, BraintreeBranch } from "../../../models/braintree/DonationModels"
import { MakePaymentGenericFunction } from '../shared/bt-models-shared';

const ApplePayService = {

    deviceSupportsApplePay: () : boolean=> {
        return window.ApplePaySession && window.ApplePaySession.supportsVersion(3) && window.ApplePaySession.canMakePayments()
    },
    makePayment: async (
        transactionLogId: string,
        branch: BraintreeBranch,
        authorizationToken: string,
        paymentInfo: IPaymentFormInfo,
        uiEvents: IPaymentUIEvents,
        makePaymentGeneric: MakePaymentGenericFunction, companyNameLabel: string): Promise<ApplePayResult> => {

        try {

            Logger.logTrace("Apple pay started")

            const applePayInstance = await applePay.create({ authorization: authorizationToken })

            Logger.logTrace("Apple pay instance created",{applePayInstance})

            const paymentRequest = applePayInstance.createPaymentRequest({
                total: {
                    label: companyNameLabel || 'Vision for Israel',
                    amount: BraintreeConfig.getTotalAmountString(paymentInfo.netAmount, paymentInfo.coverTransactionCosts)
                }, requiredBillingContactFields: ["postalAddress"]
            })

            Logger.logTrace("Apple pay payment request created",{paymentRequest})

            paymentRequest.currencyCode = paymentInfo.currencyCode

            Logger.logTrace("Apple pay currency code updated",{currency:  paymentRequest.currencyCode })

            const session = new window.ApplePaySession(3, paymentRequest);

            Logger.logTrace("Apple pay session created",{session})

            const noncePromise = new Promise<ApplePayResult>((resolve, reject) => {

                session.onvalidatemerchant = async (event) => {

                    try {

                        Logger.logTrace("Apple pay on validate merchant",{event})

                        const merchantSession = await applePayInstance.performValidation({
                            validationURL: event.validationURL,
                            displayName: 'VFI'
                        });

                        Logger.logTrace("Apple pay merchant session created",{merchantSession})

                        session.completeMerchantValidation(merchantSession);

                        Logger.logTrace("Apple pay completed merchant validation",{merchantSession})

                        resolve({success: true})
                    }
                    catch (validationErr) {
                        // You should show an error to the user, e.g. 'Apple Pay failed to load.'
                        Logger.logError(validationErr, { doingWhat: "validating apple pay merchant" });
                        session.abort();
                        reject({ success: false,error:validationErr })
                    }
                }

                session.onpaymentauthorized = async (event) => {

                    try {
                        Logger.logTrace("Apple pay was authorized ok",{event})

                        const payload = await applePayInstance.tokenize({ token: event.payment.token })

                        Logger.logTrace("Apple pay was tokenized ok",{event})

                        // Send payload.nonce to your server.
                        await makePaymentGeneric(transactionLogId,PaymentMethod.APPLE, branch, 
                            payload.nonce, 
                            paymentInfo, 
                            uiEvents, 
                            null, 
                            authorizationToken)

                            Logger.logTrace("Apple pay payment was made",{event})

                        // call `completePayment` to dismiss the Apple Pay sheet.
                        session.completePayment(window.ApplePaySession.STATUS_SUCCESS);

                        Logger.logTrace("Apple pay payment was completed",{event})

                        resolve({success: true})

                    }
                    catch (tokenizeErr) {
                        Logger.logError(tokenizeErr, { doingWhat: 'Error tokenizing Apple Pay:' });
                        session.completePayment(window.ApplePaySession.STATUS_FAILURE);
                        reject("error tokenizing apple pay")
                    }
                 
                };

                session.oncancel = async (event)=> {
                    try{
                        Logger.logTrace("Apple pay was cancelled by user",{event})
                        resolve({success: true})
                    }
                    catch (error){
                        Logger.logError(error, { doingWhat: "error when cancelling apple pay, aborting session" });
                        session.abort();

                        reject("error cancelling apple pay")
                    }
                }

                Logger.logTrace("Apple pay about to begin session")
                session.begin();
                Logger.logTrace("Apple pay session began")
            })

            return noncePromise
        }
        catch (error) {
            Logger.logError(error)
            return { success: false,error }
        }

    }
}

export default ApplePayService