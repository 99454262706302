import { BraintreeBranch } from "../models/braintree/DonationModels"

export const getRegionCode = (branch: BraintreeBranch,name: string) => {

    console.log("getting region codes for branch:",branch)
    if (!branch || !name){
        console.log("no branch or no state",branch,name)
        return null
    }
    const localRegions = allRegions[branch]
    if (localRegions){

        for (let i = 0; i <localRegions.length; i++){
            const regionConfig = localRegions[i]

            if (regionConfig.Code.toLowerCase() == name.toLowerCase() || regionConfig.Name.toLowerCase() === name.toLowerCase()){
                console.log("found region code",regionConfig)
                return regionConfig.Code
            }
        }
        console.log("went through them all, found nadda")
        return null
    }
    console.log("no local regions")
    return null
}

const allRegions = {
    UK: [
      {
        Code: "ABE",
        Name: "Aberdeen City"
      },
      {
        Code: "ABD",
        Name: "Aberdeenshire"
      },
      {
        Code: "ANS",
        Name: "Angus"
      },
      {
        Code: "ANN",
        Name: "Antrim and Newtownabbey"
      },
      {
        Code: "AND",
        Name: "Ards and North Down"
      },
      {
        Code: "AGB",
        Name: "Argyll and Bute"
      },
      {
        Code: "ABC",
        Name: "Armagh City, Banbridge and Craigavon"
      },
      {
        Code: "BDG",
        Name: "Barking and Dagenham"
      },
      {
        Code: "BNE",
        Name: "Barnet"
      },
      {
        Code: "BNS",
        Name: "Barnsley"
      },
      {
        Code: "BAS",
        Name: "Bath and North East Somerset"
      },
      {
        Code: "BDF",
        Name: "Bedford"
      },
      {
        Code: "BFS",
        Name: "Belfast City"
      },
      {
        Code: "BEX",
        Name: "Bexley"
      },
      {
        Code: "BIR",
        Name: "Birmingham"
      },
      {
        Code: "BBD",
        Name: "Blackburn with Darwen"
      },
      {
        Code: "BPL",
        Name: "Blackpool"
      },
      {
        Code: "BGW",
        Name: "Blaenau Gwent"
      },
      {
        Code: "BOL",
        Name: "Bolton"
      },
      {
        Code: "BCP",
        Name: "Bournemouth, Christchurch and Poole"
      },
      {
        Code: "BRC",
        Name: "Bracknell Forest"
      },
      {
        Code: "BRD",
        Name: "Bradford"
      },
      {
        Code: "BEN",
        Name: "Brent"
      },
      {
        Code: "BGE",
        Name: "Bridgend [Pen-y-bont ar Ogwr GB-POG]"
      },
      {
        Code: "BNH",
        Name: "Brighton and Hove"
      },
      {
        Code: "BST",
        Name: "Bristol, City of"
      },
      {
        Code: "BRY",
        Name: "Bromley"
      },
      {
        Code: "BKM",
        Name: "Buckinghamshire"
      },
      {
        Code: "BUR",
        Name: "Bury"
      },
      {
        Code: "CAY",
        Name: "Caerphilly [Caerffili GB-CAF]"
      },
      {
        Code: "CLD",
        Name: "Calderdale"
      },
      {
        Code: "CAM",
        Name: "Cambridgeshire"
      },
      {
        Code: "CMD",
        Name: "Camden"
      },
      {
        Code: "CRF",
        Name: "Cardiff [Caerdydd GB-CRD]"
      },
      {
        Code: "CMN",
        Name: "Carmarthenshire [Sir Gaerfyrddin GB-GFY]"
      },
      {
        Code: "CCG",
        Name: "Causeway Coast and Glens"
      },
      {
        Code: "CBF",
        Name: "Central Bedfordshire"
      },
      {
        Code: "CGN",
        Name: "Ceredigion [Sir Ceredigion]"
      },
      {
        Code: "CHE",
        Name: "Cheshire East"
      },
      {
        Code: "CHW",
        Name: "Cheshire West and Chester"
      },
      {
        Code: "CLK",
        Name: "Clackmannanshire"
      },
      {
        Code: "CWY",
        Name: "Conwy"
      },
      {
        Code: "CON",
        Name: "Cornwall"
      },
      {
        Code: "COV",
        Name: "Coventry"
      },
      {
        Code: "CRY",
        Name: "Croydon"
      },
      {
        Code: "CMA",
        Name: "Cumbria"
      },
      {
        Code: "DAL",
        Name: "Darlington"
      },
      {
        Code: "DEN",
        Name: "Denbighshire [Sir Ddinbych GB-DDB]"
      },
      {
        Code: "DER",
        Name: "Derby"
      },
      {
        Code: "DBY",
        Name: "Derbyshire"
      },
      {
        Code: "DRS",
        Name: "Derry and Strabane"
      },
      {
        Code: "DEV",
        Name: "Devon"
      },
      {
        Code: "DNC",
        Name: "Doncaster"
      },
      {
        Code: "DOR",
        Name: "Dorset"
      },
      {
        Code: "DUD",
        Name: "Dudley"
      },
      {
        Code: "DGY",
        Name: "Dumfries and Galloway"
      },
      {
        Code: "DND",
        Name: "Dundee City"
      },
      {
        Code: "DUR",
        Name: "Durham, County"
      },
      {
        Code: "EAL",
        Name: "Ealing"
      },
      {
        Code: "EAY",
        Name: "East Ayrshire"
      },
      {
        Code: "EDU",
        Name: "East Dunbartonshire"
      },
      {
        Code: "ELN",
        Name: "East Lothian"
      },
      {
        Code: "ERW",
        Name: "East Renfrewshire"
      },
      {
        Code: "ERY",
        Name: "East Riding of Yorkshire"
      },
      {
        Code: "ESX",
        Name: "East Sussex"
      },
      {
        Code: "EDH",
        Name: "Edinburgh, City of"
      },
      {
        Code: "ELS",
        Name: "Eilean Siar"
      },
      {
        Code: "ENF",
        Name: "Enfield"
      },
      {
        Code: "ESS",
        Name: "Essex"
      },
      {
        Code: "FAL",
        Name: "Falkirk"
      },
      {
        Code: "FMO",
        Name: "Fermanagh and Omagh"
      },
      {
        Code: "FIF",
        Name: "Fife"
      },
      {
        Code: "FLN",
        Name: "Flintshire [Sir y Fflint GB-FFL]"
      },
      {
        Code: "GAT",
        Name: "Gateshead"
      },
      {
        Code: "GLG",
        Name: "Glasgow City"
      },
      {
        Code: "GLS",
        Name: "Gloucestershire"
      },
      {
        Code: "GRE",
        Name: "Greenwich"
      },
      {
        Code: "GWN",
        Name: "Gwynedd"
      },
      {
        Code: "HCK",
        Name: "Hackney"
      },
      {
        Code: "HAL",
        Name: "Halton"
      },
      {
        Code: "HMF",
        Name: "Hammersmith and Fulham"
      },
      {
        Code: "HAM",
        Name: "Hampshire"
      },
      {
        Code: "HRY",
        Name: "Haringey"
      },
      {
        Code: "HRW",
        Name: "Harrow"
      },
      {
        Code: "HPL",
        Name: "Hartlepool"
      },
      {
        Code: "HAV",
        Name: "Havering"
      },
      {
        Code: "HEF",
        Name: "Herefordshire"
      },
      {
        Code: "HRT",
        Name: "Hertfordshire"
      },
      {
        Code: "HLD",
        Name: "Highland"
      },
      {
        Code: "HIL",
        Name: "Hillingdon"
      },
      {
        Code: "HNS",
        Name: "Hounslow"
      },
      {
        Code: "IVC",
        Name: "Inverclyde"
      },
      {
        Code: "AGY",
        Name: "Isle of Anglesey [Sir Ynys Môn GB-YNM]"
      },
      {
        Code: "IOW",
        Name: "Isle of Wight"
      },
      {
        Code: "IOS",
        Name: "Isles of Scilly"
      },
      {
        Code: "ISL",
        Name: "Islington"
      },
      {
        Code: "KEC",
        Name: "Kensington and Chelsea"
      },
      {
        Code: "KEN",
        Name: "Kent"
      },
      {
        Code: "KHL",
        Name: "Kingston upon Hull"
      },
      {
        Code: "KTT",
        Name: "Kingston upon Thames"
      },
      {
        Code: "KIR",
        Name: "Kirklees"
      },
      {
        Code: "KWL",
        Name: "Knowsley"
      },
      {
        Code: "LBH",
        Name: "Lambeth"
      },
      {
        Code: "LAN",
        Name: "Lancashire"
      },
      {
        Code: "LDS",
        Name: "Leeds"
      },
      {
        Code: "LCE",
        Name: "Leicester"
      },
      {
        Code: "LEC",
        Name: "Leicestershire"
      },
      {
        Code: "LEW",
        Name: "Lewisham"
      },
      {
        Code: "LIN",
        Name: "Lincolnshire"
      },
      {
        Code: "LBC",
        Name: "Lisburn and Castlereagh"
      },
      {
        Code: "LIV",
        Name: "Liverpool"
      },
      {
        Code: "LND",
        Name: "London, City of"
      },
      {
        Code: "LUT",
        Name: "Luton"
      },
      {
        Code: "MAN",
        Name: "Manchester"
      },
      {
        Code: "MDW",
        Name: "Medway"
      },
      {
        Code: "MTY",
        Name: "Merthyr Tydfil [Merthyr Tudful GB-MTU]"
      },
      {
        Code: "MRT",
        Name: "Merton"
      },
      {
        Code: "MEA",
        Name: "Mid and East Antrim"
      },
      {
        Code: "MDB",
        Name: "Middlesbrough"
      },
      {
        Code: "MLN",
        Name: "Midlothian"
      },
      {
        Code: "MUL",
        Name: "Mid-Ulster"
      },
      {
        Code: "MIK",
        Name: "Milton Keynes"
      },
      {
        Code: "MON",
        Name: "Monmouthshire [Sir Fynwy GB-FYN]"
      },
      {
        Code: "MRY",
        Name: "Moray"
      },
      {
        Code: "NTL",
        Name: "Neath Port Talbot [Castell-nedd Port Talbot GB-CTL]"
      },
      {
        Code: "NET",
        Name: "Newcastle upon Tyne"
      },
      {
        Code: "NWM",
        Name: "Newham"
      },
      {
        Code: "NWP",
        Name: "Newport [Casnewydd GB-CNW]"
      },
      {
        Code: "NMD",
        Name: "Newry, Mourne and Down"
      },
      {
        Code: "NFK",
        Name: "Norfolk"
      },
      {
        Code: "NAY",
        Name: "North Ayrshire"
      },
      {
        Code: "NEL",
        Name: "North East Lincolnshire"
      },
      {
        Code: "NLK",
        Name: "North Lanarkshire"
      },
      {
        Code: "NLN",
        Name: "North Lincolnshire"
      },
      {
        Code: "NSM",
        Name: "North Somerset"
      },
      {
        Code: "NTY",
        Name: "North Tyneside"
      },
      {
        Code: "NYK",
        Name: "North Yorkshire"
      },
      {
        Code: "NTH",
        Name: "Northamptonshire"
      },
      {
        Code: "NBL",
        Name: "Northumberland"
      },
      {
        Code: "NGM",
        Name: "Nottingham"
      },
      {
        Code: "NTT",
        Name: "Nottinghamshire"
      },
      {
        Code: "OLD",
        Name: "Oldham"
      },
      {
        Code: "ORK",
        Name: "Orkney Islands"
      },
      {
        Code: "OXF",
        Name: "Oxfordshire"
      },
      {
        Code: "PEM",
        Name: "Pembrokeshire [Sir Benfro GB-BNF]"
      },
      {
        Code: "PKN",
        Name: "Perth and Kinross"
      },
      {
        Code: "PTE",
        Name: "Peterborough"
      },
      {
        Code: "PLY",
        Name: "Plymouth"
      },
      {
        Code: "POR",
        Name: "Portsmouth"
      },
      {
        Code: "POW",
        Name: "Powys"
      },
      {
        Code: "RDG",
        Name: "Reading"
      },
      {
        Code: "RDB",
        Name: "Redbridge"
      },
      {
        Code: "RCC",
        Name: "Redcar and Cleveland"
      },
      {
        Code: "RFW",
        Name: "Renfrewshire"
      },
      {
        Code: "RCT",
        Name: "Rhondda Cynon Taff [Rhondda CynonTaf]"
      },
      {
        Code: "RIC",
        Name: "Richmond upon Thames"
      },
      {
        Code: "RCH",
        Name: "Rochdale"
      },
      {
        Code: "ROT",
        Name: "Rotherham"
      },
      {
        Code: "RUT",
        Name: "Rutland"
      },
      {
        Code: "SLF",
        Name: "Salford"
      },
      {
        Code: "SAW",
        Name: "Sandwell"
      },
      {
        Code: "SCB",
        Name: "Scottish Borders"
      },
      {
        Code: "SFT",
        Name: "Sefton"
      },
      {
        Code: "SHF",
        Name: "Sheffield"
      },
      {
        Code: "ZET",
        Name: "Shetland Islands"
      },
      {
        Code: "SHR",
        Name: "Shropshire"
      },
      {
        Code: "SLG",
        Name: "Slough"
      },
      {
        Code: "SOL",
        Name: "Solihull"
      },
      {
        Code: "SOM",
        Name: "Somerset"
      },
      {
        Code: "SAY",
        Name: "South Ayrshire"
      },
      {
        Code: "SGC",
        Name: "South Gloucestershire"
      },
      {
        Code: "SLK",
        Name: "South Lanarkshire"
      },
      {
        Code: "STY",
        Name: "South Tyneside"
      },
      {
        Code: "STH",
        Name: "Southampton"
      },
      {
        Code: "SOS",
        Name: "Southend-on-Sea"
      },
      {
        Code: "SWK",
        Name: "Southwark"
      },
      {
        Code: "SHN",
        Name: "St. Helens"
      },
      {
        Code: "STS",
        Name: "Staffordshire"
      },
      {
        Code: "STG",
        Name: "Stirling"
      },
      {
        Code: "SKP",
        Name: "Stockport"
      },
      {
        Code: "STT",
        Name: "Stockton-on-Tees"
      },
      {
        Code: "STE",
        Name: "Stoke-on-Trent"
      },
      {
        Code: "SFK",
        Name: "Suffolk"
      },
      {
        Code: "SND",
        Name: "Sunderland"
      },
      {
        Code: "SRY",
        Name: "Surrey"
      },
      {
        Code: "STN",
        Name: "Sutton"
      },
      {
        Code: "SWA",
        Name: "Swansea [Abertawe GB-ATA]"
      },
      {
        Code: "SWD",
        Name: "Swindon"
      },
      {
        Code: "TAM",
        Name: "Tameside"
      },
      {
        Code: "TFW",
        Name: "Telford and Wrekin"
      },
      {
        Code: "THR",
        Name: "Thurrock"
      },
      {
        Code: "TOB",
        Name: "Torbay"
      },
      {
        Code: "TOF",
        Name: "Torfaen [Tor-faen]"
      },
      {
        Code: "TWH",
        Name: "Tower Hamlets"
      },
      {
        Code: "TRF",
        Name: "Trafford"
      },
      {
        Code: "VGL",
        Name: "Vale of Glamorgan, The [Bro Morgannwg GB-BMG]"
      },
      {
        Code: "WKF",
        Name: "Wakefield"
      },
      {
        Code: "WLL",
        Name: "Walsall"
      },
      {
        Code: "WFT",
        Name: "Waltham Forest"
      },
      {
        Code: "WND",
        Name: "Wandsworth"
      },
      {
        Code: "WRT",
        Name: "Warrington"
      },
      {
        Code: "WAR",
        Name: "Warwickshire"
      },
      {
        Code: "WBK",
        Name: "West Berkshire"
      },
      {
        Code: "WDU",
        Name: "West Dunbartonshire"
      },
      {
        Code: "WLN",
        Name: "West Lothian"
      },
      {
        Code: "WSX",
        Name: "West Sussex"
      },
      {
        Code: "WSM",
        Name: "Westminster"
      },
      {
        Code: "WGN",
        Name: "Wigan"
      },
      {
        Code: "WIL",
        Name: "Wiltshire"
      },
      {
        Code: "WNM",
        Name: "Windsor and Maidenhead"
      },
      {
        Code: "WRL",
        Name: "Wirral"
      },
      {
        Code: "WOK",
        Name: "Wokingham"
      },
      {
        Code: "WLV",
        Name: "Wolverhampton"
      },
      {
        Code: "WOR",
        Name: "Worcestershire"
      },
      {
        Code: "WRX",
        Name: "Wrexham [Wrecsam GB-WRC]"
      },
      {
        Code: "YOR",
        Name: "York"
      }
    ],
    NL: [
      {
        Code: "DR",
        Name: "Drenthe"
      },
      {
        Code: "FL",
        Name: "Flevoland"
      },
      {
        Code: "FR",
        Name: "Fryslân (fy)"
      },
      {
        Code: "GE",
        Name: "Gelderland"
      },
      {
        Code: "GR",
        Name: "Groningen"
      },
      {
        Code: "LI",
        Name: "Limburg"
      },
      {
        Code: "NB",
        Name: "Noord-Brabant"
      },
      {
        Code: "NH",
        Name: "Noord-Holland"
      },
      {
        Code: "OV",
        Name: "Overijssel"
      },
      {
        Code: "UT",
        Name: "Utrecht"
      },
      {
        Code: "ZE",
        Name: "Zeeland"
      },
      {
        Code: "ZH",
        Name: "Zuid-Holland"
      }
    ],
    US: [
      {
        Code: "AL",
        Name: "Alabama"
      },
      {
        Code: "AK",
        Name: "Alaska"
      },
      {
        Code: "AZ",
        Name: "Arizona"
      },
      {
        Code: "AR",
        Name: "Arkansas"
      },
      {
        Code: "CA",
        Name: "California"
      },
      {
        Code: "CO",
        Name: "Colorado"
      },
      {
        Code: "CT",
        Name: "Connecticut"
      },
      {
        Code: "DE",
        Name: "Delaware"
      },
      {
        Code: "FL",
        Name: "Florida"
      },
      {
        Code: "GA",
        Name: "Georgia"
      },
      {
        Code: "HI",
        Name: "Hawaii"
      },
      {
        Code: "ID",
        Name: "Idaho"
      },
      {
        Code: "IL",
        Name: "Illinois"
      },
      {
        Code: "IN",
        Name: "Indiana"
      },
      {
        Code: "IA",
        Name: "Iowa"
      },
      {
        Code: "KS",
        Name: "Kansas"
      },
      {
        Code: "KY",
        Name: "Kentucky"
      },
      {
        Code: "LA",
        Name: "Louisiana"
      },
      {
        Code: "ME",
        Name: "Maine"
      },
      {
        Code: "MD",
        Name: "Maryland"
      },
      {
        Code: "MA",
        Name: "Massachusetts"
      },
      {
        Code: "MI",
        Name: "Michigan"
      },
      {
        Code: "MN",
        Name: "Minnesota"
      },
      {
        Code: "MS",
        Name: "Mississippi"
      },
      {
        Code: "MO",
        Name: "Missouri"
      },
      {
        Code: "MT",
        Name: "Montana"
      },
      {
        Code: "NE",
        Name: "Nebraska"
      },
      {
        Code: "NV",
        Name: "Nevada"
      },
      {
        Code: "NH",
        Name: "New Hampshire"
      },
      {
        Code: "NJ",
        Name: "New Jersey"
      },
      {
        Code: "NM",
        Name: "New Mexico"
      },
      {
        Code: "NY",
        Name: "New York"
      },
      {
        Code: "NC",
        Name: "North Carolina"
      },
      {
        Code: "ND",
        Name: "North Dakota"
      },
      {
        Code: "OH",
        Name: "Ohio"
      },
      {
        Code: "OK",
        Name: "Oklahoma"
      },
      {
        Code: "OR",
        Name: "Oregon"
      },
      {
        Code: "PA",
        Name: "Pennsylvania"
      },
      {
        Code: "RI",
        Name: "Rhode Island"
      },
      {
        Code: "SC",
        Name: "South Carolina"
      },
      {
        Code: "SD",
        Name: "South Dakota"
      },
      {
        Code: "TN",
        Name: "Tennessee"
      },
      {
        Code: "TX",
        Name: "Texas"
      },
      {
        Code: "UT",
        Name: "Utah"
      },
      {
        Code: "VT",
        Name: "Vermont"
      },
      {
        Code: "VA",
        Name: "Virginia"
      },
      {
        Code: "WA",
        Name: "Washington"
      },
      {
        Code: "WV",
        Name: "West Virginia"
      },
      {
        Code: "WI",
        Name: "Wisconsin"
      },
      {
        Code: "WY",
        Name: "Wyoming"
      },
      {
        Code: "DC",
        Name: "District of Columbia"
      },
      {
        Code: "AS",
        Name: "American Samoa"
      },
      {
        Code: "GU",
        Name: "Guam"
      },
      {
        Code: "MP",
        Name: "Northern Mariana Islands"
      },
      {
        Code: "PR",
        Name: "Puerto Rico"
      },
      {
        Code: "UM",
        Name: "United States Minor Outlying Islands"
      },
      {
        Code: "VI",
        Name: "U.S. Virgin Islands"
      }
    ],
    DE: [
      {
        Code: "BW",
        Name: "Baden-Württemberg"
      },
      {
        Code: "BY",
        Name: "Bavaria"
      },
      {
        Code: "BE",
        Name: "Berlin"
      },
      {
        Code: "BB",
        Name: "Brandenburg"
      },
      {
        Code: "HB",
        Name: "Bremen"
      },
      {
        Code: "HH",
        Name: "Hamburg"
      },
      {
        Code: "HE",
        Name: "Hesse"
      },
      {
        Code: "MV",
        Name: "Mecklenburg-Western Pomerania"
      },
      {
        Code: "NI",
        Name: "Lower Saxony"
      },
      {
        Code: "NW",
        Name: "North Rhine-Westphalia"
      },
      {
        Code: "RP",
        Name: "Rhineland-Palatinate"
      },
      {
        Code: "SL",
        Name: "Saarland"
      },
      {
        Code: "SN",
        Name: "Saxony"
      },
      {
        Code: "ST",
        Name: "Saxony-Anhalt"
      },
      {
        Code: "SH",
        Name: "Schleswig-Holstein"
      },
      {
        Code: "TH",
        Name: "Thuringia"
      }
    ]
  } 


  export default allRegions