import { createStyles, Theme } from "@material-ui/core"
import { secondary } from "../../../theme"

// Theme-dependent styles
const styles = (theme: Theme) => createStyles({
    greyBg: {
        borderRadius: 6,
        backgroundColor: "#ecf1f2",
        padding: theme.spacing(4),
        marginTop: 8,
        marginBottom: theme.spacing(2.5),
        display: "flex",
        flexDirection: "column",
        width: "100%",
        position: 'relative',
        transition: '0.3s',
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(3),
        },
        [theme.breakpoints.down("xs")]: {
            padding: theme.spacing(2),
        },
    },
    noBasketItemsMessage: {
        color: '#4c4c78',
        textAlign: 'center',
        fontWeight: 700,
        cursor: 'pointer',
    },
    closeItem: {
        cursor: 'pointer',
        position: 'absolute',
        width: 20,
        height: 20,
        backgroundColor: '#fff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '4px',
        top: '-8px',
        right: '-8px',
        boxShadow: '0 8px 18px 0 rgba(121, 125, 176, 0.28)',
        fontSize: 16,
        fontWeight: 700,
        color: '#4c4c78',
    },
    yourBasketTitle: {
        color: "#4c4c78",
        lineHeight: "1.71",
        fontFamily: "Raleway,Assistant",
        textAlign: "center",
        fontSize: 14,
        [theme.breakpoints.down("sm")]: {
            fontSize: 13,
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: 13,
        },
    },
    editAmt: {
        textAlign: "center",
        fontSize: 14,
        color: "#4c4c78",
        textDecoration: 'underline',
        fontWeight: 700,
        marginTop: theme.spacing(1.5),
        cursor: 'pointer',
        [theme.breakpoints.down("sm")]: {
            fontSize: 13,
            marginTop: theme.spacing(1),
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: 12,
            marginTop: theme.spacing(0.5),
        },
    }
});

export default styles;
