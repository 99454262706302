const styles = (theme) => ({
  eventListPastEvent: {
    backgroundColor: '#242440',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    marginTop: theme.spacing(10),
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(9),
      paddingTop: theme.spacing(9),
      paddingBottom: theme.spacing(9),
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(8),
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
    '& .pastEventBtnWrapper': {
      marginTop: theme.spacing(5),
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(4),
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(2),
      },
    },
  },
})

export default styles