import { createStyles } from '@material-ui/core/styles';

const style = (theme) =>
  createStyles({
    workItem: {
      boxShadow: '0 22px 57px 0 rgba(79, 89, 138, 0.16)',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      '& img': {
        width: '100%',
        height: 273,
        objectFit: 'cover',
        verticalAlign: 'bottom',
      },
      '& a': {
        textDecoration: 'none',
      },
      '& .featureWorkItemData': {
        padding: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(4),
        },
        [theme.breakpoints.down('xs')]: {
          padding: theme.spacing(2),
          height: 'unset',
        },
        '& .featuredInfo': {
          flex: 1,
          '& .featuredProjectTitle': {
            fontFamily: 'Raleway,Assistant',
            fontSize: 21,
            color: '#4c4c78',
            marginBottom: theme.spacing(1),
            fontWeight: 700,
            [theme.breakpoints.down('sm')]: {
              fontSize: 20,
            },
            [theme.breakpoints.down('xs')]: {
              fontSize: 19,
            },
          },
          '& .projectCategoryTitle': {
            fontFamily: 'Raleway,Assistant',
            fontSize: 18,
            color: '#4c4c78',
            [theme.breakpoints.down('sm')]: {
              fontSize: 17,
            },
            [theme.breakpoints.down('xs')]: {
              fontSize: 16,
            },
          },
        },
      },

      '& .button': {
        marginTop: theme.spacing(2),
      },
    },
  });

export default style;
