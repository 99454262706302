// This file is generated by Slice Machine. Update with care!

import AboutHero from './AboutHero';
import AboutIntro from './AboutIntro';
import AboutQuote from './AboutQuote';
import AllBlogs from './AllBlogs';
import AllEndorsements from './AllEndorsements';
import AllEvents from './AllEvents';
import AllMembers from './AllMembers';
import AllPastEvents from './AllPastEvents';
import BigImage from './BigImage';
import BlogPostHero from './BlogPostHero';
import BlogPostRichText from './BlogPostRichText';
import BlogPostVideo from './BlogPostVideo';
import BlogsCategory from './BlogsCategory';
import BlogTestimonial from './BlogTestimonial';
import CallToAction from './CallToAction';
import CompletePayment from './CompletePayment';
import ContactForm from './ContactForm';
import ContactOurBranches from './ContactOurBranches';
import DonateNow from './DonateNow';
import DonateOtherModes from './DonateOtherModes';
import DonatePayNow from './DonatePayNow';
import DonationThankyou from './DonationThankyou';
import EndorsementsComponent from './EndorsementsComponent';
import EventDetailAbpout from './EventDetailAbpout';
import EventDetailsCta from './EventDetailsCta';
import EventDetailsHero from './EventDetailsHero';
import EventDetailsVideo from './EventDetailsVideo';
import EventsDetailPastEvents from './EventsDetailPastEvents';
import EventVideos from './EventVideos';
import FeaturedBlogs from './FeaturedBlogs';
import FeaturedBlogsInProject from './FeaturedBlogsInProject';
import FeaturedMembers from './FeaturedMembers';
import FeaturedWork from './FeaturedWork';
import HeroBackgroundImage from './HeroBackgroundImage';
import HomepageHero from './HomepageHero';
import ImageGallery from './ImageGallery';
import ImapctStatement from './ImapctStatement';
import OurPartners from './OurPartners';
import PastEvent from './PastEvent';
import ProjectGallery from './ProjectGallery';
import QuoteTestimonials from './QuoteTestimonials';
import ShortHero from './ShortHero';
import SubscribeEbook from './SubscribeEbook';
import TestimonialBackgroundImage from './TestimonialBackgroundImage';
import TextBlueBackground from './TextBlueBackground';
import TextWithImage from './TextWithImage';
import TextWithVideo from './TextWithVideo';
import VideoDetails from './VideoDetails';
import WhoWeAre from './WhoWeAre';

export {
	AboutHero,
	AboutIntro,
	AboutQuote,
	AllBlogs,
	AllEndorsements,
	AllEvents,
	AllMembers,
	AllPastEvents,
	BigImage,
	BlogPostHero,
	BlogPostRichText,
	BlogPostVideo,
	BlogsCategory,
	BlogTestimonial,
	CallToAction,
	CompletePayment,
	ContactForm,
	ContactOurBranches,
	DonateNow,
	DonateOtherModes,
	DonatePayNow,
	DonationThankyou,
	EndorsementsComponent,
	EventDetailAbpout,
	EventDetailsCta,
	EventDetailsHero,
	EventDetailsVideo,
	EventsDetailPastEvents,
	EventVideos,
	FeaturedBlogs,
	FeaturedBlogsInProject,
	FeaturedMembers,
	FeaturedWork,
	HeroBackgroundImage,
	HomepageHero,
	ImageGallery,
	ImapctStatement,
	OurPartners,
	PastEvent,
	ProjectGallery,
	QuoteTestimonials,
	ShortHero,
	SubscribeEbook,
	TestimonialBackgroundImage,
	TextBlueBackground,
	TextWithImage,
	TextWithVideo,
	VideoDetails,
	WhoWeAre,
};

export const components = {
	about_hero: AboutHero,
	about_intro: AboutIntro,
	about_quote: AboutQuote,
	all_blogs: AllBlogs,
	all_endorsements: AllEndorsements,
	all_events: AllEvents,
	all_members: AllMembers,
	all_past_events: AllPastEvents,
	big_image: BigImage,
	blog_post_hero: BlogPostHero,
	blog_post_rich_text: BlogPostRichText,
	blog_post_video: BlogPostVideo,
	blogs_category: BlogsCategory,
	blog_testimonial: BlogTestimonial,
	call_to_action: CallToAction,
	complete_payment: CompletePayment,
	contact_form: ContactForm,
	contact_our_branches: ContactOurBranches,
	donate_now: DonateNow,
	donate_other_modes: DonateOtherModes,
	donate_pay_now: DonatePayNow,
	donation_thankyou: DonationThankyou,
	endorsements_component: EndorsementsComponent,
	event_detail_abpout: EventDetailAbpout,
	event_details_cta: EventDetailsCta,
	event_details_hero: EventDetailsHero,
	event_details_video: EventDetailsVideo,
	events_detail_past_events: EventsDetailPastEvents,
	event_videos: EventVideos,
	featured_blogs: FeaturedBlogs,
	featured_blogs_in_project: FeaturedBlogsInProject,
	featured_members: FeaturedMembers,
	featured_work: FeaturedWork,
	hero_background_image: HeroBackgroundImage,
	homepage_hero: HomepageHero,
	image_gallery: ImageGallery,
	imapct_statement: ImapctStatement,
	our_partners: OurPartners,
	past_event: PastEvent,
	project_gallery: ProjectGallery,
	quote_testimonials: QuoteTestimonials,
	short_hero: ShortHero,
	subscribe_ebook: SubscribeEbook,
	testimonial_background_image: TestimonialBackgroundImage,
	text_blue_background: TextBlueBackground,
	text_with_image: TextWithImage,
	text_with_video: TextWithVideo,
	video_details: VideoDetails,
	who_we_are: WhoWeAre,
};
