import React from "react";
import PropTypes from "prop-types";
import { RichText } from "prismic-reactjs";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import style from "./style";
import { structuredTextPropTypes } from "../../utils/prop-types";
import TitleSubtitle from "../../components/TitleSubtitle";
import EventsItem from "../../components/EventsItem";
import { useLayout } from "../../utils/layout-context";
import CustomLink from "../../components/CustomLink";
const EventDetailsPastEvents = ({ slice, classes }) => {
  const { primary, items } = slice;
  const { layout } = useLayout();
  return (
    <Container maxWidth="lg" className={classes.eventDetailsPastEvents}>
      <TitleSubtitle
        title={RichText.asText(primary.title)}
        subtitle={RichText.asText(primary.subtitle)}
      />
      <Grid
        container
        justify="center"
        alignItems="center"
        className="eventsItemWrapper"
      >
        {items.map((item) => {
          return (
            item.pastEvent.data && (
              <EventsItem
                pastOrFuture={item.pastEvent.data.upcoming_or_past}
                item={item.pastEvent}
                moreDetailsLink={item.pastEvent.data.register_button_link}
                imgSrc={item.pastEvent.data.featured_image.url}
                imgAlt=""
                eventName={RichText.asText(item.pastEvent.data.title)}
                eventPlace={RichText.asText(item.pastEvent.data.subtitle1)}
                eventDate={RichText.asText(item.pastEvent.data.subtitle2)}
                moreDetails={RichText.asText(
                  item.pastEvent.data.register_button_text
                )}
                eventPrice={RichText.asText(item.pastEvent.data.price_text)}
                btnLbl={RichText.asText(layout.past_event_watch_button_text)}
              />
            )
          );
        })}
      </Grid>
      <Grid container justify="center" alignItems="center">
        <CustomLink link={primary.viewAllButtonLink}>
          <Button variant="outlined" color="primary">
            {RichText.asText(primary.viewAllButtonText)}
          </Button>
        </CustomLink>
      </Grid>
    </Container>
  );
};

EventDetailsPastEvents.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({
      section_title: structuredTextPropTypes,
      section_subtitle: structuredTextPropTypes,
    }).isRequired,
    items: PropTypes.array.isRequired,
  }).isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(style)(EventDetailsPastEvents);
