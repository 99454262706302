const styles = (theme) => ({
  blogPostVideo: {
    clipPath: 'polygon(0 10%, 100% 0, 100% 100%, 0% 100%)',
    position: 'relative',
    backgroundColor: '#8BC249',
    backgroundImage: `url('/greenCirclePattner.png')`,
    backgroundPosition: 'bottom',
    backgroundSize: '128% 70%',
    backgroundRepeat: 'no-repeat',
    paddingTop: theme.spacing(20),
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.down('sm')]: {
      clipPath: 'polygon(0 15%, 100% 0, 100% 100%, 0% 100%)',
      paddingTop: theme.spacing(18),
      paddingBottom: theme.spacing(9),
      backgroundSize: '200% 70%',
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(16),
      paddingBottom: theme.spacing(8),
    },
    "& .thumbnailWrapper": {
      cursor:'pointer',
      "& .playBtn": {
        height: 69,
        width: 105,
        backgroundImage: `url('/playBtnBg.png')`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        position: 'absolute',
        borderRadius:8,
        boxShadow: "0px 2px 6px 0px #1f1f1f",
        top: '55%',
        left: '50%',
        right: '50%',
        cursor:'pointer',
        transform: 'translateY(-50%) translateX(-50%)',
        [theme.breakpoints.down("844")]: {
          top: '60%',
        },
        [theme.breakpoints.down("600")]: {
          top: '60%',
          height: 35,
        width: 55,
        borderRadius:8,
        },
        [theme.breakpoints.down("374")]: {
          top: '63%',
        },
      },
    },
    '&::after': {
      clipPath: 'polygon(0 15%, 100% 0, 100% 90%, 0% 100%)',
      background:
        'linear-gradient(90deg,rgba(140,194,75,1) 10%, rgba(105, 144, 57,1) 60%,  rgba(74,146,207,1) 30%)',
      content: '""',
      position: 'absolute',
      left: 0,
      top: '-89.5%',
      right: 0,
      height: '100%',
      display: 'block',
      zIndex: 9,
      [theme.breakpoints.down('sm')]: {
        top: '-84.5%',
        clipPath: 'polygon(0% 0%, 100% 10%, 100% 84.8%, 0% 100%);',
      },
    },
  },
})
export default styles