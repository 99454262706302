import React from "react";
import PropTypes from "prop-types";
import { RichText } from "prismic-reactjs";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import style from "./style";
import { structuredTextPropTypes } from "../../utils/prop-types";
import PersonItem from "../../components/PersonItem";
import TitleSubtitle from "../../components/TitleSubtitle";
import { useLayout } from "../../utils/layout-context";
import { Element } from "react-scroll";
import { useAdditionalData } from "../../utils/additional-data-context";

const AllTeam = ({ slice, classes }) => {
  const { primary, items } = slice;
  const { layout } = useLayout();
  const additionalData = useAdditionalData();

  return (
    <Container maxWidth="lg" className={classes.featuredMember}>
      <Grid
        container
        spacing={4}
        className="boardMemberItem"
        direction="row"
        justify="center"
        alignItems="center"
      >
        {additionalData.allTeam.results.map((item) => {
   
          return (
            <Grid item xs={12} sm={6} md={4}>
              <PersonItem
                bannerImageSrc={item.data.portrait_image.url}
                bannerImageAlt=""
                name={RichText.asText(item.data.name)}
                title={RichText.asText(item.data.title)}
                btnLabel={RichText.asText(layout.full_bio_button_text)}
                showTag={false}
                showBtn
                link={item}
              />
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
};

AllTeam.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({
      title: structuredTextPropTypes,
    }).isRequired,
    items: PropTypes.array,
  }).isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(style)(AllTeam);
