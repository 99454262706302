import React from "react";
import PropTypes from "prop-types";
import { RichText } from "prismic-reactjs";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import { imagePropTypes } from "../../utils/prop-types";

const BlogPostTestimonials = ({ slice, classes }) => {
  const { primary } = slice;

  // leave  this for now i am not sure where it being used just in case something goes wrong i can resuse
  
  // const styles = {};
  // if (primary.backgroundImage) {
  //   styles.backgroundImage = `url(${primary.backgroundImage.url})`;
  //   styles.backgroundSize = "cover";
  // }
  return (
    <>

      <Container maxWidth="lg" className={classes.blogPostTestimonials}>
        <Grid container alignItems="center" justify="center">
          <Grid item xs={12} sm={10} md={8}>
            {/* <div className={classes.workDetailsTestimonials} style={styles}> */}
            <div className={classes.workDetailsTestimonials}>
              {/* <div className="overlay"> */}
              <Typography variant="h4" className="testimonialText">
                {RichText.asText(primary.testimonialText)}
              </Typography>
              <Typography variant="h4" className="author">
                {RichText.asText(primary.author)}
              </Typography>
              {/* </div> */}
            </div>
          </Grid>
        </Grid>
      </Container>

    </>
  );
};

BlogPostTestimonials.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({}).isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        background_image: imagePropTypes,
        testimonial_text: PropTypes.string,
        author: PropTypes.string,
      })
    ).isRequired,
  }).isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(style)(BlogPostTestimonials);
