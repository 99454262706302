import React, { useState } from "react";
import style from "./style";
import Typography from "@material-ui/core/Typography";
import { Button, Dialog } from "@material-ui/core";
import { withStyles } from "@material-ui/core";
import { useLayout } from "../../../utils/layout-context";

const MobilePopupWarningPopup = ({
  classes,
  openPopup,
  handleClosePopup,
  handleContinue,
}) => {
  const layout = useLayout().layout;

  return (
    <Dialog open={openPopup} onBackdropClick={handleClosePopup}>
      <div className={classes.modal}>
        <Typography variant="h5">
          {layout.enable_mobile_popups_title}
        </Typography>
        <br />
        <Button
          onClick={handleContinue}
          variant="contained"
          color="primary"
          fullWidth
        >
          {layout.enable_mobile_popups_continue}
        </Button>
        <br />
        <Button
          onClick={handleClosePopup}
          variant="contained"
          color="secondary"
        >
          {layout.enable_mobile_popups_cancel}
        </Button>
      </div>
    </Dialog>
  );
};

export default withStyles(style)(MobilePopupWarningPopup);
