import React from "react";
import PropTypes from "prop-types";
import { RichText } from "prismic-reactjs";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import CustomLink from "../../components/CustomLink";
import style from "./style";
import { structuredTextPropTypes } from '../../utils/prop-types'

const EventDetailsAbout = ({ classes, slice }) => {
  const { primary } = slice;
  return (
    <Container maxWidth="lg">
      <Grid container>
        <Grid item xs={12}>
          <div className={classes.eventDetailsAbout}>
            {RichText.render(primary.description)}
            {primary.buttonLink?.url  &&
              <CustomLink link={primary.buttonLink}>
                <Button
                  variant="contained"
                  color="primary"
                  className="getTickets"
                >
                  {RichText.asText(primary.buttonText)}
                </Button>
              </CustomLink>
            }
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

EventDetailsAbout.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({
      title: structuredTextPropTypes,
      subtitle: structuredTextPropTypes,
      cta_button_text: structuredTextPropTypes,
    }).isRequired,
    items: PropTypes.array.isRequired,
  }).isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(style)(EventDetailsAbout);
