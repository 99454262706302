import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";

const SimpleDialog = ({ handleClose, open, onRendered, classes, children }) => {
  return (
    <Dialog
      onRendered={onRendered}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      className={classes.modal}
    >
      {children}
    </Dialog>
  );
};

SimpleDialog.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  children: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles()(SimpleDialog);
