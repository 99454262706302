import { createStyles, Theme } from "@material-ui/core";
import { secondary } from "../../theme";

// Theme-dependent styles
const styles = (theme: Theme) => createStyles({
  donateCard: {
    borderRadius: 12,
    boxShadow: "0 7px 28px 0 rgba(184, 196, 214, 0.5)",
    "&>.MuiGrid-item:first-child": {
      borderRight: '1px solid #ecf1f2',
      [theme.breakpoints.down("sm")]: {
        borderRight: '0'
      }
    },

  },

});

export default styles;
