import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import style from "./style";
import CustomLink from "../CustomLink";
const TextCta = ({ classes, description, ctaButtonText, ctaButtonLink }) => {
  return (
    <Container maxWidth="lg" className="container">
      <Grid container direction="column" justify="center" alignItems="center">
        <Typography variant="h4" className={classes.description}>
          {description}
        </Typography>
        <CustomLink link={ctaButtonLink}>
          <Button variant="outlined">{ctaButtonText}</Button>
        </CustomLink>
      </Grid>
    </Container>
  );
};

TextCta.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  description: PropTypes.objectOf(PropTypes.string).isRequired,
  ctaButtonText: PropTypes.objectOf(PropTypes.string).isRequired,
};
export default withStyles(style)(TextCta);
