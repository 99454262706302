import React from "react";
import PropTypes from "prop-types";
import { RichText } from "prismic-reactjs";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import style from "./style";
import Image from "next/image";
import {
  structuredTextPropTypes,
  imagePropTypes,
} from "../../utils/prop-types";

const AboutQuote = ({ slice, classes }) => {
  const { primary } = slice;

  return (
    <div className={classes.aboutQuoteBg}>
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid item xs={12} md={6} container direction="row" justify="center">
            <div className="rightImageOverlay" />
            <img
              src={primary.leftImage.url}
              className="rightImage"
              alt="quotes"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" className="quoteText">
              <img
                src="/quote-left-font-awesome.png"
                alt="quotes"
                className="quoteImg"
              />
              {RichText.asText(primary.quoteText)}
              <img
                src="/quote-right-font-awesome.png"
                className="quoteRight quoteImg"
                alt="quotes"
              />
            </Typography>
            <Typography className="quoteAuthor">
              {RichText.asText(primary.quoteAuthor)}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

AboutQuote.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({
      leftImage: imagePropTypes,
      quoteText: structuredTextPropTypes,
      quoteAuthor: structuredTextPropTypes,
    }).isRequired,
    items: PropTypes.array.isRequired,
  }).isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(style)(AboutQuote);
