
import { createStyles, Theme } from "@material-ui/core";
const styles = (theme:Theme) => createStyles({
  leftTextImageRight: {
    "& .rightImageWrapper":{
      position:"relative",
    },
    "& .rightImage": {
      borderRadius: "67% 40% 53% 60% / 40% 37% 63% 70%",
      height: 469,
      width: 570,
      objectFit: "cover",
      position: "relative",
      zIndex: 99,
      [theme.breakpoints.down("md")]: {
        width: 500,
      },
      [theme.breakpoints.down("sm")]: {
        width: 570,
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        height: "100%",
      },
    },

    "& .rightImageOverlay": {
      borderRadius: "32% 68% 42% 58% / 36% 71% 29% 64%",
      width: 570,
      height: 469,
      position: "absolute",
      zIndex: 9,
      backgroundColor: "rgba(0, 0, 0, 0.2)",
      [theme.breakpoints.down("md")]: {
        width: 500,
      },
      [theme.breakpoints.down("sm")]: {
        width: 570,
      },
      [theme.breakpoints.down("xs")]: {
        width: 350,
        height: 232,
      },
      [theme.breakpoints.down(400)]: {
        width: 250,
        height: 170,
      },
    },
    "& .preTitle": {
      color: "#fff",
      fontWeight: 700,
      marginBottom: theme.spacing(4.5),
    },
    "& .title": {
      color: "#fff",
    },
    "& .description": {
      color: "#fff",
      lineHeight: 1.89,
      fontWeight: 700,
      marginTop: theme.spacing(3.5),
      marginBottom: theme.spacing(6),
    },
    "& .playBtn": {
      height: 69,
      width: 105,
      border: 0,
      backgroundImage: `url('/playBtnBg.png')`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      position: "absolute",
      borderRadius: 8,
      zIndex: 99,
      boxShadow: "0px 2px 6px 0px #1f1f1f",
      top: "50%",
      left: "50%",
      right: "50%",
      cursor: "pointer",
      transform: "translateY(-70%) translateX(-50%)",
      [theme.breakpoints.down(844)]: {
        height: 60,
        width: 90,
      },
      [theme.breakpoints.down("xs")]: {
        height: 50,
        width: 80,
        borderRadius: 8,
      },
    },
  },
  rightBgImageVisibility:{
    objectFit:'contain!important' as "contain",
    borderRadius:'0 !important',
  }
});
export default styles;
