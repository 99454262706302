const styles = (theme) => ({
  eventDetailsCta: {
    backgroundImage: `url('/patternSimpleText.svg')`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundColor: '#8BC249',
    height: 475,
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(9),
      paddingBottom: theme.spacing(9),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
  },
})
export default styles