import React from "react";
import PropTypes from "prop-types";
import { RichText } from "prismic-reactjs";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import style from "./style";
import { structuredTextPropTypes } from "../../utils/prop-types";
import TitleSubtitle from "../../components/TitleSubtitle";
import CustomLink from "../../components/CustomLink";
import { Element } from "react-scroll";
import Image from "next/image";
const Endorsements = ({ slice, classes }) => {
  const { primary, items } = slice;
  const endorsements = items.map(
    (endorsement, i) =>
      endorsement && (
        <Grid
        key={i}
          item
          xs={12}
          sm={6}
          md={4}
          container
          direction="row"
          justify="center"
          className={classes.testimonialCardGrid}
        >
          <div className="flexEnd">
            <div className={classes.testimonialCard}>
              <Typography variant="subtitle2" className="testimonial">
                {RichText.asText(endorsement.endorsement.data.endorsement)}
              </Typography>
              <Typography variant="subtitle1" className="name">
                {RichText.asText(endorsement.endorsement.data.endorser_name)}
              </Typography>
              <Typography variant="subtitle2" className="testimonialTitle">
                {RichText.asText(endorsement.endorsement.data.endorser_title)}
              </Typography>
            </div>
            <img
              src={endorsement.endorsement.data.endorser_profile_image.url}
              alt="profile"
              className={classes.testimonialImg}
            />
          </div>
        </Grid>
      )
  );
  return (
    <div className={classes.endorsements}>
      <Container maxWidth="lg">
        <Element name={primary.anchorId} className="element"></Element>
        <TitleSubtitle
          title={RichText.asText(primary.title)}
          subtitle={RichText.asText(primary.subtitle)}
        />
        <Grid
          container
          spacing={4}
          direction="row"
          className="endorsmentWrapperContainer"
        >
          {endorsements}
        </Grid>
        <Grid container direction="row" justify="center" alignItems="center">
          <CustomLink link={primary.ctaButtonLink}>
            <Button variant="contained" color="primary">
              {RichText.asText(primary.ctaButtonText)}
            </Button>
          </CustomLink>
        </Grid>
      </Container>
    </div>
  );
};

Endorsements.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({
      title: structuredTextPropTypes,
      subtitle: structuredTextPropTypes,
      cta_button_text: structuredTextPropTypes,
    }).isRequired,
    items: PropTypes.array.isRequired,
  }).isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(style)(Endorsements);
