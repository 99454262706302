import { Checkbox, FormControlLabel, withStyles } from "@material-ui/core"
import { RichText } from "prismic-reactjs"
import React from "react"
import style from "./style"
import Typography from "@material-ui/core/Typography"
import { Button, InputAdornment, Select } from "@material-ui/core"
import { useLayout } from "../../../utils/layout-context"
import { useVFIStoreState } from '../../../store/vfi-easy-peasy-store'
import BasketItems from "../BasketItems"
import { DesignationItem } from "../../../models/braintree/DonationModels"
import BraintreeConfig from "../../../services/braintree/shared/braintree-config"

interface IYourBasketProps {
    classes: any
    netAmount: number
    basketItems: DesignationItem[]
    updateItem: (i: number, item: DesignationItem, amount: number) => void
    deleteItem: (i: number) => void
    coverTransactionCosts: boolean
    amountInput: React.MutableRefObject<any>
    setCheckbox: (id: string, e: any) => void
    isRecurringDonation: boolean
    handleOpenDonationPopup: () => void
}

export const YourBasket = ({ classes, netAmount, basketItems, updateItem, coverTransactionCosts, deleteItem, amountInput, setCheckbox, isRecurringDonation, handleOpenDonationPopup }: IYourBasketProps) => {

    const layout = useLayout().layout
    const state = useVFIStoreState(state => state);

    return <div className={classes.donateRight}>

        <Typography variant="h6" className={classes.donationBasketTitle}>
            {RichText.asText(layout.donation_basket_title)}
        </Typography>
        <div className={classes.divider} />
        <div className={classes.donateData}>
            <BasketItems netAmount={netAmount} basketItemsArray={basketItems} updateItem={updateItem} deleteItem={deleteItem} amountInput={amountInput} />
        </div>

        <div className={classes.checkboxWrapper}>
            <FormControlLabel
                className="custCheckBox"
                value="end"
                control={
                    <Checkbox
                        color="primary"
                        onChange={(e) => {
                            setCheckbox("cover-transaction", e);
                        }}
                    />
                }
                label={
                    <div className="custCheckBoxLbl">
                        <span>{RichText.asText(layout.extra_impact_checkboxes.find(x => x.extra_impact_id === "cover-transaction")?.checkbox_title)}</span>
                    </div>
                }
                labelPlacement="end"
            ></FormControlLabel>
            <FormControlLabel
                className="custCheckBox"
                value="end"
                // key={i}
                control={
                    <Checkbox
                        color="primary"
                        onChange={(e) => {
                            setCheckbox("monthly", e);
                        }}
                    />
                }
                label={
                    <div className="custCheckBoxLbl">
                        <span>{RichText.asText(layout.monthly_option_text)}</span>
                    </div>

                }
                labelPlacement="end"
            ></FormControlLabel>
            {isRecurringDonation && (
                <div className={classes.monthlyOptionDisclaimer}>{RichText.asText(layout.monthly_option_disclaimer)}</div>
            )}
        </div>
        <Typography variant="h6" className={classes.totalAmtTxt}>
            {RichText.asText(layout.total_amount_text)}: {state.currency.symbol}
            {BraintreeConfig.getTotalAmountString(netAmount, coverTransactionCosts)}
        </Typography>
        <Button
            onClick={handleOpenDonationPopup}
            variant="contained"
            color="primary"
            fullWidth
            className={classes.proceedButtonText}
            disabled={!netAmount}
        >
            {RichText.asText(layout.proceed_button_text)}
        </Button>
    </div>
}

export default withStyles(style)(YourBasket)