import { createStyles } from '@material-ui/core/styles'

const styles = (theme) => createStyles({
  contactForm: {
    "& .FormCard": {
      padding: theme.spacing(6),
      borderRadius: 6,
      boxShadow: "0 23px 42px 0 rgba(106, 118, 154, 0.15)",
      backgroundColor: "#ffffff",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      width: '100%',
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(4),
      },
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(2),
      },
      "& .formTitle": {
        color: "#4c4c78",
        textAlign: "center",
        marginBottom: theme.spacing(5),
      },
      "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: '#8bc249',
      },
      "& fieldset": {
        border: '2px solid #f3f3f3',
        "&:hover": {
          border: '2px solid #8bc249',
        }
      },
      '& .MuiInputBase-root': {
        backgroundColor: '#f3f3f3',
      },
      "& .formtnc p": {
        margin: 0,
        fontSize: 14,
        fontWeight: 500,
        color: "#4c4c78",
        lineHeight: 1.71,
        textAlign: "center",
        fontFamily: "Raleway,Assistant,sans-serif",
        "& a": {
          color: "#4c4c78",
          borderBottom: '1.5px solid #4c4c78',
          textDecoration: 'none',
        }
      },
      "& .sendBtn": {
        maxWidth: 350,
        margin: "0 auto",
        display: "block",
        [theme.breakpoints.down("500")]: {
          width: "100%",
        },
      },
    },
    '& .branchCard': {
      height: '100%',
      borderRadius: 6,
      boxShadow: '0 23px 42px 0 rgba(106, 118, 154, 0.15)',
      backgroundColor: '#ffffff',
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down('sm')]: {
        height: 'auto',
      },
      '& .bannerImg': {
        height: 350,
        width: '100%',
        objectFit: 'cover',
      },
      '& .branchCardData': {
        padding: theme.spacing(3.5, 3.5, 1),
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(2, 2, 0.5),
        },
        '& .country': {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginBottom: theme.spacing(4),
          [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(3),
          },
          [theme.breakpoints.down('xs')]: {
            marginBottom: theme.spacing(2),
          },
          '& img': {
            height: 33,
          },
          '& .countryName': {
            color: '#4c4c78',
            fontWeight: 700,
            marginLeft: theme.spacing(2),
          },
        },
        '& .infoTitle': {
          color: '#4c4c78',
          margin: theme.spacing(0, 0),
        },
        '& .infoData': {
          color: '#4c4c78',
          fontWeight: 500,
          margin: theme.spacing(0),
          fontFamily: 'Raleway,Assistant,sans-serif',
          "& a": {
            color: '#4c4c78',
            borderBottom: '1.5px solid #4c4c78',
            textDecoration: 'none',
          }
        },
      },
    },
  },
  codeWrapper: {
    display: 'flex',
    "& span": {
      marginRight: 4,
    }
  },
  contactBranch: {
    background: "#f4f5f6",
    background:
      "-webkit-linear-gradient(176deg, white 0%, white, 36%, #f4f5f6 15%, #f4f5f6 100%)",
    background:
      "-o-linear-gradient(176deg, white 0%, white, 36%, #f4f5f6 15%, #f4f5f6 100%)",
    background:
      "-moz-linear-gradient(176deg, white 0%, white, 36%, #f4f5f6 15%, #f4f5f6 100%)",
    background:
      "linear-gradient(176deg, white 0%, white, 36%, #f4f5f6 15%, #f4f5f6 100%)",
  },
  donateMailList: {
    flexDirection: theme.direction === "ltr" ? "row" : "row",
    marginRight: theme.direction === "ltr" ? "" : '0px',
    marginLeft: theme.direction === "ltr" ? '0px' : '',
    width: '100%',
    alignItems:'flex-start',
    '& .MuiCheckbox-root':{
      marginTop:'-6px',
    }
  },
  mailingListSignupFailure: {
    color: 'red'
  },
  autocompletePaper:{
    '& .MuiAutocomplete-option':{
      direction:theme.direction === "ltr" ? "ltr" : "rtl",
    }
  },
});
export default styles;
