import React from "react"
import SimpleDialog from "../../../components/Modal"
import style from "./style"
import Typography from "@material-ui/core/Typography"
import { withStyles } from "@material-ui/core"
import { useLayout } from "../../../utils/layout-context"
import { RichText } from "prismic-reactjs"
import { Alert } from '@material-ui/lab';

const RecaptchaErrorPopup = ({ classes, openPopup, closePopup,errorCodes }
    :{classes: any,openPopup:boolean,closePopup:any,errorCodes: string[]}) => {

    const layout = useLayout().layout

    return (
        <SimpleDialog onRendered={() => {  }}
            open={openPopup} handleClose={closePopup}>
            <div className={classes.modal}>
                <Typography variant="h5" className={classes.modalTitle}>
                    {layout.recaptcha_error_title}
                </Typography>
                {errorCodes && errorCodes.map((code,index) => {
                   return  <Alert severity="error" key={index}>{code}</Alert>
                })}
                <div className={classes.modalSectionTitle}>
                    {RichText.render(layout.recaptcha_error_message)}
                </div>
            </div>
        </SimpleDialog>)
}

export default withStyles(style)(RecaptchaErrorPopup)