const styles = (theme) => ({
  eventDetailsPastEvents: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(9),
      marginBottom: theme.spacing(9),
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8),
    },
    '& .eventsItemWrapper': {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8),
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(7),
        marginBottom: theme.spacing(7),
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
      },
    },
  },
})

export default styles