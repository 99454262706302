import * as hostedFields from 'braintree-web/hosted-fields'
import Logger from '../../error-logging-service';
import IPaymentFormInfo, { IPaymentUIEvents } from '../../../models/braintree/IPaymentFormInfo';
import { CreditCardInitResult } from './bt-credit-card-models';

const CreditCardService = {

    //this takes and validates the user's credit card info
    tokenizeCard: async (hostedFieldsInstance: any, paymentInfo: IPaymentFormInfo) => {
        try {

            const hostedFieldsResult = await hostedFieldsInstance.tokenize({

                billingAddress: {
                    postalCode: paymentInfo.address.zip,
                    streetAddress: paymentInfo.address.address1,
                    countryCodeAlpha2: paymentInfo.address.country.code,
                }
            })
            return hostedFieldsResult
        }
        catch (tokenizeErr) {
            Logger.logError(tokenizeErr, { type: "tokenization" })
            return null;
        }
        finally {
            console.log("finished tokenizing card")
        }
    },
    create: async (clientToken: string, paymentInfo: IPaymentFormInfo, uiEvents: IPaymentUIEvents): Promise<CreditCardInitResult> => {
        //Turn the html credit card form into a braintree compatible "hosted fields" form

        try {
            const hostedFieldsInstance = await hostedFields.create(
                {
                    authorization: clientToken,
                    styles: {
                        'input': {
                            'font-size': '16px',
                            'color': '#4c4c78',
                            'font-weight': '500',
                        },
                        ':focus': {
                            border: "2px solid #9ad455",
                        },
                        ':focus-within': {
                            border: "2px solid #9ad455",
                        },
                        ':hover': {
                            border: "2px solid #9ad455",
                        },
                        '.invalid': {
                            'color': 'red'
                        },
                    },
                    preventAutofill: false,
                    fields: {
                        number: {
                            selector: '#card-number', placeholder: paymentInfo.creditCardPlaceholders.cardNumber
                        },
                        cvv: {
                            selector: '#cvv', placeholder: paymentInfo.creditCardPlaceholders.cvv
                        },
                        expirationDate: {
                            selector: '#expiration-date', placeholder: paymentInfo.creditCardPlaceholders.expiry
                        },
                        cardholderName: {
                            selector: "#cardholder-name", placeholder: paymentInfo.creditCardPlaceholders.cardholderName
                        }
                    }
                })

            hostedFieldsInstance.on('empty', function (event) {
                uiEvents.onCreditCardTypeChanged(null)
            });

            hostedFieldsInstance.on('cardTypeChange', function (event) {

                // Change card bg depending on card type
                if (event.cards.length === 1) {

                    uiEvents.onCreditCardTypeChanged(event.cards[0].type)

                    // Change the CVV length for AmericanExpress cards
                    if (event.cards[0].code.size === 4) {
                        hostedFieldsInstance.setAttribute({
                            field: 'cvv',
                            attribute: 'placeholder',
                            value: '1234'
                        });
                    }
                } else {
                    hostedFieldsInstance.setAttribute({
                        field: 'cvv',
                        attribute: 'placeholder',
                        value: '123'
                    });
                }
            });

            return { hostedFieldsInstance, success: true }
        } catch (err) {
            //this runs when the form has been processed by braintree successfully / unsuccessfully
            Logger.logError(err, { page: "donations", action: "hosted-fields-error" });
            return { success: false, error: err }
        }
    }
}

export default CreditCardService


