import React from "react";
import PropTypes from "prop-types";
import { RichText, Date } from "prismic-reactjs";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import style from "./style";
import { structuredTextPropTypes } from "../../utils/prop-types";
import PersonItem from "../../components/PersonItem";
import TitleSubtitle from "../../components/TitleSubtitle";
import { useAdditionalData } from "../../utils/additional-data-context";
import CustomLink from "../../components/CustomLink";
import { useRouter } from 'next/router'

const dateOptions = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
};

const FeaturedBlogs = ({ slice, classes }) => {
  const { primary, items } = slice;
  const additionalData = useAdditionalData();
  return (
    <div className={classes.featuredBlogs}>
      <Container maxWidth="lg">
        <TitleSubtitle
          title={RichText.asText(primary.title)}
          subtitle={RichText.asText(primary.subtitle)}
          showClass
        />
        {primary.showLatestBlogs && additionalData.blogs && (
          <Grid
            container
            spacing={4}
            direction="row"
            justify="center"
            className="featureBlogsContainer"
          >
            {additionalData.blogs.results.map((blog, index) => {
     
              return <Grid item xs={12} sm={6} md={4} key={index}>
                  <PersonItem
                    showTag={
                      blog.data.blog_topics && blog.data.blog_topics.length > 0
                    }
                    link={blog}
                    tag={
                      blog.data.blog_topics &&
                      blog.data.blog_topics.length > 0 ? (
                        <CustomLink
                          link={
                            blog.data.blog_topics[0].blog_topic
                          }
                        >
                          {
                            blog.data.blog_topics[0].blog_topic
                              .uid
                          }
                        </CustomLink>
                      ) : null}
                    bannerImageSrc={blog.data.featured_image.url}
                    bannerImageAlt={blog.data.featured_image.alt}
                    name={RichText.asText(blog.data.title)}
                    title={Date(blog.data.date).toLocaleDateString(
                      useRouter().locale,
                      dateOptions
                    )}
                  />
              </Grid>
            })}
          </Grid>
        )}
        {!primary.showLatestBlogs && (
          <Grid
            container
            spacing={4}
            direction="row"
            justify="center"
            className="featureBlogsContainer"
          >
            {items &&
              items.map((item, index) => {
                return (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    {item.blogPost && item.blogPost.data && (
                      <CustomLink link={item.blogPost}>
                        <PersonItem
                          showTag={
                            item.blogPost.data.blog_topics &&
                            item.blogPost.data.blog_topics.length > 0
                          }
                          bannerImageSrc={item.blogPost.data.featured_image.url}
                          bannerImageAlt={item.blogPost.data.featured_image.alt}
                          name={RichText.asText(item.blogPost.data.title)}
                          title={Date(
                            item.blogPost.data.date
                          ).toLocaleDateString(useRouter().locale, dateOptions)}
                          tag={
                            item.blogPost.data.blog_topics &&
                            item.blogPost.data.blog_topics.length > 0 ? (
                              <CustomLink
                                link={
                                  item.blogPost.data.blog_topics[0].blog_topic
                                }
                              >
                                {
                                  item.blogPost.data.blog_topics[0].blog_topic
                                    .uid
                                }
                              </CustomLink>
                            ) : null
                          }
                        />
                      </CustomLink>
                    )}
                  </Grid>
                );
              })}
          </Grid>
        )}
        <Grid container direction="row" justify="center" alignItems="center">
          <CustomLink link={primary.ctaButtonLink}>
            <Button variant="outlined">
              {RichText.asText(primary.ctaButtonText)}
            </Button>
          </CustomLink>
        </Grid>
      </Container>
    </div>
  );
};

FeaturedBlogs.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({
      title: structuredTextPropTypes,
      subtitle: structuredTextPropTypes,
      ctaButtonText: structuredTextPropTypes,
      ctaButtonLink: PropTypes.objectOf(PropTypes.any),
      showLatestBlogs: PropTypes.bool,
    }).isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        blogPost: PropTypes.objectOf(PropTypes.any),
      })
    ).isRequired,
  }).isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(style)(FeaturedBlogs);
