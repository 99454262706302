const styles = (theme) => ({
  subscribe: {
    zIndex: 100,
    backgroundImage: `url('/greenCirclePattner.png')`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    clipPath: "polygon(0 15%, 100% 0, 100% 100%, 0% 100%)",
    position: "relative",
    backgroundColor: "#8BC249",
    paddingTop: theme.spacing(20),
    paddingBottom: theme.spacing(10),
    marginBottom: "-1px",
    [theme.breakpoints.down("sm")]: {
      clipPath: "polygon(0 5%, 100% 0, 100% 100%, 0% 100%)",
      paddingTop: theme.spacing(18),
      paddingBottom: theme.spacing(9),
      backgroundSize: "200% 200%",
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(16),
      paddingBottom: theme.spacing(8),
    },
    "&::after": {
      clipPath: "polygon(0 15%, 100% 0, 100% 85%, 0% 100%)",
      background:
        "linear-gradient(90deg,rgba(140,194,75,1) 10%, rgba(105, 144, 57,1) 60%,  rgba(74,146,207,1) 30%)",
      content: '""',
      position: "absolute",
      left: 0,
      top: "-84.4%",
      right: 0,
      height: "100%",
      display: "block",
      zIndex: 9,
      [theme.breakpoints.down("sm")]: {
        top: "-94.5%",
        clipPath: "polygon(0% 0%, 100% 10%, 100% 94.8%, 0% 100%)",
      },
    },
    "& .resourceImage": {
      width: 400,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        marginBottom:theme.spacing(9)
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        marginBottom:theme.spacing(8)
      },
    },
    "& .title": {
      color: "#fff",
      textTransform: "uppercase",
    },
    "& .description": {
      color: "#fff",
      lineHeight: 2.13,
      fontWeight: 700,
      padding: theme.spacing(6, 0, 5, 0),
    },
    "& .subscribeWrapper": {
      borderRadius: 8,
      padding: theme.direction === "ltr" ? "0px 0px 0px 24px" : "0px 24px 0px 0px",
      display: "flex",
      border: "2px solid #fff",
      "&:hover": {
        borderColor: "#9ad455",
      },
      "&:focus-within": {
        borderColor: "#9ad455",
        outline: 0,
        outline: "none",
      },
      [theme.breakpoints.down("xs")]: {
        display: "block",
        padding: theme.spacing(2),
      },
      "& .input": {
        flex: 1,
        fontFamily: "Raleway,Assistant",
        fontSize: 21,
        fontWeight: 900,
        "& input": {
          color: "#4c4c78",
          backgroundColor: 'transparent',
          "&::placeholder": {
            color: "#4c4c78",
            opacity: 0.38,
          },
        },
      },
      "& .subButton": {
        [theme.breakpoints.down("xs")]: {
          marginTop: theme.spacing(2),
          width: "100%",
        },
      },
    },
    "& .privacyText p": {
      fontSize: 14,
      lineHeight: 2.13,
      fontWeight: 700,
      color: "#fff",
      "& a": {
        color: "#fff",
      },
    },
  },
  snackbarCut: {
    position: 'absolute',
  }
});

export default styles;
