import * as btLocalPayment from 'braintree-web//local-payment'
import CountryConfig from '../../../models/braintree/CountryConfig'
import { BraintreeBranch, CurrencyCode, LocalPaymentProviders, PaymentErrorType } from '../../../models/braintree/DonationModels'
import IPaymentFormInfo from '../../../models/braintree/IPaymentFormInfo'
import Logger from '../../error-logging-service'
import BraintreeConfig from '../shared/braintree-config'
import { LocalPaymentFallbackResult, LocalPaymentResult } from './bt-local-payment-models'
import getConfig from 'next/config';
import { ILocalPaymentLogInsertResult } from '../../transaction-logging-models'
import { getClientToken, getTransactionDetails } from '../shared/braintree-client-service'
import regionHelpers from '../../regionHelpers'
const siteUrl = getConfig().publicRuntimeConfig.siteUrl;

const ProviderMappings = [
    {
        provider: LocalPaymentProviders.iDeal,
        supportsRecurringPayments: false,
        countryCurrencies: [{ countries: ["NL"], currencies: [CurrencyCode.Euro] }]
    },
    {
        provider: LocalPaymentProviders.Klarna,
        supportsRecurringPayments: false,
        countryCurrencies: [
            { countries: [" ", "BE", "DE", "IT", "NL", "ES"], currencies: [CurrencyCode.Euro] },
            { countries: ["GB"], currencies: [CurrencyCode.Pound] }
        ]
    }
]

const LocalPayService = {

    getErrorType: (paymentProvider: LocalPaymentProviders): PaymentErrorType => {
        switch (paymentProvider) {
            case LocalPaymentProviders.Klarna: {
                return PaymentErrorType.Klarna
            }
            case LocalPaymentProviders.iDeal: {
                return PaymentErrorType.iDeal
            }
            default: 
            {
                throw `Could not get error type for ${paymentProvider}`
            }
        }
    },
    supportsLocalPaymentMethod: (paymentProvider: LocalPaymentProviders,
        paymentDetails: IPaymentFormInfo): boolean => {

        const provider = ProviderMappings.find(x => x.provider === paymentProvider)

        if (paymentDetails.isRecurring && !provider.supportsRecurringPayments) {
            return false
        }
        if (!provider) {
            return false;
        }

        let supports = provider.countryCurrencies.find(x => x.countries.includes(paymentDetails.address.country.code) && x.currencies.includes(paymentDetails.currencyCode))

        return Boolean(supports);
    },
    completePaymentAfterMobileRedirect: async (transactionLogId: string, paymentDetails: IPaymentFormInfo, clientToken: string): Promise<LocalPaymentFallbackResult> => {

        try {

            const branch = regionHelpers.findBranchFromCountryCode(paymentDetails.address.country.code)

            if (!branch || !BraintreeConfig.validateBranch(branch)) {
                Logger.logTrace("invalid branch", { branch: branch })
                return { success: false, message: "invalid branch" };
            }

            if (!clientToken) {
                return { success: false, message: "could not tokenize" }
            }

            const localPaymentInstance = await btLocalPayment.create({ authorization: clientToken })

            if (localPaymentInstance.hasTokenizationParams()) {

                const payload = await localPaymentInstance.tokenize()

                if (payload.nonce) {

                    const url = `/api/donations/checkout/${branch}/complete-local-payment-after-fallback`;

                    const response = await fetch(url, {
                        method: "POST", body: JSON.stringify({
                            transactionLogId,
                            branch,
                            nonce: payload.nonce
                        }),
                        headers: { 'Content-Type': 'application/json', },
                    })

                    if (response.ok) {
                        const paymentResult = await response.json() as LocalPaymentFallbackResult
                        return paymentResult
                    }

                    return { success: false, message: "complete-local-payment-after-fallback failed"  }
                } else {
                    return { success: false, message: "tokenization failed" }
                }

                // make payment, return transaction Id
            } else {
                console.log("could not tokenize abc")
                return { success: false, message: "could not tokenize as the parameters are not set" }
            }

        }
        catch (error) {
            return { success: false, message: error.message }
        }
        finally {

        }

    },
    createPayment: (transactionLogId: string, paymentProvider: LocalPaymentProviders, 
        token: string, branch: BraintreeBranch, paymentDetails: IPaymentFormInfo, locale: string, redirectButtonText: string): Promise<LocalPaymentResult> => {

        const promise = new Promise<LocalPaymentResult>(async (resolve, reject) => {
            try {

                const supports = LocalPayService.supportsLocalPaymentMethod(paymentProvider, paymentDetails)
                if (!supports) {
                    resolve({ success: false, provider: paymentProvider, message: `${paymentProvider} is not a supported payment method` })
                    return
                }
                const merchantAccountId = BraintreeConfig.getMerchantAccountId(branch, paymentDetails.currencyCode)

                if (!merchantAccountId) {
                    resolve({ success: false, provider: paymentProvider, message: `${paymentProvider} could not get merchant account id for ${branch} and ${paymentDetails.currencyCode}` })
                    return
                }

                const localPaymentInstance = await btLocalPayment.create({
                    authorization: token,
                    merchantAccountId
                });

                //todo - store the basket for the webhook, retrieve an id

                const fallbackURL = `${siteUrl}/${locale}/complete-payment/${transactionLogId}`;

                const localPaymentResult = await localPaymentInstance.startPayment(
                    {
                        paymentType: paymentProvider,
                        amount: BraintreeConfig.getTotalAmountString(paymentDetails.netAmount, paymentDetails.coverTransactionCosts),
                        fallback: {
                            // this is mobile phone app fallback, to get back to our app. Requires testing with the real apps.
                            url: fallbackURL,
                            buttonText: redirectButtonText || "Click here to complete your payment" // todo, add text to layout
                        },
                        currencyCode: paymentDetails.currencyCode,
                        address: {
                            countryCode: paymentDetails.address.country.code
                        },
                        onPaymentStart: async (data, start) => {
                            Logger.logTrace(`${paymentProvider} on payment start called`, { data, start })

                            const addLocalPaymentIdToTransactionLogResult = await addLocalPaymentIdToTransactionLog(transactionLogId, data.paymentId, paymentProvider, branch)

                            if (addLocalPaymentIdToTransactionLogResult.success) {
                                Logger.logTrace("addLocalPaymentIdToTransactionLogResult result yay", { addLocalPaymentIdToTransactionLogResult })
                                start();
                            } else {
                                Logger.logTrace("addLocalPaymentIdToTransactionLogResult result nay", { addLocalPaymentIdToTransactionLogResult })
                                resolve({ success: false, message: "Error adding payment id to transaction log", provider: paymentProvider })
                                return
                            }

                        }
                    }
                )

                if (localPaymentResult.nonce) {
                    resolve({ success: true, message: "got the nonce", nonce: localPaymentResult.nonce, provider: paymentProvider })
                } else {
                    resolve({ success: false, message: "Could not get nonce", provider: paymentProvider })
                }
            }
            catch (error) {
                if (error.code === 'LOCAL_PAYMENT_POPUP_CLOSED' || error.code === 'LOCAL_PAYMENT_WINDOW_CLOSED') {
                    resolve({ success: true, provider: paymentProvider, error, suspectedDropOff: true })
                } else {
                    resolve({ success: false, provider: paymentProvider, error })
                }
            }
            finally {
                Logger.logTrace("Finished creating local payment")
            }
        })

        return promise
    }
}

export default LocalPayService

const generateObjectId = (): string => {
    const timestamp = (new Date().getTime() / 1000 | 0).toString(16);
    const suffix = 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, () => (Math.random() * 16 | 0).toString(16)).toLowerCase()
    return `${timestamp}${suffix}`;
}

const addLocalPaymentIdToTransactionLog = async (transactionLogId: string, paymentId: string, paymentProvider: LocalPaymentProviders, branch: BraintreeBranch): Promise<ILocalPaymentLogInsertResult> => {

    try {
        const url = `/api/donations/checkout/${branch}/log-local-payment-id`;

        const response = await fetch(url, {
            method: "POST", body: JSON.stringify({
                paymentProvider,
                transactionLogId,
                paymentId
            }),
            headers: { 'Content-Type': 'application/json', },
        })

        if (response.ok) {
            const paymentResult = await response.json() as ILocalPaymentLogInsertResult

            return paymentResult
        }

        return { success: false, id: null }
    }
    catch (error) {
        Logger.logTrace("local payment error", { error })
        Logger.logError(error)
        return { success: false, id: null }
    }
    finally {

    }
}

