import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { RichText } from "prismic-reactjs";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import style from "./style";
import { structuredTextPropTypes } from "../../utils/prop-types";
import WorkItem from "../../components/WorkItem";
import CategoryTag from "../../components/CategoryTag";
import TitleSubtitle from "../../components/TitleSubtitle";
import { useAdditionalData } from "../../utils/additional-data-context";
import CustomLink from "../../components/CustomLink";
import { useLayout } from "../../utils/layout-context";
const FeaturedWork = ({ slice, classes }) => {
  const { primary, items } = slice;
  const additionalData = useAdditionalData();
  const { layout } = useLayout()
  const [nextPage, setNextPage] = useState(
    additionalData && additionalData.project && additionalData.project.next_page
  );
  const [results, setResults] = useState(
    additionalData && additionalData.project && additionalData.project.results
  );
  useEffect(() => {
    fetch(`${nextPage}`)
      .then((response) => response.json())
      .then((data) => {
        setResults(results.concat(data.results));
        setNextPage(data.next_page);
      });
  }, [nextPage]);

  return (
    <Container maxWidth="lg" className={classes.FeaturedWork}>
      <TitleSubtitle
        title={RichText.asText(primary.title)}
        subtitle={RichText.asText(primary.subtitle)}
      />
      {primary.showAllCategories && additionalData.project_category && (
        <Grid
          container
          spacing={2}
          direction="row"
          justify="center"
          alignItems="center"
          className="CategoryTagWrapper"
        >
          <Grid item>
            <a style={{ textDecoration: "none" }}>
              <CategoryTag
                CategoryTagIconSrc={"/viewAll.svg"}
                CategoryTagIconAlt={"View All"}
                CategoryTagTitle={RichText.asText(layout.projects_view_all_button_text)}
                showIcon
              />
            </a>
          </Grid>
          {additionalData.project_category.results.map((category, index) => (
            <Grid item key={index}>
              <CustomLink link={category}>
                <CategoryTag
                  CategoryTagIconSrc={category.data.icon.url}
                  CategoryTagIconAlt={category.data.icon.alt}
                  CategoryTagTitle={RichText.asText(category.data.title)}
                  showIcon
                />
              </CustomLink>
            </Grid>
          ))}
        </Grid>
      )}
      {!primary.showAllCategories && (
        <Grid
          container
          spacing={4}
          direction="row"
          justify="center"
          alignItems="center"
          className="CategoryTagWrapper"
        >
          {primary.featuredWorkCategory1 && primary.featuredWorkCategory1.data && (
            <Grid item xs={12} sm={4} md={3}>
              <CustomLink link={primary.featuredWorkCategory1}>
                <CategoryTag
                  CategoryTagIconSrc={
                    primary.featuredWorkCategory1.data.icon.url
                  }
                  CategoryTagIconAlt={
                    primary.featuredWorkCategory1.data.icon.alt
                  }
                  CategoryTagTitle={RichText.asText(
                    primary.featuredWorkCategory1.data.title
                  )}
                  showIcon
                />
              </CustomLink>
            </Grid>
          )}
          {primary.featuredWorkCategory2 && primary.featuredWorkCategory2.data && (
            <Grid item xs={12} sm={4} md={3}>
              <CustomLink link={primary.featuredWorkCategory2}>
                <CategoryTag
                  CategoryTagIconSrc={
                    primary.featuredWorkCategory2.data.icon.url
                  }
                  CategoryTagIconAlt={
                    primary.featuredWorkCategory2.data.icon.alt
                  }
                  CategoryTagTitle={RichText.asText(
                    primary.featuredWorkCategory2.data.title
                  )}
                  showIcon
                />
              </CustomLink>
            </Grid>
          )}
          {primary.featuredWorkCategory3 && primary.featuredWorkCategory3.data && (
            <Grid item xs={12} sm={4} md={3}>
              <CustomLink link={primary.featuredWorkCategory3}>
                <CategoryTag
                  CategoryTagIconSrc={
                    primary.featuredWorkCategory3.data.icon.url
                  }
                  CategoryTagIconAlt={
                    primary.featuredWorkCategory3.data.icon.alt
                  }
                  CategoryTagTitle={RichText.asText(
                    primary.featuredWorkCategory3.data.title
                  )}
                  showIcon
                />
              </CustomLink>
            </Grid>
          )}
          {primary.featuredWorkCategory4 && primary.featuredWorkCategory4.data && (
            <Grid item xs={12} sm={4} md={3}>
              <CustomLink link={primary.featuredWorkCategory4}>
                <CategoryTag
                  CategoryTagIconSrc={
                    primary.featuredWorkCategory4.data.icon.url
                  }
                  CategoryTagIconAlt={
                    primary.featuredWorkCategory4.data.icon.alt
                  }
                  CategoryTagTitle={RichText.asText(
                    primary.featuredWorkCategory4.data.title
                  )}
                  showIcon
                />
              </CustomLink>
            </Grid>
          )}
        </Grid>
      )}
      <Grid container spacing={4} direction="row" justify="center">
        {!primary.showAllProjects &&
          items.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              {item.featuredProject && item.featuredProject.data && (
                <WorkItem work={item.featuredProject} />
              )}
            </Grid>
          ))}
        {primary.showAllProjects &&
          results &&
          results.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              {item.data && <WorkItem work={item} />}
            </Grid>
          ))}
      </Grid>
      {!primary.showAllProjects && (
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          className="viewAll"
        >
          <CustomLink link={primary.viewAllButtonLink}>
            <Button variant="contained" color="primary">
              {RichText.asText(primary.viewAllButtonText)}
            </Button>
          </CustomLink>
        </Grid>
      )}
    </Container>
  );
};

FeaturedWork.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({
      title: structuredTextPropTypes,
      subtitle: structuredTextPropTypes,
      featuredWorkCategory1: PropTypes.objectOf(PropTypes.any),
      featuredWorkCategory2: PropTypes.objectOf(PropTypes.any),
      featuredWorkCategory3: PropTypes.objectOf(PropTypes.any),
      featuredWorkCategory4: PropTypes.objectOf(PropTypes.any),
      viewAllButtonText: structuredTextPropTypes,
      viewAllButtonLink: PropTypes.objectOf(PropTypes.any),
      showAllProjects: PropTypes.bool,
      showAllCategories: PropTypes.bool,
    }).isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        featuredProject: PropTypes.objectOf(PropTypes.any),
      })
    ).isRequired,
  }).isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(style)(FeaturedWork);
