import React from 'react';
import PropTypes from 'prop-types';
import Header from 'next/head';
import { RichText } from 'prismic-reactjs';
import getConfig from 'next/config';
import { useRouter } from 'next/router';

const Head = ({ data, canonical }) => {
  const siteUrl = getConfig().publicRuntimeConfig.siteUrl;
  const router = useRouter();

  const canonicalUrl =
    canonical === '/homepage'
      ? `${siteUrl}/${router.locale}`
      : `${siteUrl}/${router.locale}${canonical}`;

  return (
    <Header>
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#d6a560" />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="theme-color" content="#ffffff" />

      {data.meta_title && <title>{RichText.asText(data.meta_title)}</title>}
      {!data.meta_title && <title>VFI</title>}
      {data.meta_description && (
        <meta
          name="description"
          content={RichText.asText(data.meta_description)}
        />
      )}
      {data.meta_title && (
        <meta property="og:title" content={RichText.asText(data.meta_title)} />
      )}
      {data.meta_description && (
        <meta
          property="og:description"
          content={RichText.asText(data.meta_description)}
        />
      )}
      {data.og_image && (
        <meta property="og:image" content={data.og_image.url} />
      )}

      {data.robots_index && (
        <meta name="robots" content={data.robots_index ? 'all' : 'noindex'} />
      )}
      <meta property="og:url" content={canonicalUrl} />
      <link rel="canonical" href={canonicalUrl} />
    </Header>
  );
};

Head.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
};

export default Head;
