import AddADonation from './AddADonation';
import AddressPopup from './AddressPopup';
import allCountries from '../../utils/countries';
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import donationService from '../../services/donation-service';
import Grid from '@material-ui/core/Grid';
import IPaymentFormInfo from '../../models/braintree/IPaymentFormInfo';
import PayNowPopup from './PayNowPopup';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import style from './style';
import ThankYouForDonating from './ThankYouForDonating';
import YourBasket from './YourBasket';
import { Address } from '../../models/braintree/CountryConfig';
import {
  BraintreeBranch,
  DesignationItem,
} from '../../models/braintree/DonationModels';
import { getDefaultAddress } from './AddressForm';
import { RichText } from 'prismic-reactjs';
import { useBasket } from '../../utils/basket-context';
import { useLayout } from '../../utils/layout-context';
import { useRouter } from 'next/router';
import { useVFIStoreState } from '../../store/vfi-easy-peasy-store';
import { withStyles } from '@material-ui/core/styles';
import SecurityService from '../../services/security-service';
import Logger from '../../services/error-logging-service';
import regionHelpers from '../../services/regionHelpers';

const DonatePayNow = ({ classes }) => {
  //#region CONTEXTS
  const basketContext = useBasket();
  const layout = useLayout().layout;
  const locale = useRouter().locale;

  //#endregion CONTEXTS

  //#region Constants
  const amountInput = useRef(null);

  const availableProjects = donationService.getAvailableProjects(layout);

  const state = useVFIStoreState((state) => state);

  const handleAddressChange = (e: any) => {
    // this can be made into a helper function I am sure
    const { name, value } = e.target;
    setAddressState((prevState) => ({
      ...(prevState as Address),
      [name]: value,
    }));
  };

  //#endregion Constants

  //#region STATES
  const [address, setAddressState] = useState<Address>(
    getDefaultAddress(layout.donation_auto_select_mailing_list_signup,state.country)
  );
  const [agreeToGiftAid, setAgreeToGiftAid] = useState(false);
  const [amount, setAmount] = useState<number | string>(''); // should not be a string
  const [basketItems, setBasketItems] = useState<DesignationItem[]>([]);
  const [coverTransactionCosts, setCoverTransactionCosts] = useState(false);
  const [payNowPopupOpen, setPayNowPopupOpen] = useState<boolean>(false);
  const [editOrSaveButtonText, setEditSave] = useState(
    RichText.asText(layout.add_to_basket_button_text)
  );
  const [isRecurringDonation, setIsRecurringDonation] = useState(false);
  const [netAmount, setNetAmount] = useState<number>();
  const [openDonationPopup, setOpenDonationPopup] = useState<boolean>(false);
  const [otherMethodsPopupOpen, setOtherMethodsPopupOpen] = useState<boolean>(
    false
  );
  const [paymentMethod, setPaymentMethod] = useState(layout.payment_methods[0]);
  const [selectedProject, setSelectedProject] = useState(null); // the project
  const [selectedProjectIndex, setSelectedProjectIndex] = useState<number>(0);
  const [thankyouVisible, setThankyouVisible] = useState<boolean>(false);
  const [transactionId, setTransactionId] = useState<string>();

  //#endregion STATES

  //#region EFFECTS
  useEffect(() => {
    if (selectedProjectIndex > -1) {
      setSelectedProject(availableProjects[selectedProjectIndex]);
    } else {
      setSelectedProject(null);
    }
  }, [selectedProjectIndex]);

  useEffect(()=> {
    if (thankyouVisible && state.customLinkClicked > 0){
      setThankyouVisible(false)
    }
  },[state.customLinkClicked])

  useEffect(() => {
    let basketTotal;
    const storedBasket = localStorage.getItem('Basket');

    if (storedBasket) {
      setBasketItems(JSON.parse(storedBasket));
      basketTotal = JSON.parse(storedBasket);
      calculateBasketTotal(basketTotal);
    }
  }, [netAmount]);
  //#endregion EFFECTS

  // #region FUNCTIONS

  //#region POPUPS
  const handleOpenDonationPopup = async () => {
    //here we prime recaptcha to be extra sure it will be ready for the checkout. We don't use the token here. We will generate a new token to use when submitting the payment.
    const primeToken = await SecurityService.getRecaptchaToken('prime');
    if (!primeToken) {
      Logger.logMessage('Could not get token on prime', null);
    } else {
      setOpenDonationPopup(true);
    }
  };

  const closeAllPopups = () => {
    setOpenDonationPopup(false);
    setPayNowPopupOpen(false);
  };

  const openPayNowPopup = async () => {
    setOpenDonationPopup(false);
    setPayNowPopupOpen(true);
  };

  const openOtherPaymentMethodsPopup = () => {
    setOpenDonationPopup(false);
    setOtherMethodsPopupOpen(true);
  };

  const handleCloseBankTransferPopup = async () => {
    setOtherMethodsPopupOpen(false);
  };
  //#endregion POPUPS

  const mappedCheckboxes = (checkbox: string): boolean => {
    if (checkbox == 'cover-transaction') {
      return coverTransactionCosts;
    }
    if (checkbox == 'gift-aid') {
      return agreeToGiftAid;
    }
    return false;
  };

  const setCheckbox = (id: string, e: any): void => {
    switch (id) {
      case 'monthly':
        setIsRecurringDonation(e.target.checked);
        break;
      case 'cover-transaction': {
        setCoverTransactionCosts(e.target.checked);
        break;
      }
      case 'gift-aid':
        setAgreeToGiftAid(e.target.checked);
        break;
      default:
        false;
    }
  };

  //#region BASKET FUNCTIONS
  const calculateBasketTotal = (changedBasket) => {
    let total = changedBasket.reduce(
      (accumulator, currentValue) => +accumulator + +currentValue.amount,
      0
    );
    setNetAmount(total);
  };

  const addToBasket = async () => {
    const newBasketItems = donationService.addToBasket(
      basketItems,
      selectedProject,
      amount,
      state.currency.code,
      basketContext,
      setAmount
    );

    setBasketItems(newBasketItems);
    calculateBasketTotal(newBasketItems);
  };

  const deleteItem = (i: number) => {
    //remove the item
    const basketWithoutItem = basketItems.filter((x) => x !== basketItems[i]);

    //set local storage
    localStorage.setItem('Basket', JSON.stringify(basketWithoutItem));

    //set local state
    setBasketItems([...basketWithoutItem]);

    // set global state
    basketContext.basket.setBasket([...basketWithoutItem]);

    //calculate basket total
    calculateBasketTotal(basketWithoutItem);
  };

  const emptyBasket = () => {
    setBasketItems([]);
    basketContext.basket.setBasket([]);
    localStorage.removeItem('Basket');
    calculateBasketTotal([]);
    setAmount('');
  };

  const updateItem = (i: number, project: DesignationItem, amount: number) => {
    deleteItem(i);
    const index = availableProjects.findIndex((x) => x.id === project.id);
    setSelectedProjectIndex(index);
    setEditSave('Save'); // hardcoded, needs to come from prismic layout
    amountInput.current.focus();
    setAmount(amount);
  };
  //#endregion BASKET FUNCTIONS

  const postDonationSuccess = async (transactionId: string) => {
    setTransactionId(transactionId);
    emptyBasket();
    setIsRecurringDonation(false);
    setCoverTransactionCosts(false);
    setThankyouVisible(true);
    setAddressState(
      getDefaultAddress(layout.donation_auto_select_mailing_list_signup,state.country)
    );
    closeAllPopups();
  };

  const getPaymentInfo = (): IPaymentFormInfo => {
    return {
      address,
      agreeToGiftAid,
      basketItems,
      creditCardPlaceholders: {
        cardNumber: layout.credit_card_number_placeholder,
        cvv: layout.credit_card_cvv_placeholder,
        expiry: layout.credit_card_expiry_placeholder,
        cardholderName: layout.cardholder_name_placeholder,
      },
      coverTransactionCosts,
      currencyCode: state.currency.code,
      isRecurring: isRecurringDonation,
      netAmount: netAmount,
      onSuccess: postDonationSuccess,
      twoLetterLanguageCode: locale || 'en',
    };
  };

  // #endregion FUNCTIONS

  //#region RETURN STATEMENT / HTML RENDER FUNCTIONS
  const BankTransferPopup = () => {
    return (
      <Dialog
        className={classes.modal}
        aria-labelledby="simple-dialog-title"
        open={otherMethodsPopupOpen}
        onClose={handleCloseBankTransferPopup}
      >
        {RichText.render(paymentMethod.popup_text)}
      </Dialog>
    );
  };

  if (thankyouVisible) {
    return (
      <ThankYouForDonating
        transactionId={transactionId}
      />
    );
  }
  // ACTUAL RETURN STATEMENT
  return (
    <>
      <PayNowPopup
        goBackToAddressPopup={
          ()=> {
            setPayNowPopupOpen(false)
            setOpenDonationPopup(true)
          }
        }
        closePopup={() => {
          setPayNowPopupOpen(false);
        }}
        getPaymentInfo={getPaymentInfo}
        netAmount={netAmount}
        popupOpen={payNowPopupOpen}
        recurringDonation={isRecurringDonation}
        coverTransactionCosts={coverTransactionCosts}
      />
      {BankTransferPopup()}

      {openDonationPopup && (
        <AddressPopup
          address={address}
          closeAllPopups={closeAllPopups}
          getPaymentInfo={getPaymentInfo}
          handleAddressChange={handleAddressChange}
          mappedCheckboxes={mappedCheckboxes}
          openPayNowPopup={openPayNowPopup}
          setCheckbox={setCheckbox}
          openDonationPopup={openDonationPopup}
        />
      )}

      <Container maxWidth="lg">
        <Grid container className={classes.donateCard}>
          {/* #region THE LEFT HAND BASKET FORM */}
          <Grid item xs={12} sm={12} md={6}>
            <AddADonation
              addToBasket={addToBasket}
              amount={amount}
              amountInput={amountInput}
              availableProjects={availableProjects}
              editOrSaveButtonText={editOrSaveButtonText}
              selectedProjectIndex={selectedProjectIndex}
              setAmount={setAmount}
              setSelectedProjectIndex={setSelectedProjectIndex}
            />
          </Grid>
          {/* #endregion THE LEFT HAND BASKET FORM */}
          {/* #region THE RIGHT HAND BASKET FORM */}
          <Grid item xs={12} sm={12} md={6}>
            <YourBasket
              amountInput={amountInput}
              basketItems={basketItems}
              coverTransactionCosts={coverTransactionCosts}
              deleteItem={deleteItem}
              handleOpenDonationPopup={handleOpenDonationPopup}
              isRecurringDonation={isRecurringDonation}
              netAmount={netAmount}
              setCheckbox={setCheckbox}
              updateItem={updateItem}
            />
          </Grid>
        </Grid>
        {/* #endregion THE RIGHT HAND BASKET FORM */}
      </Container>
    </>
  );

  //#endregion RETURN STATEMENT / HTML
};

//#region EXPORT

DonatePayNow.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(style)(DonatePayNow);
//#endregion EXPORT
