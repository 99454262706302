import { createStyles, Theme } from "@material-ui/core";

const styles = (theme: Theme) => createStyles({
    shortHero: {
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(10),
        [theme.breakpoints.down("sm")]: {
          marginTop: theme.spacing(9),
          marginBottom: theme.spacing(9),
        },
        [theme.breakpoints.down("xs")]: {
          marginTop: theme.spacing(8),
          marginBottom: theme.spacing(8),
        },
      },
});

export default styles;
