import React, { useEffect, useState } from 'react'
import { Container } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import styles from './style'
import { withStyles } from '@material-ui/core/styles';
import LocalPayService from '../../services/braintree/local-pay/bt-local-payment-service';
import { useAdditionalData } from "../../utils/additional-data-context";
import { ITransactionResult } from '../../services/transaction-logging-models';
import ThankYouForDonating from '../DonatePayNow/ThankYouForDonating';
import { Alert } from '@material-ui/lab';

const CompletePayment = ({ slice, classes }) => {

  const [errorState, setErrorState] = useState<boolean>(false);
  const [successState, setSuccessState] = useState<boolean>(false);
  const [finishedLoading, setFinishedLoading] = useState<boolean>(false)
  const [transactionId, setTransactionId] = useState<string>();
  const [transactionResult, setTransactionResult] = useState<ITransactionResult>();
  const additionalData: any = useAdditionalData()

  if (!transactionResult && additionalData.transactionResult) {
    setTransactionResult(additionalData.transactionResult)
  }

  const completePaymentAsync = async (transactionResult: ITransactionResult, clientToken: string) => {

    console.log("*** completing payment ***", additionalData)
    const paymentResult = await LocalPayService.completePaymentAfterMobileRedirect(transactionResult.transactionLogId, transactionResult.paymentDetails,clientToken)
    setFinishedLoading(true)
    setErrorState(!paymentResult.success)
    setSuccessState(paymentResult.success)
    setTransactionId(paymentResult.transactionId)
  }

  const setPaymentAlreadyProcessed = (transactionResult: ITransactionResult) => {

    console.log("*** setting payment already processed", additionalData)
    setFinishedLoading(true)
    setErrorState(false)
    setSuccessState(true)
    setTransactionId(transactionResult.transactionId)
  }

  useEffect(() => {

    if (transactionResult) {
      if (transactionResult.alreadyProcessed) {
        setPaymentAlreadyProcessed(transactionResult)
      } else {
        completePaymentAsync(transactionResult,additionalData.clientToken)
      }
    }

  }, [transactionResult])

  return (
    <Container maxWidth="md" className={classes.shortHero}>

      <Typography variant="h1" ></Typography>

      <section>
        {
          !finishedLoading && slice.primary.loadingMessage &&
          <Alert severity="info">
          {slice.primary.loadingMessage}
          </Alert>
        }
        {
          finishedLoading && errorState &&
          <Alert severity="info">
          {slice.primary.errorMessage}
          </Alert>
        }
        {
          finishedLoading && successState && transactionId &&
          <ThankYouForDonating transactionId={transactionId} />
        }
      </section>
    </Container>
  )
}

export default withStyles(styles)(CompletePayment);