const styles = (theme) => ({
  shortHero: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    color: '#4c4c78',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(9),
      paddingBottom: theme.spacing(9),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
    "& h1": {
      textTransform: 'uppercase',
    },
    "& p": {
      fontWeight: 700,
    }
  },
})
export default styles
