const styles = (theme) => ({
  impactStatement: {
    backgroundImage: `url('/patternSimpleText.svg')`,
    backgroundColor: '#397DB7',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    paddingTop: theme.spacing(20),
    paddingBottom: theme.spacing(20),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(15),
      paddingBottom: theme.spacing(15),
      backgroundPosition: '100%',
      backgroundSize: 'cover',
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(10),
      backgroundPosition: '100%',
      backgroundSize: 'cover',
    },
    '& .impactStatement': {
      color: '#fff',
      fontStyle: 'italic',
      textAlign: 'center',
    },
  },
})

export default styles