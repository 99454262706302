import * as paypalCheckout from 'braintree-web/paypal-checkout'
import { BraintreeBranch, PaymentErrorType, PaymentMethod } from '../../../models/braintree/DonationModels';
import IPaymentFormInfo, { IPaymentUIEvents } from '../../../models/braintree/IPaymentFormInfo';
import Logger from '../../error-logging-service';
import { MakePaymentGenericFunction } from '../shared/bt-models-shared';
import { PaypalInitResult, PaypalActionButtons } from './bt-paypal-models';

const PaypalService = {
    init: async (transactionLogId: string, token: string, paymentInfo: IPaymentFormInfo, uiEvents: IPaymentUIEvents,
        makePaymentGeneric: MakePaymentGenericFunction,
        branch: BraintreeBranch): Promise<PaypalInitResult> => {

        const waitForPaypalInit = async (): Promise<PaypalInitResult> => {

            const paypalInitPromise = new Promise<PaypalInitResult>(async (resolve, reject) => {

                let paypalButtonActions: PaypalActionButtons = null
                try {

                    Logger.logTrace("setting up paypal")

                    const paypalCheckoutInstance = await paypalCheckout.create({ authorization: token })

                    Logger.logTrace("got the paypal checkout instance, now loading SDK", { vault: true, currency: paymentInfo.currencyCode, paypalCheckoutInstance })

                    const paypalSetupResult = await paypalCheckoutInstance.loadPayPalSDK({ vault: true, currency: paymentInfo.currencyCode })

                    Logger.logTrace("paypalSetupResult", paypalSetupResult)

                    // The PayPal script is now loaded on the page and
                    // window.paypal.Buttons is now available to use
                    await window.paypal.Buttons({

                        fundingSource: window.paypal.FUNDING.PAYPAL,

                        createBillingAgreement: () => {
                            Logger.logTrace("paypal create billing agreement")
                            return paypalCheckoutInstance.createPayment({ flow: 'vault' });
                        },
                        onInit: (data, actions) => {
                            Logger.logTrace("PAYPAL BUTTON ACTIONS IN PAYPAL SERVICE", actions)
                            paypalButtonActions = actions
                            resolve({ paypalButtonActions: actions, success: true, code: "init-succeeded" })
                        },
                        onApprove: async (data, actions) => {
                            Logger.logTrace("paypal on approve")
                            try {
                                uiEvents.showLoading()

                                const paypalNonceResult: { nonce: string } = await paypalCheckoutInstance.tokenizePayment(data)

                                Logger.logTrace("paypal nonce result", paypalNonceResult)
                                await makePaymentGeneric(transactionLogId,PaymentMethod.PAYPAL, branch, paypalNonceResult.nonce, paymentInfo,
                                    uiEvents, { success: true, paypalButtonActions, paypalCheckoutInstance }, token);

                            }
                            catch (err) {
                                Logger.logError(err, { page: "donations", action: "tokenize paypal payment" });
                                uiEvents.onError(PaymentErrorType.Paypal, err)
                                return;
                            }
                            finally {
                                Logger.logTrace("paypal onapprove finally executing, hiding loading")
                                uiEvents.hideLoading()
                            }
                        },

                        onCancel: function (data) {
                            Logger.logTrace('PayPal payment canceled', data);
                            resolve({ success: true, code: "cancelled" })
                        },
                        onError: function (err) {
                            if (err?.message === "Detected popup close"){
                                Logger.logTrace('PayPal payment canceled');
                                resolve({ success: true, code: "cancelled" })
                            }else{
                                Logger.logError(err, { page: "donations", action: "generic paypal error" });
                                uiEvents.onError(PaymentErrorType.Paypal, err)
                                resolve({ success: false, code: "cancelled" })
                            }
                        },
                        style: {
                            color: 'blue',
                            //shape:  'pill',
                            label: 'pay',
                            height: 40,
                            size: 'responsive'
                        }
                    }).render('#paypal-button')

                    // The PayPal button will be rendered in an html element with the ID `paypal-button`.
                    Logger.logTrace("THE PAYPAL BUTTON IS SET UP AND READY TO USE")

                    return { success: true, error: null, paypalCheckoutInstance: paypalSetupResult }
                }
                catch (paypalCheckoutErr) {
                    Logger.logError(paypalCheckoutErr, paypalCheckoutErr);
                    uiEvents.onError(PaymentErrorType.Paypal, paypalCheckoutErr)
                    return { success: false, error: paypalCheckoutErr }
                }
                finally {
                    Logger.logTrace("paypal finally block executing")
                }
            })
            return paypalInitPromise
        }

        try {
            //10 second timeout on paypal init, otherwise just do not show it
            const timeoutPromise = new Promise<PaypalInitResult>((res) => setTimeout(() => res({ success: false, code: "timed_out_waiting_for_paypal_init" }), 10000))
            const paypalInitResult = await Promise.race([timeoutPromise, waitForPaypalInit()])
            Logger.logTrace("paypal init result", paypalInitResult)
            return paypalInitResult
        }
        catch (error) {
            Logger.logTrace("paypal init error: " + error.message, { error })
            return { success: false, error }
        }
        finally {
            Logger.logTrace("paypal init finally")
        }
    }
}

export default PaypalService