import React from "react";
import PropTypes from "prop-types";
import { RichText } from "prismic-reactjs";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import style from "./style";
import { structuredTextPropTypes } from "../../utils/prop-types";
import TitleSubtitle from "../../components/TitleSubtitle";
import CustomLink from "../../components/CustomLink";
const EventListPastEvent = ({ slice, classes }) => {
  const { primary } = slice;

  return (
    <div className={classes.eventListPastEvent}>
      <Container maxWidth="md">
        <TitleSubtitle
          title={RichText.asText(primary.title)}
          subtitle={RichText.asText(primary.description)}
          showClass
        />
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          className="pastEventBtnWrapper"
        >
          <CustomLink link={primary.buttonLink}>
            <Button variant="outlined">
              {RichText.asText(primary.buttonText)}
            </Button>
          </CustomLink>
        </Grid>
      </Container>
    </div>
  );
};

EventListPastEvent.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({
      title: structuredTextPropTypes,
      description: structuredTextPropTypes,
      button_text: structuredTextPropTypes,
    }).isRequired,
    items: PropTypes.array.isRequired,
  }).isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(style)(EventListPastEvent);
