const styles = (theme) => ({
  eventsItem: {
    borderRadius: 5,
    boxShadow: "0 30px 60px 0 rgba(73, 86, 149, 0.15)",
    backgroundColor: "#ffffff",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    cursor: 'pointer',
    [theme.breakpoints.down("sm")]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    "& img": {
      width: 260,
      height: 260,
      objectFit: "cover",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: "100%",
      },
    },
    "& .eventInfo": {
      flex: 1,
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        marginTop: theme.spacing(2),
        paddingLeft: theme.spacing(0),
      },
      [theme.breakpoints.down("xs")]: {
        flex: "none",
        width: "100%",
      },
      "& .eventName,.moreDetails": {
        color: "#4c4c78",
        fontWeight: 700,
        marginBottom: theme.spacing(2),
      },
      "& .eventPlace,.eventDate": {
        lineHeight: 1.44,
        color: "#4c4c78",
        fontWeight: 500,
      },
      "& .moreDetails": {
        color: "#4c4c78",
        fontWeight: 700,
        marginTop: theme.spacing(2.5),
        textDecoration: "underline",
        cursor: "pointer",
      },
    },
    "& .eventRegister": {
      textAlign: "center",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        marginTop: "-20px",
        textAlign: "left",
      },
      "& .eventPrice": {
        color: "#4c4c78",
        fontWeight: 700,
        textAlign: "center",
        marginBottom: theme.spacing(3.5),
        [theme.breakpoints.down("sm")]: {
          textAlign: "left",
        },
      },
    },
  },
});

export default styles;
