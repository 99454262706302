import allCountries from '../../../utils/countries';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CountryConfig, {
  Address
} from '../../../models/braintree/CountryConfig';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import regionHelpers from '../../../services/regionHelpers';
import style from './style';
import TextField from '@material-ui/core/TextField';
import { RichText } from 'prismic-reactjs';
import { useLayout } from '../../../utils/layout-context';
import { withStyles } from '@material-ui/core/styles';
import filters from '../../../utils/autocomplete-filters';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { CurrencyCode } from '../../../models/braintree/DonationModels';

const AddressForm = ({
  classes,
  address,
  handleAddressChange,
}: {
  classes: any;
  address: Address;
  handleAddressChange: (e: any) => void;
}) => {
  const layout = useLayout().layout;
  const [stateRequired, setStateRequired] = useState<boolean>();

  useEffect(() => {

    if (address.country) {
      setStateRequired(address.country.code === 'US');
    }

  }, [address?.country]);


  return (
    <>
      <Grid container spacing={2} className={classes.addressformGrid}>
        <Grid item xs={12} sm={6}>
          <TextField
            autoComplete="given-name"
            placeholder={RichText.asText(layout.first_name_placeholder)}
            variant="outlined"
            fullWidth
            required
            margin="dense"
            name="firstName"
            value={address.firstName}
            onChange={handleAddressChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            autoComplete="family-name"
            placeholder={RichText.asText(layout.last_name_placeholder)}
            variant="outlined"
            fullWidth
            required
            margin="dense"
            name="lastName"
            value={address.lastName}
            onChange={handleAddressChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            autoComplete="address-line1"
            placeholder={RichText.asText(layout.address_1_placeholder)}
            variant="outlined"
            fullWidth
            required
            margin="dense"
            name="address1"
            value={address.address1}
            onChange={handleAddressChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            autoComplete="address-line2"
            placeholder={RichText.asText(layout.address_2_placeholder)}
            variant="outlined"
            fullWidth
            margin="dense"
            name="address2"
            value={address.address2}
            onChange={handleAddressChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            autoComplete="home city"
            placeholder={RichText.asText(layout.city_placeholder)}
            variant="outlined"
            fullWidth
            required
            margin="dense"
            name="city"
            value={address.city}
            onChange={handleAddressChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            autoComplete="home state"
            placeholder={RichText.asText(layout.state_placeholder)}
            variant="outlined"
            fullWidth
            margin="dense"
            required={stateRequired}
            name="state"
            value={address.state}
            onChange={handleAddressChange}
          />

        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            autoComplete="postal-code"
            placeholder={RichText.asText(layout.zipcode_placeholder)}
            variant="outlined"
            fullWidth
            required
            margin="dense"
            name="zip"
            value={address.zip}
            onChange={handleAddressChange}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Autocomplete id="country-select-demo"
            fullWidth 
            autoHighlight
            value={address.country || null} 
            renderOption={(option, i) => (
              <div className={classes.codeWrapper}>
                <span>{regionHelpers.countryToFlag(option.code)}</span>
                {option.label} ({option.code})
              </div>
            )} 
            filterOptions={filters.countryFilter}
            onChange={(e, countryFromAutocomplete: CountryConfig) => {

              handleAddressChange({
                target: { value: countryFromAutocomplete, name: 'country' },
              });
            }}
            classes={{
              option: classes.option,
              paper: classes.autocompletePaper,
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                placeholder={RichText.asText(layout.country_placeholder)}
                variant="outlined"
                margin="dense"
                required 
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
              />
            )}
            getOptionLabel={(option) => option.label} 
            options={[
              ...allCountries.sort((a, b) => (a.label > b.label ? 1 : -1)),
            ]} />

        </Grid>
        <Grid item xs={12}>
          <TextField
            autoComplete="email"
            placeholder={RichText.asText(layout.email_placeholder)}
            variant="outlined"
            required
            fullWidth
            type="email"
            name="email"
            margin="dense"
            value={address.email}
            onChange={handleAddressChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Autocomplete
            id="dialing-code-select"
            fullWidth
            options={[
              ...allCountries.sort((a, b) => (a.code > b.code ? 1 : -1))
            ]}
            classes={{
              option: classes.option,
              paper: classes.autocompletePaper,
            }}
            filterOptions={filters.dialingCodes}
            autoHighlight
            getOptionLabel={(option: CountryConfig) =>  { 

              return `+` + option.dialingCode
            }}
            value={address.dialingCodeCountryConfig || null}
            onChange={(e, country: CountryConfig) => {
      
              if (country) {
                handleAddressChange({
                  target: { value: country, name: 'dialingCodeCountryConfig' },
                });
                handleAddressChange({
                  target: { value: country.dialingCode, name: 'dialingCode' },
                });
              }else{
                handleAddressChange({
                  target: { value: null, name: 'dialingCodeCountryConfig' },
                });
                handleAddressChange({
                  target: { value: "", name: 'dialingCode' },
                });
              }
            }}
            renderOption={(option) => (
              <div className={classes.codeWrapper}>
                <span>{regionHelpers.countryToFlag(option.code)}</span>(
                {option.code}) +{option.dialingCode}
              </div>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                placeholder={RichText.asText(layout.code_placeholder)}
                variant="outlined"
                margin="dense"
                required 
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <TextField
            placeholder={RichText.asText(layout.phone_number_placeholder)}
            variant="outlined"
            fullWidth
            required
            type="phone"
            margin="dense"
            name="phoneNumberLocal"
            autoComplete="tel-local"
            value={address.phoneNumberLocal}
            onChange={handleAddressChange}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            checked={address.signUpForEmail}
            value="start"
            className={classes.donateMailList}
            control={
              <Checkbox
                color="primary"
                onChange={(e) => {
                  handleAddressChange({
                    target: { value: e.target.checked, name: 'signUpForEmail' },
                  });
                }}
              />
            }
            label={<div>{layout.donation_mailing_list_sign_up_text}</div>}
            labelPlacement="end"
          />
        </Grid>
      </Grid>
    </>
  );
};

AddressForm.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export const getDefaultAddress = (signUpForEmail: boolean,country?: CountryConfig): Address => {
  return new Address({
    firstName: '',
    dialingCode: country?.dialingCode,
    dialingCodeCountryConfig: country,
    country: country,
    lastName: '',
    address1: '',
    address2: '',
    city: '',
    email: '',
    phoneNumberLocal: '',
    state: '',
    zip: '',
    signUpForEmail,
  });
};

export default withStyles(style)(AddressForm);
