import React from "react";
import PropTypes from "prop-types";
import { RichText } from "prismic-reactjs";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import style from "./style";
import { structuredTextPropTypes } from "../../utils/prop-types";
import CustomLink from "../../components/CustomLink";
import { imagePropTypes } from "../../utils/prop-types";
import Image from "next/image";
const BigImage = ({ slice, classes }) => {
  const { primary } = slice;

  return (
    <div className={classes.bigImage}>
      <Container maxWidth="lg" className="container">
        <Grid container justify="center">
          <Grid item xs={12} sm={10} md={8}>
            <img
              className={classes.image}
              src={primary.image.url}
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

BigImage.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({
      image: imagePropTypes,
    }).isRequired,
    items: PropTypes.array.isRequired,
  }).isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(style)(BigImage);
