const styles = (theme) => ({
  featuredMember: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(9),
      paddingBottom: theme.spacing(9),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
    '& .boardMemberItem': {
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(10),
      [theme.breakpoints.down('sm')]: {
        paddingTop: theme.spacing(9),
        paddingBottom: theme.spacing(9),
      },
      [theme.breakpoints.down('xs')]: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
      },
    },
    '& .viewAll': {
      margin: '0 auto',
    },
    "& .element": {
      position: 'absolute',
      top: 80,
    }
  },
})

export default styles