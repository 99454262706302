import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { RichText } from "prismic-reactjs";
import { Container, Button } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { structuredTextPropTypes } from "../../utils/prop-types";
import style from "./style";
import { useBasket } from "../../utils/basket-context";
import CustomLink from "../../components/CustomLink";
import { useRouter } from "next/router";
const DonationThankyou = ({ slice, classes }) => {
  const { primary } = slice;
  const router = useRouter();
  useEffect(async () => {
    localStorage.removeItem("basket");
  });
  const basket = useBasket();
  const {
    recurring,
    currencySymbol,
    totalAmount,
    currency,
    designationAmounts,
  } = basket.basket.thankyouPageDetails;
  return (
    <Container maxWidth="md" className={classes.shortHero}>
      <Typography variant="h1" >
           {RichText.render(primary.paymentConfirmationText)}
      </Typography>
      <Typography variant="subtitle1" >
           {RichText.render(primary.feedbackRequestText)}
      </Typography>

      <CustomLink link={primary.ctaButtonLink}>
        <Button variant="primary" className={classes.ctaButton}>
          {RichText.asText(primary.ctaButtonText)}
        </Button>
      </CustomLink>
      <CustomLink link={'/'}>
        <Button variant="contained" className={classes.ctaButton}>
          {RichText.asText(primary.homepageLinkText)}
        </Button>
      </CustomLink>
    </Container>
  );
};

DonationThankyou.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({
      title: structuredTextPropTypes,
      subtitle: structuredTextPropTypes,
    }).isRequired,
    items: PropTypes.array.isRequired,
  }).isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(style)(DonationThankyou);
